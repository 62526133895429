import React from 'react';
import axios from 'axios';
import { Router, useMatch } from '@reach/router';
import config from '../../../config';
import ListUsers from './ListUsers';
import InviteUser from './InviteUser';
import User from './User';
import { useAppSelector } from '../../../hooks';
import * as style from './index.module.scss';

interface Props {
    organisation: any;
    accessToken: string;
    users: any[];
    setUsers: any;
    isOrgAdmin: boolean;
    me: any;
    organisationId: string;
}

const Users = ({
    organisation,
    accessToken,
    users,
    setUsers,
    isOrgAdmin,
    me,
    organisationId,
}: Props) => {
    const language = useAppSelector((state) => state.global.language);
    const [user, setUser] = React.useState(null);
    const [isFetchingUsers, setIsFetchingUsers] = React.useState(false);

    const matchUserId = useMatch(`/app/:organisationId/users/:userId`);

    let userId: string | null = null;
    if (matchUserId != null && matchUserId.userId != null) {
        userId = matchUserId.userId;
    }

    React.useEffect(() => {
        // console.log(user, userId, users)
        if (
            user == null &&
            userId != null &&
            users != null &&
            users.length > 0
        ) {
            for (let i = 0; i < users.length; i++) {
                if (users[i].id === userId) {
                    setUser(users[i]);
                    break;
                }
            }
        }
    }, [users, userId, user]);

    React.useEffect(() => {
        if (
            users.length === 0 &&
            organisationId != null &&
            accessToken != null
        ) {
            const fetchUsersAsync = async () => {
                setIsFetchingUsers(true);
                try {
                    console.log('# Fetching users');
                    const response = await axios.get(
                        `${config.apiBaseUrl}/organisation/${organisationId}/users`,
                        {
                            headers: {
                                Authorization: `Bearer ${accessToken}`,
                            },
                        }
                    );
                    setUsers(response.data);
                } catch (err) {
                    console.log(err);
                }

                setIsFetchingUsers(false);
            };

            fetchUsersAsync();
        }
    }, [users, accessToken, setUsers, organisationId]);

    return (
        <div className={style.usersPage}>
            <Router>
                <ListUsers
                    path='/'
                    users={users}
                    setUser={setUser}
                    language={language}
                    isOrgAdmin={isOrgAdmin}
                    isFetchingUsers={isFetchingUsers || organisation == null}
                    organisationId={organisationId}
                />
                <InviteUser
                    path='/invite'
                    language={language}
                    accessToken={accessToken}
                    organisation={organisation}
                    users={users}
                    setUsers={setUsers}
                    isFetchingUsers={isFetchingUsers}
                    organisationId={organisationId}
                />
                <User
                    path='/:userId'
                    accessToken={accessToken}
                    organisation={organisation}
                    user={user}
                    setUser={setUser}
                    language={language}
                    setUsers={setUsers}
                    users={users}
                    isOrgAdmin={isOrgAdmin}
                    me={me}
                    isFetchingUsers={isFetchingUsers}
                    organisationId={organisationId}
                />
            </Router>
        </div>
    );
};

export default Users;
