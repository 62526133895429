import React, { useCallback, useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import { Router, useMatch } from '@reach/router';
import axios from 'axios';
import { styled } from '@mui/material/styles';
import { useAppDispatch, useAppSelector } from '../../hooks';
import {
    AppBar as MuiAppBar,
    Toolbar,
    IconButton,
    Typography,
    Drawer as MuiDrawer,
    List,
    Menu,
    MenuItem,
    ListItemIcon,
    ListItemText,
    Badge,
    Divider,
    CircularProgress,
    Box,
    ListItemButton,
} from '@mui/material';

import {
    Menu as MenuIcon,
    LibraryBooks,
    List as ListIcon,
    Dashboard as DashboardIcon,
    Settings as SettingsIcon,
    PeopleAlt as UsersIcon,
    AccountCircle,
    NotificationsActive,
    Notifications,
    Person,
    ExitToApp,
    Language,
    ArrowBack,
    Forum,
    Message,
    History,
    Email,
    Share,
    Description,
    Assignment,
    AssignmentOutlined,
} from '@mui/icons-material';
import SEO from '../seo';
import config from '../../config';
import localization from '../../localization';
import Dashboard from './Dashboard';
import Reports from './Reports';
import Content from './Content';
import Form from './Form';
import Settings from './Settings';
import Users from './Users';
import Activity from './Activity';
import Account from './Account';
import Organisations from './Organisations';
import ReportLink from './ReportLink';

import * as style from './index.module.scss';
import CookieConsent from '../CookieConsent';
import {
    languageSet,
    organisationSet,
    userSet,
} from '../../state/reducers/globalReducer';
import { Content as IContent } from '../../interfaces/Content';

const drawerWidth = 220;
const isBrowser = typeof window !== 'undefined';

const openedMixin = (theme: any) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme: any) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(9)} + 1px)`,
    },

    // width: theme.spacing(7) + 1,
    // [theme.breakpoints.up('sm')]: {
    //     width: theme.spacing(9) + 1,
    // },
});

const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
    }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    backgroundColor: '#0277bc',
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        // marginLeft: drawerWidth,
        // backgroundColor: '#0277bc',
        // width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

interface Props {
    location: any;
}

const App = ({ location }: Props) => {
    const isSignedIn = useAppSelector((state) => state.global.isSignedIn);
    const isReady = useAppSelector((state) => state.global.isReady);
    const isSigningIn = useAppSelector((state) => state.global.isSigningIn);
    const user = useAppSelector((state) => state.global.user);
    const language = useAppSelector((state) => state.global.language);
    const organisation = useAppSelector((state) => state.global.organisation);
    const accessToken = useAppSelector(
        (state) => state.global.auth.accessToken
    );
    const [organisationError, setOrganisationError] = useState(null);
    // const [orgLink, setOrgLink] = useState(null)

    // State for the app pages
    const [records, setRecords] = useState<any>([]);
    const [reports, setReports] = useState<any>([]);
    const [refreshRecords, setRefreshRecords] = useState(true);
    const [refreshReports, setRefreshReports] = useState(true);
    // const [isFetchingActivity, setIsFetchingActivity] = useState(false)
    const [users, setUsers] = useState<any>([]);
    const [closedReports, setClosedReports] = useState<any>([]);
    const [reportLink, setReportLink] = useState<any | null>(null);
    const [content, setContent] = useState<IContent>({
        pages: [],
        files: [],
        groups: [],
        isFetching: true,
        reportDefaultMessages: [],
    });

    const [analytics, setAnalytics] = useState<any>(null);
    const [hasUnreadMessage, setHasUnreadMessage] = useState(false);

    const [notificationReports, setNotificationReports] = useState([]);

    // State for report links
    const [isMessageDialogOpen, setIsMessageDialogOpen] = useState(false);
    const [isReportLinkInactive, setIsReportLinkInactive] = useState(false);

    // Matches
    const matchOrganisationId = useMatch(`/app/:organisationId/*`);
    const matchReportId = useMatch(
        `/app/:organisationId/reports/id/:reportId/*`
    );
    const matchReportLink = useMatch(
        `/app/:organisationId/link/:linkId/id/:reportId/*`
    );

    // console.log(matchOrganisationId)

    const organisationId =
        matchOrganisationId != null ? matchOrganisationId.organisationId : null;

    const isReportLink = matchReportLink != null;

    // Global state for the app
    const [isOrgAdmin, setIsOrgAdmin] = useState(false);
    const [canManageReports, setCanManageReports] = useState(false);
    const [canReadReports, setCanReadReports] = useState(false);
    const [numUnreadMessages, setNumUnreadMessages] = useState(0);
    const [numUnreadComments, setNumUnreadComments] = useState(0);
    const [numOpenReports, setNumOpenReports] = useState(0);
    const [numNewReports, setNumNewReports] = useState(0);
    const [numPendingReports, setNumPendingReports] = useState(0);

    const isEmailVerified = user != null && user.isEmailVerified === true;

    // const hasOrganisation = user != null && user.organisationId != null && user.organisationId.length > 0
    // const hasOrgLink = orgLink != null

    const pathName = location.pathname;

    // console.log(orgLink)

    const dispatch = useAppDispatch();
    const setOrganisation = useCallback(
        (organisation) => dispatch(organisationSet(organisation)),
        [dispatch]
    );

    const [open, setOpen] = useState(isBrowser && window.innerWidth > 700);
    const [anchorElLanguage, setAnchorElLanguage] = useState(null);
    const [anchorElAccount, setAnchorElAccount] = useState(null);
    const [anchorElNotifications, setAnchorElNotifications] = useState(null);

    const changeLanguageAsync = async (newLanguage: string) => {
        handleClose();
        dispatch(languageSet(newLanguage));

        if (isReportLink) {
            return;
        }

        const formData = new FormData();
        formData.append('language', newLanguage);

        try {
            const data = (
                await axios.put(
                    `${config.apiBaseUrl}/account/language`,
                    formData,
                    {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                    }
                )
            ).data;
            if (user != null) {
                dispatch(
                    userSet({
                        ...user,
                        language: data.language,
                    })
                );
            }
        } catch (err) {
            console.log(err);
        }
    };

    const toggleDrawer = () => {
        setOpen(!open);
    };

    const handleAccountMenu = (event: any) => {
        setAnchorElAccount(event.currentTarget);
        setAnchorElLanguage(null);
        setAnchorElNotifications(null);
    };

    const handleLanguageMenu = (event: any) => {
        setAnchorElLanguage(event.currentTarget);
        setAnchorElAccount(null);
        setAnchorElNotifications(null);
    };

    const handleNotificationsMenu = (event: any) => {
        setAnchorElNotifications(event.currentTarget);
        setAnchorElAccount(null);
        setAnchorElLanguage(null);
    };

    const handleClose = () => {
        setAnchorElLanguage(null);
        setAnchorElAccount(null);
        setAnchorElNotifications(null);
    };

    const handleSignOut = () => {
        handleClose();
        navigate(`/${language}/signout`);
    };

    useEffect(() => {
        if (organisationId == null) {
            navigate(`/account/overview`);
        }
    }, [organisationId]);

    useEffect(() => {
        if (reports != null) {
            const updatedNotificationReports: any = [];
            for (let i = 0; i < reports.length; i++) {
                if (
                    reports[i].hasUnreadComment ||
                    reports[i].hasUnreadMessage ||
                    reports[i].status === 'new'
                ) {
                    updatedNotificationReports.push(reports[i]);
                }

                if (matchReportId?.reportId != null) {
                    if (reports[i].id === matchReportId.reportId) {
                        setHasUnreadMessage(reports[i].hasUnreadMessage);
                    }
                }
            }

            setNotificationReports(updatedNotificationReports);
        }
    }, [reports]);

    // Refresh reports every 10 minutes
    useEffect(() => {
        // console.log('# Resetting timer')
        const fetchInterval = setInterval(() => {
            setRefreshReports(true);
        }, 1000 * 60 * 10);

        return () => clearInterval(fetchInterval);
    }, [refreshReports]);

    useEffect(() => {
        if (refreshReports && organisation != null && !isReportLink) {
            const getReportsAsync = async () => {
                try {
                    // console.log('### Fetching important reports. ', new Date().toString())
                    const response = await axios.get(
                        `${config.apiBaseUrl}/organisation/${organisation.id}/app/reports`,
                        {
                            headers: {
                                Authorization: `Bearer ${accessToken}`,
                            },
                        }
                    );
                    setReports(response.data);
                } catch (err) {
                    console.log(err);
                }

                setRefreshReports(false);
            };

            getReportsAsync();
        }
    }, [organisation, accessToken, refreshReports, isReportLink]);

    useEffect(() => {
        let countUnreadMessages = 0;
        let countUnreadComments = 0;
        let countOpenReports = 0;
        let countNewReports = 0;
        let countPendingReports = 0;
        for (let i = 0; i < reports.length; i++) {
            if (reports[i].hasUnreadMessage) {
                countUnreadMessages++;
            }
            if (reports[i].hasUnreadComment) {
                countUnreadComments++;
            }
            if (reports[i].status.startsWith('open')) {
                countOpenReports++;
            } else if (reports[i].status.startsWith('pending')) {
                countPendingReports++;
            } else if (reports[i].status === 'new') {
                countNewReports++;
            }
        }
        setNumNewReports(countNewReports);
        setNumOpenReports(countOpenReports);
        setNumPendingReports(countPendingReports);
        setNumUnreadMessages(countUnreadMessages);
        setNumUnreadComments(countUnreadComments);
    }, [reports]);

    useEffect(() => {
        if (
            isEmailVerified &&
            (organisation == null || organisation.id !== organisationId) &&
            isSignedIn &&
            isReady &&
            !isSigningIn &&
            !isReportLink &&
            organisationId != null &&
            accessToken != null
        ) {
            const getOrgAsync = async () => {
                // console.log('### Fetching organisation. ', organisationId);
                let error = null;
                try {
                    const response = await axios.get(
                        `${config.apiBaseUrl}/organisation/${organisationId}`,
                        {
                            headers: {
                                Authorization: `Bearer ${accessToken}`,
                            },
                        }
                    );
                    setContent({
                        ...response.data.content,
                        isFetching: false,
                    });
                    dispatch(organisationSet(response.data.organisation));
                    setUsers(response.data.users);
                    setAnalytics(response.data.analytics);

                    for (let i = 0; i < response.data.users.length; i++) {
                        if (response.data.users[i].id === user.id) {
                            // setOrgLink({
                            //     ...response.data.users[i]
                            // })
                            setIsOrgAdmin(
                                response.data.users[i].role === 'admin'
                            );
                            setCanManageReports(
                                response.data.users[i].role === 'admin' ||
                                    response.data.users[i].role === 'manage'
                            );
                            setCanReadReports(
                                response.data.users[i].role === 'admin' ||
                                    response.data.users[i].role === 'manage' ||
                                    response.data.users[i].role === 'read'
                            );
                            break;
                        }
                    }
                } catch (err: any) {
                    if (err && err.response && err.response.status) {
                        error = err.response.status;
                    } else {
                        console.error(err);
                        error = 1;
                    }
                    if (error === 404) {
                        await navigate(`/account/overview`);
                    } else {
                        console.log(error);
                        setOrganisationError(error);
                    }
                }
            };
            getOrgAsync();
        }
    }, [
        user,
        isEmailVerified,
        organisation,
        isSignedIn,
        isSigningIn,
        accessToken,
        isReportLink,
        organisationId,
        isReady,
        dispatch,
    ]);

    // useEffect(() => {
    //     if (isBrowser && isSignedIn && user.organisationId == null) {
    //         navigate(`/${language}/create`)
    //     }
    // }, [isSignedIn, user.organisationId, language])

    // Disable SSR for the app pages
    if (!isBrowser) {
        return null;
    }

    const goBackHandler = () => {
        if (matchReportId != null) {
            navigate(`/app/${organisationId}/reports`);
        }
    };

    const renderMenuItems = () => {
        if (isReportLink) {
            return (
                <>
                    {/* <div className={classes.toolbar}></div> */}
                    <DrawerHeader />
                    <List>
                        {organisation != null &&
                            organisation.reportLink.canReadReport === true && (
                                <ListItemButton
                                    key='report'
                                    selected={
                                        pathName ===
                                        `/app/${matchReportLink.organisationId}/link/${matchReportLink.linkId}/id/${matchReportLink.reportId}`
                                    }
                                    onClick={() =>
                                        navigate(
                                            `/app/${matchReportLink.organisationId}/link/${matchReportLink.linkId}/id/${matchReportLink.reportId}`
                                        )
                                    }
                                >
                                    <ListItemIcon>
                                        <Assignment />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={
                                            localization[language].app
                                                .labelReport
                                        }
                                    />
                                </ListItemButton>
                            )}
                        <ListItemButton
                            key='comments'
                            selected={
                                pathName ===
                                `/app/${matchReportLink.organisationId}/link/${matchReportLink.linkId}/id/${matchReportLink.reportId}/comments`
                            }
                            onClick={() =>
                                navigate(
                                    `/app/${matchReportLink.organisationId}/link/${matchReportLink.linkId}/id/${matchReportLink.reportId}/comments`
                                )
                            }
                        >
                            <ListItemIcon>
                                <Message />
                            </ListItemIcon>
                            <ListItemText
                                primary={
                                    localization[language].app.labelComments
                                }
                            />
                        </ListItemButton>
                    </List>
                    <Divider />
                    <List>
                        <ListItemButton onClick={handleSignOut}>
                            <ListItemIcon>
                                <ExitToApp />
                            </ListItemIcon>
                            <ListItemText
                                primary={
                                    localization[language].app.labelSignOut
                                }
                            />
                        </ListItemButton>
                    </List>
                </>
            );
        }
        if (matchReportId) {
            return (
                <>
                    <DrawerHeader />
                    {/* <div className={classes.toolbar}></div> */}
                    <List>
                        <ListItemButton key='Back' onClick={goBackHandler}>
                            <ListItemIcon>
                                <ArrowBack />
                            </ListItemIcon>
                            <ListItemText
                                primary={
                                    localization[language].app.backToReports
                                }
                            />
                        </ListItemButton>
                    </List>
                    <Divider />
                    <List>
                        <ListItemButton
                            key='report'
                            selected={
                                pathName ===
                                `/app/${organisationId}/reports/id/${matchReportId.reportId}`
                            }
                            onClick={() =>
                                navigate(
                                    `/app/${organisationId}/reports/id/${matchReportId.reportId}`
                                )
                            }
                        >
                            <ListItemIcon>
                                <Assignment />
                            </ListItemIcon>
                            <ListItemText
                                primary={localization[language].app.labelReport}
                            />
                        </ListItemButton>
                        <ListItemButton
                            key='followup'
                            selected={
                                pathName ===
                                `/app/${organisationId}/reports/id/${matchReportId.reportId}/follow-up`
                            }
                            onClick={() =>
                                navigate(
                                    `/app/${organisationId}/reports/id/${matchReportId.reportId}/follow-up`
                                )
                            }
                        >
                            <ListItemIcon
                                key='followup'
                                onClick={() =>
                                    navigate(
                                        `/app/${organisationId}/reports/id/${matchReportId.reportId}/follow-up`
                                    )
                                }
                            >
                                <Badge
                                    invisible={!hasUnreadMessage}
                                    variant='dot'
                                    color='secondary'
                                >
                                    <Forum />
                                </Badge>
                            </ListItemIcon>

                            <ListItemText
                                primary={localization[language].app.chatPortal}
                            />
                        </ListItemButton>
                    </List>
                    <Divider />
                    <List>
                        <ListItemButton
                            key='comments'
                            selected={
                                pathName ===
                                `/app/${organisationId}/reports/id/${matchReportId.reportId}/comments`
                            }
                            onClick={() =>
                                navigate(
                                    `/app/${organisationId}/reports/id/${matchReportId.reportId}/comments`
                                )
                            }
                        >
                            <ListItemIcon>
                                <Message />
                            </ListItemIcon>
                            <ListItemText
                                primary={
                                    localization[language].app.labelComments
                                }
                            />
                        </ListItemButton>
                        {canManageReports && (
                            <ListItemButton
                                key='sharing'
                                selected={
                                    pathName ===
                                    `/app/${organisationId}/reports/id/${matchReportId.reportId}/sharing`
                                }
                                onClick={() =>
                                    navigate(
                                        `/app/${organisationId}/reports/id/${matchReportId.reportId}/sharing`
                                    )
                                }
                            >
                                <ListItemIcon>
                                    <Share />
                                </ListItemIcon>
                                <ListItemText
                                    primary={
                                        localization[language].app.labelSharing
                                    }
                                />
                            </ListItemButton>
                        )}
                        <ListItemButton
                            key='activity'
                            selected={
                                pathName ===
                                `/app/${organisationId}/reports/id/${matchReportId.reportId}/activity`
                            }
                            onClick={() =>
                                navigate(
                                    `/app/${organisationId}/reports/id/${matchReportId.reportId}/activity`
                                )
                            }
                        >
                            <ListItemIcon>
                                <History />
                            </ListItemIcon>
                            <ListItemText
                                primary={
                                    localization[language].app.labelActivity
                                }
                            />
                        </ListItemButton>
                    </List>
                </>
            );
        }
        return (
            <>
                <DrawerHeader />
                {/* <div className={classes.toolbar}></div> */}
                <List>
                    {/* {matchReportId && (
                        <ListItemButton
                            key="Back"
                            onClick={goBackHandler}
                        >
                            <ListItemIcon><ArrowBack /></ListItemIcon>
                            <ListItemText primary={localization[language].app.labelReports} />
                        </ListItemButton>
                    )} */}
                    <ListItemButton
                        selected={pathName.startsWith(
                            `/app/${organisationId}/dashboard`
                        )}
                        onClick={() =>
                            navigate(`/app/${organisationId}/dashboard`)
                        }
                    >
                        <ListItemIcon>
                            <DashboardIcon />
                        </ListItemIcon>
                        <ListItemText
                            primary={localization[language].app.labelDashboard}
                        />
                    </ListItemButton>
                    {canReadReports && (
                        <ListItemButton
                            selected={pathName.startsWith(
                                `/app/${organisationId}/reports`
                            )}
                            onClick={() =>
                                navigate(`/app/${organisationId}/reports`)
                            }
                        >
                            <ListItemIcon>
                                <LibraryBooks />
                            </ListItemIcon>
                            <ListItemText
                                primary={
                                    localization[language].app.labelReports
                                }
                            />
                        </ListItemButton>
                    )}
                    <ListItemButton
                        key='activity'
                        selected={pathName.startsWith(
                            `/app/${organisationId}/activity`
                        )}
                        onClick={() =>
                            navigate(`/app/${organisationId}/activity`)
                        }
                    >
                        <ListItemIcon>
                            <ListIcon />
                        </ListItemIcon>
                        <ListItemText
                            primary={localization[language].app.labelActivity}
                        />
                    </ListItemButton>
                    <ListItemButton
                        selected={pathName.startsWith(
                            `/app/${organisationId}/users`
                        )}
                        onClick={() => navigate(`/app/${organisationId}/users`)}
                    >
                        <ListItemIcon>
                            <UsersIcon />
                        </ListItemIcon>
                        <ListItemText
                            primary={localization[language].app.labelUsers}
                        />
                    </ListItemButton>
                </List>
                <Divider />
                <List>
                    {isOrgAdmin && (
                        <>
                            <ListItemButton
                                key='content'
                                selected={pathName.startsWith(
                                    `/app/${organisationId}/content`
                                )}
                                onClick={() =>
                                    navigate(`/app/${organisationId}/content`)
                                }
                            >
                                <ListItemIcon>
                                    <Description />
                                </ListItemIcon>
                                <ListItemText
                                    primary={localization[language].app.content}
                                />
                            </ListItemButton>
                            <ListItemButton
                                key='form'
                                selected={pathName.startsWith(
                                    `/app/${organisationId}/form`
                                )}
                                onClick={() =>
                                    navigate(`/app/${organisationId}/form`)
                                }
                            >
                                <ListItemIcon>
                                    <AssignmentOutlined />
                                </ListItemIcon>
                                <ListItemText
                                    primary={
                                        localization[language].formEditor
                                            .reportForm
                                    }
                                />
                            </ListItemButton>
                            <ListItemButton
                                key='settings'
                                selected={
                                    pathName ===
                                    `/app/${organisationId}/settings`
                                }
                                onClick={() =>
                                    navigate(`/app/${organisationId}/settings`)
                                }
                            >
                                <ListItemIcon>
                                    <SettingsIcon />
                                </ListItemIcon>
                                <ListItemText
                                    primary={
                                        localization[language].app.labelSettings
                                    }
                                />
                            </ListItemButton>
                        </>
                    )}
                </List>

                {/* <Divider /> */}
                {/* <List>
                    <ListItemButton
                        key="settings"
                        selected={pathName === '/app/account'}
                        onClick={() => navigate('/app/account')}
                    >
                        <ListItemIcon><Person /></ListItemIcon>
                        <ListItemText primary={localization[language].app.labelAccount} />
                    </ListItemButton>
                </List> */}
                {/* {
                    isAdmin && (
                        <>
                            <Divider />
                            <List>
                                <ListItemButton
                                    key="organistaions"
                                    selected={pathName === '/app/organistaions'}
                                    onClick={() => navigate('/app/organistaions')}
                                >
                                    <ListItemIcon><BusinessIcon /></ListItemIcon>
                                    <ListItemText primary="Organisations" />
                                </ListItemButton>
                            </List>
                        </>
                    )
                } */}
            </>
        );
    };

    if (!isSignedIn && !isSigningIn && !isReportLink && isReady) {
        navigate(
            `/${language}/signin?redirect=${encodeURIComponent(
                location.pathname
            )}`
        );

        return null;
    }

    if (
        isSignedIn &&
        user != null &&
        !user.isEmailVerified &&
        !isReportLink &&
        isReady
    ) {
        navigate(`/${language}/email-verification`);

        return null;
    }

    if (organisationError) {
        console.log('Error getting organisation, redirecting to signin page');
        navigate(
            `/${language}/signin?redirect=${encodeURIComponent(
                location.pathname
            )}`
        );

        return null;
    }

    return (
        // <ThemeProvider theme={theme}>
        <>
            <SEO title={organisation != null ? organisation.name : ''} />
            <div>
                <CookieConsent language={language} />
            </div>
            <Box sx={{ display: 'flex' }}>
                {/* <CssBaseline /> */}
                <AppBar
                    position='fixed'
                    // className={clsx(classes.appBar, {
                    //     // [classes.appBarShift]: open,
                    // })}
                    open={open}
                >
                    <Toolbar>
                        {!isReportLinkInactive && (
                            <IconButton
                                color='inherit'
                                aria-label='open drawer'
                                onClick={toggleDrawer}
                                edge='start'
                                // className={clsx(classes.menuButton, {
                                //     [classes.hide]: open,
                                // })}
                            >
                                <MenuIcon />
                            </IconButton>
                        )}
                        <Typography variant='h6' noWrap>
                            {organisation == null && !isReportLinkInactive && (
                                <CircularProgress
                                    style={{ color: '#ffffff' }}
                                    size={20}
                                />
                            )}
                            {organisation !== null && organisation.name}
                            {isReportLinkInactive && 'Tilkynna'}
                        </Typography>
                        <Box sx={{ flexGrow: 1 }} />
                        <Box>
                            <IconButton
                                aria-label='change language'
                                aria-controls='menu-language'
                                aria-haspopup='true'
                                onClick={handleLanguageMenu}
                                color='inherit'
                            >
                                <Language />
                            </IconButton>

                            <Menu
                                id='menu-language'
                                anchorEl={anchorElLanguage}
                                transformOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}
                                open={Boolean(anchorElLanguage)}
                                onClose={handleClose}
                            >
                                <MenuItem disabled style={{ fontSize: '1em' }}>
                                    {language === 'is' ? 'Íslenska' : 'English'}
                                </MenuItem>
                                {language !== 'is' && (
                                    <MenuItem
                                        onClick={() => {
                                            changeLanguageAsync('is');
                                        }}
                                    >
                                        Íslenska
                                    </MenuItem>
                                )}
                                {language !== 'en' && (
                                    <MenuItem
                                        onClick={() => {
                                            changeLanguageAsync('en');
                                        }}
                                    >
                                        English
                                    </MenuItem>
                                )}
                            </Menu>

                            {!isReportLink && canReadReports && (
                                <IconButton
                                    aria-label='Reports that need attention'
                                    onClick={handleNotificationsMenu}
                                    color='inherit'
                                >
                                    <Badge
                                        showZero={false}
                                        badgeContent={
                                            notificationReports.length
                                        }
                                        // classes={{
                                        //     badge: classes.darkBadge,
                                        // }}
                                        color='secondary'
                                    >
                                        {notificationReports.length > 0 ? (
                                            <NotificationsActive />
                                        ) : (
                                            <Notifications />
                                        )}
                                    </Badge>
                                </IconButton>
                            )}
                            {isReportLink &&
                                reportLink != null &&
                                reportLink.message != null &&
                                reportLink.message.length > 0 && (
                                    <IconButton
                                        aria-label='Message'
                                        onClick={() =>
                                            setIsMessageDialogOpen(true)
                                        }
                                        color='inherit'
                                    >
                                        <Email />
                                    </IconButton>
                                )}

                            {!isReportLinkInactive && (
                                <IconButton
                                    aria-label='account of current user'
                                    aria-controls='menu-appbar'
                                    aria-haspopup='true'
                                    onClick={handleAccountMenu}
                                    color='inherit'
                                >
                                    <AccountCircle />
                                </IconButton>
                            )}

                            <Menu
                                id='menu-notifications'
                                anchorEl={anchorElNotifications}
                                transformOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}
                                open={Boolean(anchorElNotifications)}
                                onClose={handleClose}
                            >
                                {/* <MenuItem
                                    disabled
                                    style={{ fontSize: '1em' }}
                                >
                                    {localization[language].app.menuActiveReports}
                                </MenuItem> */}
                                {notificationReports.map((report: any) => (
                                    <MenuItem
                                        key={report.id}
                                        onClick={() => {
                                            navigate(
                                                `/app/${organisationId}/reports/id/${report.id}`
                                            );
                                            handleClose();
                                        }}
                                    >
                                        <ListItemIcon style={{ minWidth: 40 }}>
                                            <Badge
                                                showZero={false}
                                                variant='dot'
                                                color='secondary'
                                            >
                                                <Assignment fontSize='small' />
                                            </Badge>
                                        </ListItemIcon>
                                        {localization[language].app.labelReport}{' '}
                                        #{report.number} {report.name || ''}
                                    </MenuItem>
                                ))}
                                {notificationReports.length === 0 && (
                                    <MenuItem
                                        disabled
                                        style={{ fontSize: '1em' }}
                                    >
                                        {localization[language].app.noActivity}
                                    </MenuItem>
                                )}
                                <Divider />
                                <MenuItem
                                    onClick={() => {
                                        navigate(
                                            `/app/${organisationId}/reports`
                                        );
                                        handleClose();
                                    }}
                                >
                                    <ListItemIcon style={{ minWidth: 40 }}>
                                        <LibraryBooks fontSize='small' />
                                    </ListItemIcon>
                                    {localization[language].app.viewAll}
                                </MenuItem>
                            </Menu>

                            <Menu
                                id='menu-appbar'
                                anchorEl={anchorElAccount}
                                transformOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}
                                open={Boolean(anchorElAccount)}
                                onClose={handleClose}
                            >
                                {!isReportLink ? (
                                    <MenuItem
                                        disabled
                                        style={{ fontSize: '1em' }}
                                    >
                                        {user?.email || ''}
                                    </MenuItem>
                                ) : (
                                    <MenuItem
                                        disabled
                                        style={{ fontSize: '1em' }}
                                    >
                                        {reportLink != null &&
                                            reportLink.name != null && (
                                                <>{reportLink.name}</>
                                            )}
                                    </MenuItem>
                                )}

                                {!isReportLink && (
                                    <MenuItem
                                        onClick={() => {
                                            navigate(`/account/overview`);
                                            handleClose();
                                        }}
                                    >
                                        <ListItemIcon style={{ minWidth: 36 }}>
                                            <Person fontSize='small' />
                                        </ListItemIcon>
                                        {
                                            localization[language].app
                                                .labelAccount
                                        }
                                    </MenuItem>
                                )}
                                <MenuItem onClick={handleSignOut}>
                                    <ListItemIcon style={{ minWidth: 36 }}>
                                        <ExitToApp fontSize='small' />
                                    </ListItemIcon>
                                    {localization[language].app.labelSignOut}
                                </MenuItem>
                            </Menu>
                        </Box>
                    </Toolbar>
                </AppBar>

                {!isReportLinkInactive && (
                    <Drawer
                        variant='permanent'
                        open={open}
                        // className={clsx(classes.drawer, {
                        //     [classes.drawerOpen]: open,
                        //     [classes.drawerClose]: !open,
                        // })}
                        // classes={{
                        //     paper: clsx({
                        //         [classes.drawerOpen]: open,
                        //         [classes.drawerClose]: !open,
                        //         [classes.inner]: true,
                        //     }),
                        // }}
                    >
                        <div className={style.topDrawer}>
                            {renderMenuItems()}
                        </div>
                        {!isReportLink && (
                            <div className={style.bottomDrawer}>
                                <List>
                                    <ListItemButton
                                        onClick={() =>
                                            navigate('/account/overview')
                                        }
                                    >
                                        <ListItemIcon>
                                            <Person />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={
                                                localization[language].app
                                                    .labelAccount
                                            }
                                        />
                                    </ListItemButton>
                                </List>
                            </div>
                        )}
                    </Drawer>
                )}

                {/* <main className={classes.content}> */}
                <Box
                    component='main'
                    sx={{
                        flexGrow: 1,
                        p: 3,
                        backgroundColor: '#fff',
                        minHeight: '100vh',
                    }}
                >
                    <DrawerHeader />
                    {/* <div className={classes.toolbar} /> */}
                    <Router>
                        <Dashboard
                            path='/app/:organisationId/dashboard/*'
                            default
                            accessToken={accessToken}
                            organisation={organisation}
                            analytics={analytics}
                            users={users}
                            language={language}
                            content={content}
                            numUnreadMessages={numUnreadMessages}
                            numUnreadComments={numUnreadComments}
                            numOpenReports={numOpenReports}
                            numPendingReports={numPendingReports}
                            numNewReports={numNewReports}
                            organisationId={organisationId}
                        />
                        <ReportLink
                            path='/app/:organisationId/link/*'
                            isMessageDialogOpen={isMessageDialogOpen}
                            setIsMessageDialogOpen={setIsMessageDialogOpen}
                            organisation={organisation}
                            setOrganisation={setOrganisation}
                            reportLink={reportLink}
                            setReportLink={setReportLink}
                            setIsReportLinkInactive={setIsReportLinkInactive}
                            isReportLinkInactive={isReportLinkInactive}
                        />
                        <Reports
                            path='/app/:organisationId/reports/*'
                            accessToken={accessToken}
                            organisation={organisation}
                            content={content}
                            reports={reports}
                            setReports={setReports}
                            closedReports={closedReports}
                            setClosedReports={setClosedReports}
                            setRefreshRecords={setRefreshRecords}
                            setRefreshReports={setRefreshReports}
                            refreshReports={refreshReports}
                            users={users}
                            canManageReports={canManageReports}
                            notificationReports={notificationReports}
                            organisationId={organisationId}
                        />
                        <Users
                            path='/app/:organisationId/users/*'
                            accessToken={accessToken}
                            language={language}
                            organisation={organisation}
                            setOrganisation={setOrganisation}
                            users={users}
                            setUsers={setUsers}
                            isOrgAdmin={isOrgAdmin}
                            me={user}
                            organisationId={organisationId}
                        />
                        <Activity
                            path='/app/:organisationId/activity/*'
                            accessToken={accessToken}
                            organisation={organisation}
                            records={records}
                            setRecords={setRecords}
                            language={language}
                            refreshRecords={refreshRecords}
                            setRefreshRecords={setRefreshRecords}
                        />
                        <Content
                            path='/app/:organisationId/content/*'
                            language={language}
                            organisation={organisation}
                            setOrganisation={setOrganisation}
                            setContent={setContent}
                            content={content}
                            organisationId={organisationId}
                        />
                        <Form
                            path='/app/:organisationId/form/*'
                            language={language}
                            organisation={organisation}
                            setOrganisation={setOrganisation}
                            content={content}
                            setContent={setContent}
                            organisationId={organisationId}
                        />
                        <Settings
                            path='/app/:organisationId/settings'
                            language={language}
                            organisation={organisation}
                            setOrganisation={setOrganisation}
                            content={content}
                            setContent={setContent}
                            organisationId={organisationId}
                        />
                        <Account
                            path='/app/:organisationId/account'
                            language={language}
                            organisation={organisation}
                            setOrganisation={setOrganisation}
                            isOrgAdmin={isOrgAdmin}
                        />
                        <Organisations path='/app/organisations' />
                    </Router>
                </Box>
            </Box>
            {/* </ThemeProvider> */}
        </>
    );
};

export default App;
