import React from 'react';
import axios from 'axios';
import { Router, useMatch } from '@reach/router';
import Report from './Report';
import ReportList from './ReportList';
import config from '../../../config';
import NewReport from './NewReport';
import { useAppSelector } from '../../../hooks';
import * as style from './index.module.scss';

interface Props {
    organisation: any;
    accessToken: string;
    content: any;
    reports: any;
    setReports: any;
    location: any;
    closedReports: any;
    setClosedReports: any;
    setRefreshReports: any;
    refreshReports: any;
    setRefreshRecords: any;
    users: any;
    canManageReports: boolean;
    notificationReports: any;
    organisationId: string;
}

const Cases = ({
    organisation,
    accessToken,
    content,
    reports,
    setReports,
    location,
    closedReports,
    setClosedReports,
    setRefreshReports,
    refreshReports,
    setRefreshRecords,
    users,
    canManageReports,
    notificationReports,
    organisationId,
}: Props) => {
    const language = useAppSelector((state) => state.global.language);
    const [report, setReport] = React.useState(null);
    const [refreshClosedReports, setRefreshClosedReports] =
        React.useState(false);
    const matchReportId = useMatch(`/app/reports/id/:reportId/*`);

    let reportId = null;
    if (matchReportId != null && matchReportId.reportId != null) {
        reportId = matchReportId.reportId;
    }

    React.useEffect(() => {
        if (refreshClosedReports && organisation != null) {
            const fetchClosedReportsAsync = async () => {
                // console.log('# Fetching closed reports');
                try {
                    const response = await axios.get(
                        `${config.apiBaseUrl}/organisation/${organisation.id}/reports/closed`,
                        {
                            headers: {
                                Authorization: `Bearer ${accessToken}`,
                            },
                        }
                    );
                    setClosedReports(response.data);
                } catch (err) {
                    console.log(err);
                    if (err && err.response && err.response.status) {
                        // error = err.response.status
                    } else {
                        // console.error(err)
                    }
                }
                setRefreshClosedReports(false);
            };
            fetchClosedReportsAsync();
        }
    }, [accessToken, refreshClosedReports, organisation]);

    // React.useEffect(() => {
    //     if (report == null && reportId != null && reports.length > 0) {
    //         let found = false
    //         for (let i = 0; i < reports.length; i++) {
    //             if (reports[i].id === reportId) {
    //                 setReport({
    //                     ...reports[i],
    //                 })
    //                 found = true
    //                 break
    //             }
    //         }

    //         if (!found) {
    //             setReport({
    //                 id: reportId,
    //             })
    //         }
    //     }
    // }, [report, reportId, reports])

    return (
        <div className={style.reportsPage}>
            <Router>
                <ReportList
                    default
                    organisation={organisation}
                    content={content}
                    location={location}
                    setReport={setReport}
                    reports={reports}
                    language={language}
                    accessToken={accessToken}
                    setRefreshClosedReports={setRefreshClosedReports}
                    setRefreshReports={setRefreshReports}
                    refreshReports={refreshReports}
                    refreshClosedReports={refreshClosedReports}
                    closedReports={closedReports}
                    users={users}
                    canManageReports={canManageReports}
                    notificationReports={notificationReports}
                    organisationId={organisationId}
                />
                <NewReport
                    path='/new'
                    organisation={organisation}
                    accessToken={accessToken}
                    content={content}
                    report={report}
                    setReport={setReport}
                    reports={reports}
                    setReports={setReports}
                    language={language}
                    setRefreshClosedReports={setRefreshClosedReports}
                    setRefreshReports={setRefreshReports}
                    refreshReports={refreshReports}
                    reportId={reportId}
                    setRefreshRecords={setRefreshRecords}
                    users={users}
                    canManageReports={canManageReports}
                    organisationId={organisationId}
                />
                <Report
                    path='/id/:reportId/*'
                    organisation={organisation}
                    accessToken={accessToken}
                    content={content}
                    report={report}
                    setReport={setReport}
                    reports={reports}
                    setReports={setReports}
                    language={language}
                    setRefreshClosedReports={setRefreshClosedReports}
                    setRefreshReports={setRefreshReports}
                    refreshReports={refreshReports}
                    reportId={reportId}
                    setRefreshRecords={setRefreshRecords}
                    users={users}
                    canManageReports={canManageReports}
                    organisationId={organisationId}
                />
            </Router>
        </div>
    );
};

export default React.memo(Cases);
