import React from 'react';
import axios from 'axios';
import { Link, navigate } from 'gatsby';
import { ArrowBack } from '@mui/icons-material';
import {
    Button,
    FormControlLabel,
    ListItemIcon,
    ListItemText,
    ListItemButton,
    List,
    Card,
    CardContent,
    Checkbox,
    Dialog,
    DialogTitle,
    CircularProgress,
    DialogActions,
} from '@mui/material';
import config from '../../../config';
import localization from '../../../localization';
import * as style from './index.module.scss';

interface Props {
    language: string;
    organisation: any;
    accessToken: string;
    users: any[];
    setUsers: any;
    organisationId: string;
    me: any;
    user: any;
    setUser: any;
    isFetchingUsers: boolean;
}

const User = ({
    organisation,
    user,
    setUser,
    language,
    accessToken,
    users,
    setUsers,
    me,
    isFetchingUsers,
    organisationId,
}: Props) => {
    const [isDeleting, setIsDeleting] = React.useState(false);
    const [isSaving, setIsSaving] = React.useState(false);
    const [userRole, setUserRole] = React.useState(
        user != null ? user.role : 'admin'
    );
    const [sendEmailNotifications, setSendEmailNotifications] = React.useState(
        user != null ? user.sendEmailNotifications : true
    );
    const [showDeleteDialog, setShowDeleteDialog] = React.useState(false);

    React.useEffect(() => {
        // console.log('#### >>>> ', user)
        if (user != null) {
            setUserRole(user.role);
            setSendEmailNotifications(user.sendEmailNotifications);
        }
    }, [user]);

    const onDeleteAsync = async () => {
        setIsDeleting(true);
        try {
            await axios.delete(
                `${config.apiBaseUrl}/organisation/${organisation.id}/user/${user.id}`,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            );
            setUsers([...users.filter((u) => u.id !== user.id)]);
        } catch (err: any) {
            if (err && err.response && err.response.status) {
            } else {
                console.error(err);
            }
        }

        await navigate(`/app/${organisationId}/users`);
    };

    const saveAsync = async () => {
        setIsSaving(true);
        try {
            const response = await axios.put(
                `${config.apiBaseUrl}/organisation/${organisation.id}/user/${user.id}`,
                {
                    role: userRole,
                    sendEmailNotifications,
                },
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            );
            setUsers([response.data, ...users.filter((u) => u.id !== user.id)]);
            setUser(response.data);
            // console.log(response.data)
        } catch (err: any) {
            if (err && err.response && err.response.status) {
            } else {
                console.error(err);
            }
        }

        setIsSaving(false);
    };

    if (user == null || me == null) {
        return (
            <div className={style.editUserPage}>
                <h2>
                    <Link to={`/app/${organisationId}/users`}>
                        {localization[language].app.labelManageUsers}
                    </Link>{' '}
                    / {localization[language].app.labelEditUser}
                </h2>
                <List>
                    <ListItemButton
                        disableGutters
                        dense
                        disableRipple
                        onClick={() => navigate(`/app/${organisationId}/users`)}
                    >
                        <ListItemIcon style={{ minWidth: 36 }}>
                            <ArrowBack />
                        </ListItemIcon>
                        <ListItemText
                            primary={localization[language].app.labelUsers}
                        />
                    </ListItemButton>
                </List>

                <Card variant='outlined' className={style.userCard}>
                    <CardContent>
                        <h2>{localization[language].app.labelEditUser}</h2>
                        <CircularProgress />
                    </CardContent>
                </Card>
            </div>
        );
    }

    const isMe = user.id === me.id;

    return (
        <div className={style.editUserPage}>
            <h2>
                <Link to={`/app/${organisationId}/users`}>
                    {localization[language].app.labelManageUsers}
                </Link>{' '}
                / {localization[language].app.labelEditUser}
            </h2>
            <Card variant='outlined' className={style.userCard}>
                <CardContent className={style.item}>
                    {!user.isPendingInvitation ? (
                        <>
                            <h3>{localization[language].app.labelName}</h3>
                            <p>{user.name}</p>
                        </>
                    ) : (
                        <h3>{localization[language].invite.labelSent}</h3>
                    )}

                    <h3>{localization[language].app.labelEmail}</h3>
                    <p>{user.email}</p>
                    <h3>{localization[language].app.labelRole}</h3>
                    <form>
                        <select
                            value={userRole}
                            onChange={(e) => setUserRole(e.target.value)}
                            disabled={isMe}
                        >
                            <option value='admin'>
                                {localization[language].roles.admin}
                            </option>
                            <option value='manage'>
                                {localization[language].roles.manage}
                            </option>
                            <option value='read'>
                                {localization[language].roles.read}
                            </option>
                            <option value='analytics'>
                                {localization[language].roles.analytics}
                            </option>
                        </select>
                    </form>
                    <h3>{localization[language].app.labelSendEmail}</h3>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={sendEmailNotifications}
                                onChange={(e) =>
                                    setSendEmailNotifications(e.target.checked)
                                }
                                name='sendEmail'
                            />
                        }
                        label={localization[language].app.labelWillReceiveEmail}
                    />

                    <div className={style.formActions}>
                        <Button
                            variant='outlined'
                            onClick={saveAsync}
                            disabled={
                                isSaving ||
                                isDeleting ||
                                (user.role === userRole &&
                                    sendEmailNotifications ===
                                        user.sendEmailNotifications)
                            }
                        >
                            {isSaving
                                ? localization[language].app.labelSaving
                                : localization[language].app.labelSave}
                        </Button>
                        <Button
                            variant='outlined'
                            disabled={isDeleting || isSaving}
                            onClick={() =>
                                navigate(`/app/${organisationId}/users`)
                            }
                        >
                            {localization[language].form.labelCancel}
                        </Button>
                    </div>
                </CardContent>
            </Card>
            {me.role === 'admin' && (
                <Card className={style.dangerZone} variant='outlined'>
                    <CardContent>
                        <h2>{localization[language].app.labelActions}</h2>
                        <Button
                            variant='outlined'
                            color='secondary'
                            size='small'
                            onClick={() => setShowDeleteDialog(true)}
                            disabled={isDeleting || isSaving || isMe}
                        >
                            {localization[language].app.removeUser}
                        </Button>
                    </CardContent>
                </Card>
            )}
            <Dialog
                open={showDeleteDialog}
                onClose={() => setShowDeleteDialog(false)}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
            >
                <DialogTitle id='alert-dialog-title'>
                    {localization[language].users.confirmRemove}
                </DialogTitle>
                <DialogActions>
                    <Button
                        onClick={() => setShowDeleteDialog(false)}
                        disabled={isDeleting}
                    >
                        {localization[language].app.labelCancel}
                    </Button>
                    <Button
                        onClick={onDeleteAsync}
                        autoFocus
                        disabled={isDeleting}
                    >
                        {localization[language].app.labelContinue}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default React.memo(User);
