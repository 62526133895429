import React from 'react';
import axios from 'axios';
import {
    Button,
    Card,
    CardActions,
    CardContent,
    CircularProgress,
} from '@mui/material';
import localization from '../../../localization';
import * as style from './index.module.scss';
import config from '../../../config';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { userSet } from '../../../state/reducers/globalReducer';

const Settings = ({ language, organisation }) => {
    const [name, setName] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [isEditingName, setIsEditingName] = React.useState(false);
    const [isSavingName, setIsSavingName] = React.useState(false);
    const [isEditingPassword, setIsEditingPassword] = React.useState(false);
    const [isSavingPassword, setIsSavingPassword] = React.useState(false);

    const accessToken = useAppSelector(
        (state) => state.global.auth.accessToken
    );
    const user = useAppSelector((state) => state.global.user);
    const refName = React.useRef();
    const refPassword = React.useRef();
    const refIsRemoved = React.useRef(false);

    const dispatch = useAppDispatch();

    React.useEffect(() => {
        return () => {
            refIsRemoved.current = true;
        };
    }, []);

    const isValidName = name != null && name.length > 1;
    const isValidPassword = password != null && password.length > 4;

    const handleSaveName = async () => {
        setIsSavingName(true);
        const formData = new FormData();
        formData.append('name', name);

        try {
            const data = (
                await axios.put(`${config.apiBaseUrl}/account/name`, formData, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                })
            ).data;

            dispatch(
                userSet({
                    ...user,
                    name: data.name,
                })
            );
        } catch (err) {
            console.log(err);
        }
        setIsSavingName(false);
        setIsEditingName(false);
    };

    const handleSavePassword = async () => {
        setIsSavingPassword(true);
        const formData = new FormData();
        if (password != null) {
            formData.append('password', password);
        }

        try {
            await axios.put(`${config.apiBaseUrl}/account/password`, formData, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        } catch (err) {
            console.log(err);
        }
        setIsSavingPassword(false);
        setIsEditingPassword(false);
    };

    React.useEffect(() => {
        if (user != null) {
            setName(user.name);
            if (isEditingName && refName.current != null) {
                refName.current.focus();
            }
        }
    }, [isEditingName, user?.name]);

    React.useEffect(() => {
        if (isEditingPassword && refPassword.current != null) {
            refPassword.current.focus();
        }
    }, [isEditingPassword]);

    if (organisation == null || user == null) {
        return (
            <div className={style.accountPage}>
                <h2>{localization[language].app.labelAccount}</h2>
                <CircularProgress />
            </div>
        );
    }

    return (
        <div className={style.accountPage}>
            <h2>{localization[language].app.labelAccount}</h2>
            <Card className={style.item} variant='outlined'>
                <CardContent>
                    <label htmlFor='name'>
                        {localization[language].form.labelName}
                    </label>
                    {!isEditingName && (
                        <div className={style.currentSetting}>{user.name}</div>
                    )}
                    {isEditingName && (
                        <>
                            <input
                                ref={refName}
                                type='text'
                                name='name'
                                id='name'
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                readOnly={isSavingName}
                            />
                            <div className={style.actions}>
                                <Button
                                    onClick={handleSaveName}
                                    variant='outlined'
                                    size='small'
                                    disableElevation
                                    style={{
                                        outlineColor: 'rgba(66, 180, 230, .5)',
                                    }}
                                    disabled={isSavingName || !isValidName}
                                >
                                    {localization[language].app.labelSave}
                                </Button>
                            </div>
                        </>
                    )}
                </CardContent>
                <CardActions>
                    {!isEditingName ? (
                        <Button
                            variant='text'
                            onClick={() => setIsEditingName(true)}
                        >
                            {localization[language].app.labelEdit}
                        </Button>
                    ) : (
                        <Button
                            onClick={() => setIsEditingName(false)}
                            variant='text'
                            disabled={isSavingName}
                        >
                            {localization[language].app.labelCancel}
                        </Button>
                    )}
                </CardActions>
            </Card>

            <Card className={style.item} variant='outlined'>
                <CardContent>
                    <label>{localization[language].form.labelEmail}</label>
                    <div className={style.currentSetting}>{user.email}</div>
                </CardContent>
            </Card>

            <Card className={style.item} variant='outlined'>
                <CardContent>
                    <label>
                        {localization[language].app.labelLinkedOrganisation}
                    </label>
                    <div className={style.currentSetting}>
                        {organisation.name}
                    </div>
                    <h3>
                        {localization[language].app.labelRole}:{' '}
                        <span>{localization[language].roles[user.role]}</span>
                    </h3>
                </CardContent>
            </Card>

            <Card className={style.item} variant='outlined'>
                <CardContent>
                    <label htmlFor='password'>
                        {localization[language].register.labelPassword}
                    </label>
                    {!isEditingPassword && (
                        <>
                            <div className={style.currentSetting}>
                                {organisation.password}
                            </div>
                            <div className={style.actions}>
                                <Button
                                    onClick={() => setIsEditingPassword(true)}
                                    variant='outlined'
                                    size='small'
                                    disabled={
                                        isSavingPassword || !isValidPassword
                                    }
                                >
                                    {localization[language].app.labelEdit}
                                </Button>
                            </div>
                        </>
                    )}
                    {isEditingPassword && (
                        <>
                            <input
                                ref={refPassword}
                                type='password'
                                name='password'
                                id='password'
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                readOnly={isSavingPassword}
                            />
                            <div className={style.actions}>
                                <Button
                                    onClick={() => handleSavePassword(password)}
                                    variant='outlined'
                                    size='small'
                                    disableElevation
                                    style={{
                                        outlineColor: 'rgba(66, 180, 230, .5)',
                                    }}
                                    disabled={isSavingPassword}
                                >
                                    {localization[language].app.labelSave}
                                </Button>
                                <Button
                                    onClick={() => setIsEditingPassword(false)}
                                    variant='outlined'
                                    size='small'
                                    disabled={isSavingPassword}
                                >
                                    {localization[language].app.labelCancel}
                                </Button>
                            </div>
                        </>
                    )}
                </CardContent>
            </Card>
        </div>
    );
};

export default Settings;
