import React from 'react'

import {
    Typography
} from '@mui/material';

const Organisations = () => {
    return (
        <div>
            <Typography paragraph variant="h4">
                Skrá lögaðila
            </Typography>
        </div>
    )
}

export default Organisations
