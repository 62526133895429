import React from 'react';

import { faPaperclip } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    Avatar,
    Card,
    CardContent,
    CardHeader,
    Chip,
    CircularProgress,
    Grid,
} from '@mui/material';

import localization from '../../../localization';
import * as style from '../Reports/index.module.scss';
import DownloadBtn from '../DownloadBtn';
import SimpleDate from '../../SimpleDate';
import { navigate } from 'gatsby';
import { faUserCheck, faUserShield } from '@fortawesome/pro-solid-svg-icons';
import { IUser } from '../../../interfaces/IUser';

interface Props {
    language: string;
    report: any;
    assignedUsers: any;
    isFetching: boolean;
    reportLink: any;
    reportLabels: any;
    assignedLabelsIds: any;
    organisation: any;
}

const ReportDetails = ({
    language,
    report,
    assignedUsers,
    isFetching,
    reportLink,
    reportLabels,
    assignedLabelsIds,
    organisation,
}: Props) => {
    const [assignedLabels, setAssignedLabels] = React.useState([]);

    React.useEffect(() => {
        if (!isFetching && reportLink != null && !reportLink.canReadReport) {
            navigate('comments');
        }
    }, [isFetching, reportLink]);

    React.useEffect(() => {
        if (reportLabels == null || assignedLabelsIds == null) {
            return;
        }
        let tempAssignedLabels: any = [];
        let assignedDict: any = {};

        if (assignedLabelsIds != null) {
            for (let i = 0; i < assignedLabelsIds.length; i++) {
                assignedDict[assignedLabelsIds[i]] = true;
            }
        }

        for (let i = 0; i < reportLabels.length; i++) {
            if (assignedDict[reportLabels[i].id] === true) {
                tempAssignedLabels.push(reportLabels[i]);
            }
        }

        setAssignedLabels(tempAssignedLabels);
    }, [assignedLabelsIds, reportLabels]);

    const getReportChannelById = (reportAreaId) => {
        if (organisation == null || organisation.reportChannels == null) {
            return null;
        }

        for (let i = 0; i < organisation.reportChannels.length; i++) {
            if (organisation.reportChannels[i].id === reportAreaId) {
                return organisation.reportChannels[i];
            }
        }

        return null;
    };

    if (!isFetching && reportLink != null && !reportLink.canReadReport) {
        // console.log('Returning null')
        return null;
    }

    if (isFetching || report == null) {
        return (
            <div>
                <CircularProgress />
            </div>
        );
    }

    return (
        <>
            <Card variant='outlined' className={style.reportCard}>
                <CardHeader
                    avatar={
                        <Avatar aria-label='recipe'>
                            {report != null ? report.number : ''}
                        </Avatar>
                    }
                    title={`${localization[language].app.labelReport} #${
                        report != null ? report.number : ''
                    }`}
                    subheader={
                        <SimpleDate
                            customFormat='dd MMM, yyyy'
                            dateString={report != null ? report.created : null}
                        />
                    }
                />
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={5} lg={4}>
                            <Card
                                variant='outlined'
                                className={style.reportActionsCard}
                            >
                                {isFetching ? (
                                    <CardContent>
                                        <CircularProgress />
                                    </CardContent>
                                ) : (
                                    <CardContent>
                                        <div className={style.reportActions}>
                                            <div className={style.reportName}>
                                                <label htmlFor='reportName'>
                                                    {
                                                        localization[language]
                                                            .app.caseName
                                                    }
                                                </label>
                                                <p>
                                                    {report.name || (
                                                        <i>
                                                            {
                                                                localization[
                                                                    language
                                                                ].app.noCaseName
                                                            }
                                                        </i>
                                                    )}
                                                </p>
                                            </div>
                                            <div className={style.reportStatus}>
                                                <label>
                                                    {
                                                        localization[language]
                                                            .app.labelStatus
                                                    }
                                                </label>
                                                <p>
                                                    {
                                                        localization[language]
                                                            .reportStatus[
                                                            report.status
                                                        ]
                                                    }
                                                </p>
                                            </div>
                                            {assignedUsers != null &&
                                                assignedUsers.length > 0 && (
                                                    <div
                                                        className={
                                                            style.assignedUsers
                                                        }
                                                    >
                                                        <label>
                                                            {
                                                                localization[
                                                                    language
                                                                ].app
                                                                    .assignedUsers
                                                            }
                                                        </label>
                                                        {assignedUsers.map(
                                                            (user: IUser) => (
                                                                <Chip
                                                                    key={
                                                                        user.id
                                                                    }
                                                                    variant='outlined'
                                                                    className={
                                                                        style.assignedUser
                                                                    }
                                                                    label={
                                                                        user.name
                                                                    }
                                                                />
                                                            )
                                                        )}
                                                    </div>
                                                )}
                                            {assignedLabels != null &&
                                                assignedLabels.length > 0 && (
                                                    <div
                                                        className={
                                                            style.assignedLabels
                                                        }
                                                    >
                                                        <label>
                                                            {
                                                                localization[
                                                                    language
                                                                ].app.labels
                                                            }
                                                        </label>
                                                        {assignedLabels.map(
                                                            (label: any) => (
                                                                <Chip
                                                                    key={
                                                                        label.id
                                                                    }
                                                                    className={
                                                                        style.assignedLabel
                                                                    }
                                                                    label={
                                                                        label
                                                                            .title
                                                                            .is ||
                                                                        label
                                                                            .title
                                                                            .en
                                                                    }
                                                                    style={{
                                                                        backgroundColor:
                                                                            label.color,
                                                                        color: '#ffffff',
                                                                    }}
                                                                />
                                                            )
                                                        )}
                                                    </div>
                                                )}
                                            {report.reportAreaId != null &&
                                                organisation.reportChannels !=
                                                    null &&
                                                getReportChannelById(
                                                    report.reportAreaId
                                                ) != null && (
                                                    <div
                                                        className={
                                                            style.reportArea
                                                        }
                                                    >
                                                        <label>
                                                            {
                                                                localization[
                                                                    language
                                                                ].app.reportArea
                                                            }
                                                        </label>
                                                        <p>
                                                            {
                                                                getReportChannelById(
                                                                    report.reportAreaId
                                                                ).subdomain
                                                            }
                                                        </p>
                                                    </div>
                                                )}
                                        </div>
                                    </CardContent>
                                )}
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={7} lg={8}>
                            <Card variant='outlined'>
                                <CardContent>
                                    <h2>
                                        {localization[language].app.labelReport}
                                    </h2>
                                    {report.fields != null &&
                                    report.fields.length > 0 ? (
                                        <>
                                            {report.fields.map((field: any) => (
                                                <div
                                                    className={style.item}
                                                    key={field.id}
                                                >
                                                    <h3>
                                                        {field.title[language]}
                                                    </h3>
                                                    {(field.fieldType ===
                                                        'text' ||
                                                        field.fieldType ===
                                                            'textarea') && (
                                                        <p>
                                                            {field.value !=
                                                                null &&
                                                            field.value.length >
                                                                0 ? (
                                                                field.value
                                                            ) : (
                                                                <i>
                                                                    {
                                                                        localization[
                                                                            language
                                                                        ].form
                                                                            .labelNotGiven
                                                                    }
                                                                </i>
                                                            )}
                                                        </p>
                                                    )}
                                                    {field.fieldType ===
                                                        'file' && (
                                                        <>
                                                            {(field.files ==
                                                                null ||
                                                                field.files
                                                                    .length ===
                                                                    0) && (
                                                                <p>
                                                                    {
                                                                        localization[
                                                                            language
                                                                        ].form
                                                                            .labelNoAttachment
                                                                    }
                                                                </p>
                                                            )}

                                                            {field.files !=
                                                                null &&
                                                                field.files
                                                                    .length >
                                                                    0 && (
                                                                    <ul>
                                                                        {field.files.map(
                                                                            (
                                                                                file: string
                                                                            ) => (
                                                                                <li
                                                                                    key={
                                                                                        file
                                                                                    }
                                                                                >
                                                                                    <span>
                                                                                        <FontAwesomeIcon
                                                                                            icon={
                                                                                                faPaperclip
                                                                                            }
                                                                                            fixedWidth
                                                                                        />
                                                                                    </span>
                                                                                    <DownloadBtn
                                                                                        file={
                                                                                            file
                                                                                        }
                                                                                        report={
                                                                                            report
                                                                                        }
                                                                                        isReportLink={
                                                                                            true
                                                                                        }
                                                                                        reportLink={
                                                                                            reportLink
                                                                                        }
                                                                                    />
                                                                                </li>
                                                                            )
                                                                        )}
                                                                    </ul>
                                                                )}
                                                        </>
                                                    )}
                                                    {(field.fieldType ===
                                                        'radio' ||
                                                        field.fieldType ===
                                                            'dropdown') &&
                                                        field.choices != null &&
                                                        field.choices.length >
                                                            0 && (
                                                            <>
                                                                <p>
                                                                    {field.choices.filter(
                                                                        (
                                                                            choice
                                                                        ) =>
                                                                            choice.isSelected ===
                                                                            true
                                                                    )[0] != null
                                                                        ? field.choices.filter(
                                                                              (
                                                                                  choice
                                                                              ) =>
                                                                                  choice.isSelected ===
                                                                                  true
                                                                          )[0]
                                                                              .title[
                                                                              language
                                                                          ]
                                                                        : null}
                                                                </p>
                                                            </>
                                                        )}
                                                    {field.fieldType ===
                                                        'checkboxes' &&
                                                        field.choices != null &&
                                                        field.choices.length >
                                                            0 && (
                                                            <ul>
                                                                {field.choices
                                                                    .filter(
                                                                        (
                                                                            choice: any
                                                                        ) =>
                                                                            choice.isSelected ===
                                                                            true
                                                                    )
                                                                    .map(
                                                                        (
                                                                            choice: any
                                                                        ) => (
                                                                            <li
                                                                                key={
                                                                                    choice.id
                                                                                }
                                                                            >
                                                                                {
                                                                                    choice
                                                                                        .title[
                                                                                        language
                                                                                    ]
                                                                                }
                                                                            </li>
                                                                        )
                                                                    )}
                                                            </ul>
                                                        )}
                                                </div>
                                            ))}
                                        </>
                                    ) : (
                                        <>
                                            <p></p>
                                        </>
                                    )}
                                    <div className={style.item}>
                                        <h3>
                                            {
                                                localization[language].app
                                                    .labelNotifier
                                            }
                                        </h3>
                                        {report.notifierMethod === '0' && (
                                            <p>
                                                {
                                                    localization[language].app
                                                        .isAnonymous
                                                }
                                            </p>
                                        )}

                                        {report.notifierMethod === '1' && (
                                            <>
                                                {report.isVerified && (
                                                    <p
                                                        className={
                                                            style.verified
                                                        }
                                                    >
                                                        <FontAwesomeIcon
                                                            icon={faUserCheck}
                                                        />{' '}
                                                        <i>
                                                            {
                                                                localization[
                                                                    language
                                                                ].icelandauth
                                                                    .identified
                                                            }
                                                        </i>
                                                    </p>
                                                )}
                                                {report.anonymity && (
                                                    <p
                                                        className={
                                                            style.anonymity
                                                        }
                                                    >
                                                        <FontAwesomeIcon
                                                            icon={faUserShield}
                                                        />
                                                        <i>
                                                            {' '}
                                                            {
                                                                localization[
                                                                    language
                                                                ].notifier
                                                                    .wantsAnonymity
                                                            }
                                                        </i>
                                                    </p>
                                                )}
                                            </>
                                        )}
                                    </div>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </>
    );
};

export default React.memo(ReportDetails);
