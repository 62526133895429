import React from 'react';
import axios from 'axios';
import {
    Button,
    Card,
    CardActions,
    CardContent,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    FormHelperText,
    Grid,
} from '@mui/material';
import localization from '../../../localization';
import config from '../../../config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLockAlt } from '@fortawesome/pro-light-svg-icons';
import * as style from './index.module.scss';
import { useAppSelector } from '../../../hooks';
import { ReportSettings } from '../../../interfaces/ReportSettings';
import { Content } from '../../../interfaces/Content';
import { IReportChannel } from '../../../interfaces/IReportChannel';

const defaultReportSettings: ReportSettings = {
    allowAnonymousReports: false,
    allowFollowUpAttachments: true,
    askForEmail: true,
    askForPersonalIdNumber: false,
    askForPhoneNumber: false,
    icelandAuthEnabled: true,
    icelandAuthRequired: false,
    canSeeRecipients: false,
    canToggleRecipients: false,
};

interface Props {
    language: string;
    organisation: any;
    setOrganisation: any;
    content: Content;
    setContent: any;
}

interface SelectedReportChannel extends IReportChannel {
    reportSettings: ReportSettings;
    isNew: boolean;
}

const ReactAreas = ({
    language,
    organisation,
    setOrganisation,
    content,
    setContent,
}: Props) => {
    const [isSaving, setIsSaving] = React.useState(false);
    const [selectedReportChannel, setSelectedReportChannel] =
        React.useState<SelectedReportChannel | null>(null);
    const [showReportChannelDialog, setShowReportChannelDialog] =
        React.useState(false);
    const [isSubdomainTaken, setIsSubdomainTaken] = React.useState(false);
    const refIsRemoved = React.useRef(false);

    const accessToken = useAppSelector(
        (state) => state.global.auth.accessToken
    );

    React.useEffect(() => {
        return () => {
            refIsRemoved.current = true;
        };
    }, []);

    React.useEffect(() => {
        setShowReportChannelDialog(selectedReportChannel != null);
    }, [selectedReportChannel]);

    if (organisation == null) {
        return null;
    }

    const createReportChannelClick = () => {
        setSelectedReportChannel({
            // created: '',
            id: 'temp_' + new Date().getTime().toString(),
            organisationId: organisation.id,
            organisationName: organisation.name,
            // title: '',
            subdomain: '',
            password: '',
            name: '',
            logo: null,
            hasPassword: false,
            isNew: true,

            reportSettings: {
                ...defaultReportSettings,
            },
        });
    };

    const onSelectReportChannel = (reportChannel: IReportChannel) => {
        let existingReportChannelSettings = {};

        if (
            content != null &&
            content.reportChannelSettings != null &&
            content.reportChannelSettings[reportChannel.id] != null
        ) {
            existingReportChannelSettings =
                content.reportChannelSettings[reportChannel.id];
        }

        setSelectedReportChannel({
            ...reportChannel,
            password: '',
            hasPassword:
                reportChannel.password != null &&
                reportChannel.password.length > 0,
            reportSettings: {
                ...defaultReportSettings,
                ...existingReportChannelSettings,
            },
            isNew: false,
        });
    };

    const onReportChannelSubdomainChange = (subdomain: string) => {
        if (selectedReportChannel === null) {
            return;
        }
        setSelectedReportChannel({
            ...selectedReportChannel,
            subdomain,
        });
    };

    const onChangeReportSetting = (e: any) => {
        if (selectedReportChannel === null) {
            return;
        }
        setSelectedReportChannel({
            ...selectedReportChannel,
            reportSettings: {
                ...selectedReportChannel.reportSettings,
                [e.target.name]: e.target.checked,
            },
        });
    };

    const onReportChannelDeletePassword = () => {
        if (selectedReportChannel === null) {
            return;
        }
        setSelectedReportChannel({
            ...selectedReportChannel,
            password: '',
            hasPassword: false,
        });
    };

    const onReportChannelPasswordChange = (password: string) => {
        if (selectedReportChannel === null) {
            return;
        }
        setSelectedReportChannel({
            ...selectedReportChannel,
            password,
        });
    };

    const onSaveReportChannelAsync = async () => {
        if (selectedReportChannel === null) {
            return;
        }
        setIsSaving(true);
        if (isSubdomainTaken) {
            setIsSubdomainTaken(false);
        }
        const updatedReportChannels = [...organisation.reportChannels];

        let error = null;

        try {
            const res = await axios.post(
                `${config.apiBaseUrl}/organisation/${organisation.id}/reportchannels`,
                {
                    reportChannel: selectedReportChannel,
                    hasPassword:
                        selectedReportChannel.hasPassword ||
                        selectedReportChannel.password.length > 0,
                    reportSettings: selectedReportChannel.reportSettings,
                    isNew: selectedReportChannel.isNew,
                },
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            );

            if (selectedReportChannel.isNew) {
                updatedReportChannels.push(res.data.reportChannel);
            } else {
                for (let i = 0; i < updatedReportChannels.length; i++) {
                    if (
                        updatedReportChannels[i].id === selectedReportChannel.id
                    ) {
                        updatedReportChannels[i] = {
                            ...res.data.reportChannel,
                        };
                    }
                }
            }
            setOrganisation({
                ...organisation,
                reportChannels: updatedReportChannels,
            });

            setContent(res.data.content);
        } catch (err: any) {
            console.log(err);

            if (
                err &&
                err.response &&
                err.response.status &&
                err.response.status === 409
            ) {
                error = 409;
                setIsSubdomainTaken(true);
            }
        }
        setIsSaving(false);

        if (error == null) {
            setSelectedReportChannel(null);
        }
    };

    const onDeleteReportChannelAsync = async () => {
        if (selectedReportChannel === null) {
            return;
        }
        setIsSaving(true);

        const updatedReportChannels = [
            ...organisation.reportChannels.filter(
                (r: IReportChannel) =>
                    r.subdomain !== selectedReportChannel.subdomain
            ),
        ];

        try {
            await axios.delete(
                `${config.apiBaseUrl}/organisation/${organisation.id}/reportchannels/${selectedReportChannel.subdomain}`,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            );

            setOrganisation({
                ...organisation,
                reportChannels: updatedReportChannels,
            });
        } catch (err) {
            console.log(err);
        }
        setIsSaving(false);
        setSelectedReportChannel(null);
    };

    const onCloseReportChannelDialog = () => {
        setSelectedReportChannel(null);
    };

    const isValidReportChannel =
        selectedReportChannel != null &&
        selectedReportChannel.subdomain.length > 1;

    return (
        <Card className={style.item} variant='outlined'>
            <CardContent>
                <h3>{localization[language].app.reportAreas}</h3>

                <div className={style.reportChannels}>
                    {organisation != null &&
                        organisation.reportChannels != null &&
                        organisation.reportChannels.map(
                            (channel: IReportChannel) => (
                                <Card
                                    variant='outlined'
                                    key={channel.subdomain || 'new'}
                                    className={style.reportChannel}
                                >
                                    <CardContent>
                                        <h3>
                                            {channel.subdomain}
                                            <i>.tilkynna.is</i>
                                        </h3>

                                        {channel.password != null &&
                                            channel.password.length > 0 && (
                                                <div
                                                    className={
                                                        style.passwordProtected
                                                    }
                                                >
                                                    <FontAwesomeIcon
                                                        icon={faLockAlt}
                                                        fixedWidth
                                                    />{' '}
                                                    {
                                                        localization[language]
                                                            .app
                                                            .reportAreaProtectedWithPassword
                                                    }
                                                </div>
                                            )}
                                    </CardContent>
                                    <CardActions>
                                        <Button
                                            size='small'
                                            color='primary'
                                            onClick={() =>
                                                onSelectReportChannel(channel)
                                            }
                                        >
                                            {
                                                localization[language].app
                                                    .editReportArea
                                            }
                                        </Button>
                                    </CardActions>
                                </Card>
                            )
                        )}
                    {(organisation == null ||
                        organisation.reportChannels == null) && (
                        <CircularProgress />
                    )}
                </div>

                <Button
                    color='primary'
                    variant='outlined'
                    size='small'
                    onClick={createReportChannelClick}
                >
                    {localization[language].app.createReportArea}
                </Button>
            </CardContent>

            <Dialog
                fullWidth={true}
                maxWidth='lg'
                className={style.dialog}
                open={showReportChannelDialog}
                onClose={onCloseReportChannelDialog}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
            >
                <DialogTitle id='alert-dialog-title'>
                    {selectedReportChannel != null &&
                    selectedReportChannel.isNew
                        ? localization[language].app.createReportArea
                        : localization[language].app.editReportArea}
                </DialogTitle>
                {selectedReportChannel != null && (
                    <DialogContent className={style.reportChannelDialogContent}>
                        <Card variant='outlined'>
                            <CardContent>
                                <label
                                    htmlFor='subdomain'
                                    className={style.label}
                                >
                                    {
                                        localization[language].create
                                            .labelSubdomain
                                    }
                                </label>
                                <input
                                    // disabled={isSaving}
                                    autoFocus={selectedReportChannel.isNew}
                                    // ref={lang.short === 'is' ? choiceTitleRef : null}
                                    type='text'
                                    disabled={isSaving}
                                    maxLength={200}
                                    style={{ maxWidth: 400 }}
                                    id='subdomain'
                                    name='subdomain'
                                    value={selectedReportChannel.subdomain}
                                    onChange={(e) =>
                                        onReportChannelSubdomainChange(
                                            e.target.value
                                        )
                                    }
                                />
                                <FormHelperText>
                                    {
                                        localization[language].create
                                            .labelSubdomainHelp
                                    }
                                </FormHelperText>
                            </CardContent>
                            <CardContent>
                                <label
                                    htmlFor='password'
                                    className={style.label}
                                >
                                    {
                                        localization[language].app
                                            .labelOptionalPassword
                                    }
                                </label>
                                {selectedReportChannel.hasPassword ? (
                                    <Button
                                        color='secondary'
                                        variant='outlined'
                                        size='small'
                                        onClick={onReportChannelDeletePassword}
                                    >
                                        {
                                            localization[language].app
                                                .deleteCurrentPassword
                                        }
                                    </Button>
                                ) : (
                                    <input
                                        disabled={isSaving}
                                        style={{
                                            maxWidth: 400,
                                            marginBottom: '1em',
                                        }}
                                        // ref={lang.short === 'is' ? choiceTitleRef : null}
                                        type='text'
                                        maxLength={200}
                                        id='password'
                                        name='password'
                                        value={selectedReportChannel.password}
                                        onChange={(e) =>
                                            onReportChannelPasswordChange(
                                                e.target.value
                                            )
                                        }
                                    />
                                )}
                            </CardContent>
                            <CardContent>
                                <Card
                                    variant='outlined'
                                    className={style.reportChannelSettings}
                                >
                                    <CardContent>
                                        <p style={{ marginTop: 0 }}>
                                            {
                                                localization[language].app
                                                    .labelReports
                                            }
                                        </p>
                                        <div>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        size='small'
                                                        checked={
                                                            selectedReportChannel
                                                                .reportSettings
                                                                .allowAnonymousReports
                                                        }
                                                        //checked={reportSettings.allowAnonymousReports}
                                                        onChange={
                                                            onChangeReportSetting
                                                        }
                                                        name='allowAnonymousReports'
                                                        color='primary'
                                                        disabled={isSaving}
                                                    />
                                                }
                                                label={
                                                    localization[language].app
                                                        .allowAnonymousReports
                                                }
                                            />
                                        </div>
                                        <div>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        size='small'
                                                        checked={
                                                            selectedReportChannel
                                                                .reportSettings
                                                                .icelandAuthEnabled
                                                        }
                                                        onChange={
                                                            onChangeReportSetting
                                                        }
                                                        name='icelandAuthEnabled'
                                                        color='primary'
                                                        disabled={isSaving}
                                                    />
                                                }
                                                label={
                                                    localization[language]
                                                        .icelandauth.enabled
                                                }
                                            />
                                        </div>
                                        <div>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        size='small'
                                                        checked={
                                                            selectedReportChannel
                                                                .reportSettings
                                                                .icelandAuthRequired
                                                        }
                                                        onChange={
                                                            onChangeReportSetting
                                                        }
                                                        name='icelandAuthRequired'
                                                        color='primary'
                                                        disabled={
                                                            isSaving ||
                                                            !selectedReportChannel
                                                                .reportSettings
                                                                .icelandAuthEnabled
                                                        }
                                                    />
                                                }
                                                label={
                                                    localization[language]
                                                        .icelandauth
                                                        .mandatorySetting
                                                }
                                            />
                                        </div>
                                        <div style={{ display: 'none' }}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        size='small'
                                                        checked={
                                                            selectedReportChannel
                                                                .reportSettings
                                                                .canSeeRecipients
                                                        }
                                                        onChange={
                                                            onChangeReportSetting
                                                        }
                                                        name='canSeeRecipients'
                                                        color='primary'
                                                        disabled={isSaving}
                                                    />
                                                }
                                                label={
                                                    localization[language].app
                                                        .canSeeRecipients
                                                }
                                            />
                                        </div>
                                        <div style={{ display: 'none' }}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        size='small'
                                                        checked={
                                                            selectedReportChannel
                                                                .reportSettings
                                                                .canToggleRecipients
                                                        }
                                                        onChange={
                                                            onChangeReportSetting
                                                        }
                                                        name='canToggleRecipients'
                                                        color='primary'
                                                        disabled={
                                                            isSaving ||
                                                            !selectedReportChannel
                                                                .reportSettings
                                                                .canSeeRecipients
                                                        }
                                                    />
                                                }
                                                label={
                                                    localization[language].app
                                                        .canToggleRecipients
                                                }
                                            />
                                        </div>
                                        <div className={style.notifier}>
                                            <p>
                                                {
                                                    localization[language]
                                                        .notifier
                                                        .notifierSettings
                                                }
                                            </p>
                                            <Grid
                                                container
                                                spacing={0}
                                                rowSpacing={0}
                                                columnSpacing={0}
                                            >
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={12}
                                                    md={3}
                                                >
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                size='small'
                                                                checked={
                                                                    selectedReportChannel
                                                                        .reportSettings
                                                                        .askForPersonalIdNumber
                                                                }
                                                                onChange={
                                                                    onChangeReportSetting
                                                                }
                                                                name='askForPersonalIdNumber'
                                                                color='primary'
                                                                disabled={
                                                                    isSaving
                                                                }
                                                            />
                                                        }
                                                        label={
                                                            localization[
                                                                language
                                                            ].app
                                                                .askForPersonalIdNumber
                                                        }
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={12}
                                                    md={3}
                                                >
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                size='small'
                                                                checked={
                                                                    selectedReportChannel
                                                                        .reportSettings
                                                                        .askForEmail
                                                                }
                                                                onChange={
                                                                    onChangeReportSetting
                                                                }
                                                                name='askForEmail'
                                                                color='primary'
                                                                disabled={
                                                                    isSaving
                                                                }
                                                            />
                                                        }
                                                        label={
                                                            localization[
                                                                language
                                                            ].app.askForEmail
                                                        }
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={12}
                                                    md={4}
                                                >
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                size='small'
                                                                checked={
                                                                    selectedReportChannel
                                                                        .reportSettings
                                                                        .askForPhoneNumber
                                                                }
                                                                onChange={
                                                                    onChangeReportSetting
                                                                }
                                                                name='askForPhoneNumber'
                                                                color='primary'
                                                                disabled={
                                                                    isSaving
                                                                }
                                                            />
                                                        }
                                                        label={
                                                            localization[
                                                                language
                                                            ].app
                                                                .askForPhoneNumber
                                                        }
                                                    />
                                                </Grid>
                                            </Grid>
                                        </div>

                                        <Grid
                                            container
                                            spacing={0}
                                            rowSpacing={0}
                                            columnSpacing={0}
                                        >
                                            <Grid item xs={12} sm={12} md={3}>
                                                <FormControlLabel
                                                    disabled={
                                                        !selectedReportChannel
                                                            .reportSettings
                                                            .askForPersonalIdNumber ||
                                                        isSaving
                                                    }
                                                    control={
                                                        <Checkbox
                                                            size='small'
                                                            checked={
                                                                selectedReportChannel
                                                                    .reportSettings
                                                                    .isPersonalIdNumberRequired &&
                                                                selectedReportChannel
                                                                    .reportSettings
                                                                    .askForPersonalIdNumber
                                                            }
                                                            onChange={
                                                                onChangeReportSetting
                                                            }
                                                            name='isPersonalIdNumberRequired'
                                                            color='primary'
                                                            disabled={isSaving}
                                                        />
                                                    }
                                                    label={
                                                        localization[language]
                                                            .app
                                                            .personalIdNumberIsRequired
                                                    }
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={3}>
                                                <FormControlLabel
                                                    disabled={
                                                        !selectedReportChannel
                                                            .reportSettings
                                                            .askForEmail ||
                                                        isSaving
                                                    }
                                                    control={
                                                        <Checkbox
                                                            size='small'
                                                            checked={
                                                                selectedReportChannel
                                                                    .reportSettings
                                                                    .isEmailRequired &&
                                                                selectedReportChannel
                                                                    .reportSettings
                                                                    .askForEmail
                                                            }
                                                            onChange={
                                                                onChangeReportSetting
                                                            }
                                                            name='isEmailRequired'
                                                            color='primary'
                                                            disabled={isSaving}
                                                        />
                                                    }
                                                    label={
                                                        localization[language]
                                                            .app.emailIsRequired
                                                    }
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={4}>
                                                <FormControlLabel
                                                    disabled={
                                                        !selectedReportChannel
                                                            .reportSettings
                                                            .askForPhoneNumber ||
                                                        isSaving
                                                    }
                                                    control={
                                                        <Checkbox
                                                            size='small'
                                                            checked={
                                                                selectedReportChannel
                                                                    .reportSettings
                                                                    .isPhoneNumberRequired &&
                                                                selectedReportChannel
                                                                    .reportSettings
                                                                    .askForPhoneNumber
                                                            }
                                                            onChange={
                                                                onChangeReportSetting
                                                            }
                                                            name='isPhoneNumberRequired'
                                                            color='primary'
                                                            disabled={isSaving}
                                                        />
                                                    }
                                                    label={
                                                        localization[language]
                                                            .app
                                                            .phoneNumberIsRequired
                                                    }
                                                />
                                            </Grid>
                                        </Grid>
                                        <div className={style.followUp}>
                                            <p>
                                                {
                                                    localization[language].form
                                                        .labelFollowUp
                                                }
                                            </p>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        size='small'
                                                        checked={
                                                            selectedReportChannel
                                                                .reportSettings
                                                                .allowFollowUpAttachments
                                                        }
                                                        onChange={
                                                            onChangeReportSetting
                                                        }
                                                        name='allowFollowUpAttachments'
                                                        color='primary'
                                                        disabled={isSaving}
                                                    />
                                                }
                                                label={
                                                    localization[language].app
                                                        .allowFollowUpAttachments
                                                }
                                            />
                                        </div>
                                    </CardContent>
                                </Card>

                                {isSubdomainTaken && (
                                    <p
                                        style={{
                                            color: 'red',
                                            marginBottom: 0,
                                        }}
                                    >
                                        {
                                            localization[language].create
                                                .labelSubdomainInUse
                                        }
                                    </p>
                                )}
                            </CardContent>
                        </Card>
                    </DialogContent>
                )}
                {selectedReportChannel != null && !selectedReportChannel.isNew && (
                    <DialogActions>
                        <Button
                            color='secondary'
                            size='small'
                            onClick={onDeleteReportChannelAsync}
                            disabled={isSaving}
                        >
                            {localization[language].app.deleteReportArea}
                        </Button>
                    </DialogActions>
                )}
                <DialogActions>
                    <Button
                        onClick={onSaveReportChannelAsync}
                        variant='contained'
                        disabled={isSaving || !isValidReportChannel}
                    >
                        {localization[language].app.labelSave}
                    </Button>
                    <Button
                        onClick={onCloseReportChannelDialog}
                        variant='outlined'
                        disabled={isSaving}
                    >
                        {localization[language].app.labelCancel}
                    </Button>
                </DialogActions>
            </Dialog>
        </Card>
    );
};

export default React.memo(ReactAreas);
