import React from 'react';
import axios from 'axios';

import {
    Avatar,
    Button,
    Card,
    CardContent,
    CardHeader,
    CircularProgress,
    IconButton,
} from '@mui/material';
import localization from '../../../localization';
import config from '../../../config';
import { DeleteForever } from '@mui/icons-material';
import * as style from './index.module.scss';

const getFileName = (filename: string | null) => {
    if (filename == null) return '';
    if (filename.split('/').length === 0) return filename;
    return filename.split('/')[filename.split('/').length - 1];
};

interface Props {
    language: string;
    organisation: any;
    accessToken: string;
    content: any;
    setContent: any;
}

const Files = ({
    language,
    accessToken,
    organisation,
    content,
    setContent,
}: Props) => {
    const [totalRemaining, setTotalRemaining] = React.useState(100);
    const [remaining, setRemaining] = React.useState(0);
    const [isUploadingFile, setIsUploadingFile] = React.useState(false);
    const [isRemovingFile, setIsRemovingFile] = React.useState(false);
    const refFileInput = React.useRef();
    const refFile = React.useRef<any>(null);
    const refIsRemoved = React.useRef(false);

    React.useEffect(() => {
        return () => {
            refIsRemoved.current = true;
        };
    }, []);

    const browseFiles = () => {
        if (refFileInput.current) {
            refFileInput.current.click();
        }
    };

    const onFileChange = (e) => {
        e.preventDefault();
        if (
            refFileInput.current &&
            refFileInput.current.files &&
            refFileInput.current.files.length > 0
        ) {
            refFile.current = refFileInput.current.files[0];
            uploadFileAsync();
        }
        e.target.value = null;
    };

    const deleteFileAsync = async (filename: string) => {
        setIsRemovingFile(true);
        // 1. Get delete signature for S3 file
        try {
            await axios.delete(
                `${config.apiBaseUrl}/organisation/${organisation.id}/file`,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                    data: {
                        filename,
                    },
                }
            );
            setContent({
                ...content,
                files: [
                    ...content.files.filter(
                        (file: string) => file !== filename
                    ),
                ],
            });
        } catch (err) {
            console.log(err);
        }

        setIsRemovingFile(false);
    };

    const uploadFileAsync = async () => {
        setIsUploadingFile(true);
        // 1. Get upload signature for S3
        const formDataSignature = new FormData();
        let signedUrl;
        let hasError = false;
        formDataSignature.append('filename', refFile.current.name);
        try {
            signedUrl = (
                await axios.post(
                    `${config.apiBaseUrl}/organisation/${organisation.id}/file/signature`,
                    formDataSignature,
                    {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                    }
                )
            ).data;
        } catch (err) {
            console.log(err);
            hasError = true;
        }

        if (!hasError && signedUrl && signedUrl.length > 1) {
            try {
                await axios.put(signedUrl, refFile.current, {
                    headers: {
                        'Content-Type': refFile.current.type,
                    },
                    onUploadProgress: (e) => {
                        if (e.loaded && e.total) {
                            if (totalRemaining !== e.total) {
                                setTotalRemaining(e.total);
                            }
                            if (remaining !== e.loaded) {
                                setRemaining(e.loaded);
                            }
                        }
                    },
                });
            } catch (err) {
                console.log(err);
                hasError = true;
            }
        }

        if (!hasError) {
            setContent({
                ...content,
                files: [
                    ...content.files,
                    signedUrl
                        .split('?')[0]
                        .replace(
                            'tilkynna-public.s3.eu-west-1.amazonaws.com/',
                            'cdn.tilkynna.is/'
                        ),
                ],
            });
        }

        if (!refIsRemoved.current) {
            refFile.current = null;
        }

        setIsUploadingFile(false);
    };

    return (
        <Card variant='outlined' className={style.filesCard}>
            <CardContent>
                <h3>{localization[language].content.publicFiles}</h3>

                <p>{localization[language].content.filesInfo}</p>

                <input
                    ref={refFileInput}
                    type='file'
                    id='attachment'
                    name='attachment'
                    style={{ display: 'none' }}
                    onChange={onFileChange}
                    accept='*'
                />
                <div className={style.fileList}>
                    {content.files.map((file: string, index: number) => (
                        <Card
                            key={file}
                            variant='outlined'
                            className={style.file}
                        >
                            <CardHeader
                                // title="lol"
                                avatar={<Avatar>{index + 1}</Avatar>}
                                title={`${getFileName(file)}`}
                                subheader={
                                    <a
                                        href={file}
                                        target='_blank'
                                        rel='noreferrer'
                                    >
                                        {file}
                                    </a>
                                }
                                action={
                                    <IconButton
                                        color='secondary'
                                        aria-label='Delete'
                                        onClick={() => deleteFileAsync(file)}
                                        disabled={isRemovingFile}
                                    >
                                        <DeleteForever />
                                    </IconButton>
                                }
                            />
                        </Card>
                    ))}
                </div>
                <Button
                    variant='outlined'
                    onClick={browseFiles}
                    disabled={
                        isUploadingFile ||
                        isRemovingFile ||
                        organisation == null
                    }
                    color='primary'
                >
                    {isUploadingFile
                        ? `${
                              localization[language].form.labelUploading
                          } (${Math.floor(
                              (remaining / totalRemaining) * 100
                          )}%)`
                        : localization[language].content.addFile}
                </Button>
            </CardContent>
            {organisation === null && (
                <CardContent>
                    <CircularProgress />
                </CardContent>
            )}
        </Card>
    );
};

export default React.memo(Files);
