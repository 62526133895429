import React from 'react';
import axios from 'axios';
import { Button } from '@mui/material';
import config from '../../../config';
import localization from '../../../localization';
import { useAppSelector } from '../../../hooks';

const getFileName = (filename) => {
    if (filename == null) return '';
    if (filename.split('/').length === 0) return filename;
    return filename.split('/')[filename.split('/').length - 1];
};

interface Props {
    file: string;
    report: any;
    isComment: boolean;
    isReportLink: boolean;
    reportLink: any;
}

const DownloadBtn = ({
    file,
    report,
    isComment,
    isReportLink,
    reportLink,
}: Props) => {
    // Global state
    const accessToken = useAppSelector(
        (state) => state.global.auth.accessToken
    );
    const language = useAppSelector((state) => state.global.language);
    // Local state
    const [isRequesting, setIsRequesting] = React.useState(false);

    const downloadFile = (uri: string) => {
        const link = document.createElement('a');
        link.download = file.split('/')[file.split('/').length - 1];
        link.href = uri;
        link.target = '_blank';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const requestDownloadAsync = async () => {
        setIsRequesting(true);
        let response = null;
        try {
            if (isReportLink) {
                response = await axios.post(
                    `${config.apiBaseUrl}/organisation/${reportLink.organisationId}/${reportLink.reportId}/rl/${reportLink.id}/file`,
                    {
                        fileUrl: file,
                        isComment,
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                    }
                );
            } else {
                response = await axios.post(
                    `${config.apiBaseUrl}/organisation/${report.organisationId}/${report.id}/file`,
                    {
                        fileUrl: file,
                        isComment,
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                    }
                );
            }
            downloadFile(response.data.signedUrl);
        } catch (err) {
            console.log(err);
        }
        setIsRequesting(false);
    };

    return (
        <Button
            color='primary'
            title={localization[language].app.labelDownloadFile}
            disabled={isRequesting}
            onClick={requestDownloadAsync}
            style={{ textTransform: 'unset', textAlign: 'left', minWidth: 0 }}
        >
            {getFileName(file)}
        </Button>
    );
};

export default DownloadBtn;
