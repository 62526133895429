import React, {
    ChangeEvent,
    HTMLProps,
    KeyboardEvent,
    useCallback,
    useEffect,
    useLayoutEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
// import jsx from 'refractor/lang/jsx.js';
// import typescript from 'refractor/lang/typescript.js';
import { ExtensionPriority } from 'remirror';
import {
    BlockquoteExtension,
    BoldExtension,
    BulletListExtension,
    // CodeBlockExtension,
    CodeExtension,
    createMarkPositioner,
    HardBreakExtension,
    HeadingExtension,
    ItalicExtension,
    LinkExtension,
    ListItemExtension,
    MarkdownExtension,
    OrderedListExtension,
    ShortcutHandlerProps,
    StrikeExtension,
    TableExtension,
    TrailingNodeExtension,
    UnderlineExtension,
} from 'remirror/extensions';
import {
    BasicFormattingButtonGroup,
    CommandButton,
    DataTransferButtonGroup,
    FloatingToolbar,
    FloatingWrapper,
    HeadingLevelButtonGroup,
    HistoryButtonGroup,
    Remirror,
    ToggleBlockquoteButton,
    ToggleBulletListButton,
    ToggleOrderedListButton,
    Toolbar,
    // ToolbarItemUnion,
    useActive,
    useAttrs,
    useChainedCommands,
    useCurrentSelection,
    useExtensionEvent,
    useRemirror,
    useUpdateReason,
    VerticalDivider,
} from '@remirror/react';

import * as styles from './index.module.scss';

const useLinkShortcut = () => {
    const [linkShortcut, setLinkShortcut] = useState<
        ShortcutHandlerProps | undefined
    >();
    const [isEditing, setIsEditing] = useState(false);

    useExtensionEvent(
        LinkExtension,
        'onShortcut',
        useCallback(
            (props) => {
                if (!isEditing) {
                    setIsEditing(true);
                }

                return setLinkShortcut(props);
            },
            [isEditing]
        )
    );

    return { linkShortcut, isEditing, setIsEditing };
};

const useFloatingLinkState = () => {
    const chain = useChainedCommands();
    const { isEditing, linkShortcut, setIsEditing } = useLinkShortcut();
    const { to, empty } = useCurrentSelection();

    const url = (useAttrs().link()?.href as string) ?? '';
    const [href, setHref] = useState<string>(url);

    // A positioner which only shows for links.
    const linkPositioner = useMemo(
        () => createMarkPositioner({ type: 'link' }),
        []
    );

    const onRemove = useCallback(() => {
        return chain.removeLink().focus().run();
    }, [chain]);

    const updateReason = useUpdateReason();

    useLayoutEffect(() => {
        if (!isEditing) {
            return;
        }

        if (updateReason.doc || updateReason.selection) {
            setIsEditing(false);
        }
    }, [isEditing, setIsEditing, updateReason.doc, updateReason.selection]);

    useEffect(() => {
        setHref(url);
    }, [url]);

    const submitHref = useCallback(() => {
        setIsEditing(false);
        const range = linkShortcut ?? undefined;

        if (href === '') {
            chain.removeLink();
        } else {
            chain.updateLink({ href, auto: false }, range);
        }

        chain.focus(range?.to ?? to).run();
        setHref('');
    }, [setIsEditing, linkShortcut, chain, href, to]);

    const cancelHref = useCallback(() => {
        setIsEditing(false);
    }, [setIsEditing]);

    const clickEdit = useCallback(() => {
        if (empty) {
            chain.selectLink();
        }

        setIsEditing(true);
    }, [chain, empty, setIsEditing]);

    return useMemo(
        () => ({
            href,
            setHref,
            linkShortcut,
            linkPositioner,
            isEditing,
            clickEdit,
            onRemove,
            submitHref,
            cancelHref,
        }),
        [
            href,
            linkShortcut,
            linkPositioner,
            isEditing,
            clickEdit,
            onRemove,
            submitHref,
            cancelHref,
        ]
    );
};

const DelayAutoFocusInput = ({
    autoFocus,
    ...rest
}: HTMLProps<HTMLInputElement>) => {
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (!autoFocus) {
            return;
        }

        const frame = window.requestAnimationFrame(() => {
            inputRef.current?.focus();
        });

        return () => {
            window.cancelAnimationFrame(frame);
        };
    }, [autoFocus]);

    return <input ref={inputRef} {...rest} />;
};

const FloatingLinkToolbar = () => {
    const {
        isEditing,
        linkPositioner,
        clickEdit,
        onRemove,
        submitHref,
        href,
        setHref,
        cancelHref,
    } = useFloatingLinkState();
    const active = useActive();
    const activeLink = active.link();
    const { empty } = useCurrentSelection();
    const handleClickEdit = useCallback(() => {
        clickEdit();
    }, [clickEdit]);
    // const linkEditItems: ToolbarItemUnion[] = useMemo(
    //     () => [
    //         {
    //             type: ComponentItem.ToolbarGroup,
    //             label: 'Link',
    //             items: activeLink
    //                 ? [
    //                       {
    //                           type: ComponentItem.ToolbarButton,
    //                           onClick: () => clickEdit(),
    //                           icon: 'pencilLine',
    //                       },
    //                       {
    //                           type: ComponentItem.ToolbarButton,
    //                           onClick: onRemove,
    //                           icon: 'linkUnlink',
    //                       },
    //                   ]
    //                 : [
    //                       {
    //                           type: ComponentItem.ToolbarButton,
    //                           onClick: () => clickEdit(),
    //                           icon: 'link',
    //                       },
    //                   ],
    //         },
    //     ],
    //     [clickEdit, onRemove, activeLink]
    // );

    const linkEditButtons = activeLink ? (
        <>
            <CommandButton
                commandName='updateLink'
                onSelect={handleClickEdit}
                icon='pencilLine'
                enabled
            />
            <CommandButton
                commandName='removeLink'
                onSelect={onRemove}
                icon='linkUnlink'
                enabled
            />
        </>
    ) : (
        <CommandButton
            commandName='updateLink'
            onSelect={handleClickEdit}
            icon='link'
            enabled
        />
    );

    // const items: ToolbarItemUnion[] = useMemo(
    //     () => linkEditItems,
    //     [linkEditItems]
    // );

    // return (
    //     <>
    //         <FloatingToolbar
    //             items={items}
    //             positioner='selection'
    //             placement='bottom'
    //             enabled={!isEditing}
    //         />
    //         <FloatingToolbar
    //             items={linkEditItems}
    //             positioner={linkPositioner}
    //             placement='bottom'
    //             enabled={!isEditing && empty}
    //         />

    //         <FloatingWrapper
    //             positioner='selection'
    //             placement='bottom'
    //             enabled={isEditing}
    //             blurOnInactive
    //             // renderOutsideEditor
    //         >
    //             <DelayAutoFocusInput
    //                 // onBlur={() => cancelHref()}
    //                 style={{ zIndex: 20 }}
    //                 autoFocus
    //                 placeholder='Enter link...'
    //                 onChange={(event: ChangeEvent<HTMLInputElement>) =>
    //                     setHref(event.target.value)
    //                 }
    //                 onKeyDown={(event: KeyboardEvent<HTMLInputElement>) => {
    //                     if (event.code === 'Escape') {
    //                         cancelHref();
    //                     }
    //                 }}
    //                 value={href}
    //                 onKeyPress={(event: KeyboardEvent<HTMLInputElement>) => {
    //                     const { code } = event;

    //                     if (code === 'Enter') {
    //                         submitHref();
    //                     }

    //                     if (code === 'Escape') {
    //                         cancelHref();
    //                     }
    //                 }}
    //             />
    //         </FloatingWrapper>
    //     </>
    // );

    return (
        <>
            {!isEditing && <FloatingToolbar>{linkEditButtons}</FloatingToolbar>}
            {!isEditing && empty && (
                <FloatingToolbar positioner={linkPositioner}>
                    {linkEditButtons}
                </FloatingToolbar>
            )}

            <FloatingWrapper
                positioner='always'
                placement='bottom'
                enabled={isEditing}
                renderOutsideEditor
            >
                <DelayAutoFocusInput
                    style={{ zIndex: 20 }}
                    autoFocus
                    placeholder='Enter link...'
                    onChange={(event: ChangeEvent<HTMLInputElement>) =>
                        setHref(event.target.value)
                    }
                    value={href}
                    onKeyPress={(event: KeyboardEvent<HTMLInputElement>) => {
                        const { code } = event;

                        if (code === 'Enter') {
                            submitHref();
                        }

                        if (code === 'Escape') {
                            cancelHref();
                        }
                    }}
                />
            </FloatingWrapper>
        </>
    );
};

export const MarkdownEditor: React.FC<{
    initialContent: string;
    onChange: (updatedContent: string) => void;
}> = ({
    initialContent,
    onChange,
    autoFocus,
}: {
    initialContent: any;
    onChange: any;
    autoFocus?: boolean;
}) => {
    const visual = useRemirror({
        extensions,
        stringHandler: 'markdown',
    });

    return (
        // <ThemeProvider className={styles.toolbar}>
        <Remirror
            autoFocus={autoFocus}
            manager={visual.manager}
            autoRender='end'
            onChange={({ helpers, state }) => {
                return onChange(helpers.getMarkdown(state));
            }}
            initialContent={initialContent}
            classNames={[styles.remirrorEditor]}
        >
            {/* <Toolbar
                
                    items={toolbarItems}
                    refocusEditor
                    label='Top Toolbar'
                /> */}
            <EditorToolbar />
            <FloatingLinkToolbar />
        </Remirror>
        // </ThemeProvider>
    );
};

// const theme = createTheme({
//     components: {
//         // Name of the component
//         MuiButton: {
//             defaultProps: {
//                 // The default props to change
//                 disableElevation: true,
//                 disableRipple: true,
//                 disableFocusRipple: true,
//             },
//         },
//         MuiButtonBase: {
//             defaultProps: {
//                 // The props to change the default for.
//                 disableRipple: true, // No more ripple, on the whole application 💣!
//             },
//         },
//     },
// });

const extensions = () => [
    new LinkExtension({ autoLink: true }),
    new BoldExtension(),
    new StrikeExtension(),
    new ItalicExtension(),
    new HeadingExtension(),
    new BlockquoteExtension(),
    new BulletListExtension({ enableSpine: true }),
    new OrderedListExtension(),
    new ListItemExtension({
        priority: ExtensionPriority.High,
        enableCollapsible: true,
    }),
    new CodeExtension(),
    // new CodeBlockExtension({ supportedLanguages: [jsx, typescript] }),
    new TrailingNodeExtension(),
    new UnderlineExtension(),
    new TableExtension(),
    new MarkdownExtension({ copyAsMarkdown: false }),
    /**
     * `HardBreakExtension` allows us to create a newline inside paragraphs.
     * e.g. in a list item
     */
    new HardBreakExtension(),
];

const EditorToolbar = () => {
    return (
        <Toolbar
            paddingBottom={'.6rem'}
            // className={styles.toolbar}
            sx={{ '& .Mui-selected': { backgroundColor: '#0277bc' } }}
        >
            <BasicFormattingButtonGroup />
            <VerticalDivider />
            <HeadingLevelButtonGroup />
            <VerticalDivider />
            <ToggleBlockquoteButton />
            {/* <ToggleCodeBlockButton /> */}
            {/* <ToggleCodeButton /> */}
            <ToggleBulletListButton />
            <ToggleOrderedListButton />
            <VerticalDivider />
            <DataTransferButtonGroup />

            <VerticalDivider />
            <HistoryButtonGroup />
        </Toolbar>
    );
};

// const toolbarItems: ToolbarItemUnion[] = [
//     {
//         type: ComponentItem.ToolbarGroup,
//         label: 'Simple Formatting',
//         items: [
//             {
//                 type: ComponentItem.ToolbarCommandButton,
//                 commandName: 'toggleBold',
//                 display: 'icon',
//             },
//             {
//                 type: ComponentItem.ToolbarCommandButton,
//                 commandName: 'toggleItalic',
//                 display: 'icon',
//             },
//             {
//                 type: ComponentItem.ToolbarCommandButton,
//                 commandName: 'toggleUnderline',
//                 display: 'icon',
//             },
//             // {
//             //     type: ComponentItem.ToolbarButton,
//             //     onClick: () => alert('figure out how to execute logic'),
//             //     icon: 'link',
//             // },
//         ],
//         separator: 'end',
//     },
//     {
//         type: ComponentItem.ToolbarGroup,
//         label: 'Heading Formatting',
//         items: [
//             {
//                 type: ComponentItem.ToolbarCommandButton,
//                 commandName: 'toggleHeading',
//                 display: 'icon',
//                 attrs: { level: 1 },
//             },
//             {
//                 type: ComponentItem.ToolbarCommandButton,
//                 commandName: 'toggleHeading',
//                 display: 'icon',
//                 attrs: { level: 2 },
//             },
//             {
//                 type: ComponentItem.ToolbarCommandButton,
//                 commandName: 'toggleHeading',
//                 display: 'icon',
//                 attrs: { level: 3 },
//             },
//             // {
//             //     type: ComponentItem.ToolbarMenu,

//             //     items: [
//             //         {
//             //             type: ComponentItem.MenuGroup,
//             //             role: 'radio',
//             //             items: [
//             //                 {
//             //                     type: ComponentItem.MenuCommandPane,
//             //                     commandName: 'toggleHeading',
//             //                     attrs: { level: 3 },
//             //                 },
//             //                 {
//             //                     type: ComponentItem.MenuCommandPane,
//             //                     commandName: 'toggleHeading',
//             //                     attrs: { level: 4 },
//             //                 },
//             //                 {
//             //                     type: ComponentItem.MenuCommandPane,
//             //                     commandName: 'toggleHeading',
//             //                     attrs: { level: 5 },
//             //                 },
//             //                 {
//             //                     type: ComponentItem.MenuCommandPane,
//             //                     commandName: 'toggleHeading',
//             //                     attrs: { level: 6 },
//             //                 },
//             //             ],
//             //         },
//             //     ],
//             // },
//         ],
//         separator: 'end',
//     },
//     {
//         type: ComponentItem.ToolbarGroup,
//         label: 'Simple Formatting',
//         items: [
//             {
//                 type: ComponentItem.ToolbarCommandButton,
//                 commandName: 'toggleBlockquote',
//                 display: 'icon',
//             },
//             {
//                 type: ComponentItem.ToolbarCommandButton,
//                 commandName: 'toggleCodeBlock',
//                 display: 'icon',
//             },
//             {
//                 type: ComponentItem.ToolbarCommandButton,
//                 commandName: 'toggleBulletList',
//                 display: 'icon',
//             },
//             {
//                 type: ComponentItem.ToolbarCommandButton,
//                 commandName: 'toggleOrderedList',
//                 display: 'icon',
//             },
//         ],
//         separator: 'end',
//     },
//     {
//         type: ComponentItem.ToolbarGroup,
//         label: 'History',
//         items: [
//             {
//                 type: ComponentItem.ToolbarCommandButton,
//                 commandName: 'undo',
//                 display: 'icon',
//             },
//             {
//                 type: ComponentItem.ToolbarCommandButton,
//                 commandName: 'redo',
//                 display: 'icon',
//             },
//             {
//                 type: ComponentItem.ToolbarCommandButton,
//                 commandName: 'toggleColumns',
//                 display: 'icon',
//                 attrs: { count: 2 },
//             },
//         ],
//         separator: 'none',
//     },
// ];

export default MarkdownEditor;
