import React from 'react';
import axios from 'axios';
import classNames from 'classnames';
import {
    Button,
    Card,
    CardActions,
    CardContent,
    CircularProgress,
    IconButton,
    Tooltip,
} from '@mui/material';
import { CloudUpload, DeleteForever, Help } from '@mui/icons-material';
import localization from '../../../localization';
import config from '../../../config';
import SimpleDate from '../../SimpleDate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperclip } from '@fortawesome/pro-light-svg-icons';
import DownloadBtn from '../DownloadBtn';
import { useAppSelector } from '../../../hooks';
import { Content } from '../../../interfaces/Content';
import * as style from './index.module.scss';
import { IDefaultMessage } from '../../../interfaces/TilkynnaInterfaces';

const getFileName = (filename: string | null) => {
    if (filename == null) return '';
    if (filename.split('/').length === 0) return filename;
    return filename.split('/')[filename.split('/').length - 1];
};

interface Props {
    accessToken: string;
    organisation: any;
    report: any;
    setReport: any;
    reports: any;
    setReports: any;
    language: 'en' | 'is';
    isFetchingReport: boolean;
    messages: any;
    setMessages: any;
    canManageReports: boolean;
    content: Content;
}

const ReportFollowUp = ({
    accessToken,
    organisation,
    report,
    setReport,
    reports,
    setReports,
    language,
    isFetchingReport,
    messages,
    setMessages,
    canManageReports,
    content,
}: Props) => {
    const user = useAppSelector((state) => state.global.user);
    const [showWriteNewMessage, setShowWriteNewMessage] = React.useState(false);
    const [isSendingMessage, setIsSendingMessage] = React.useState(false);
    const [isDeletingMessage, setIsDeletingMessage] = React.useState(false);
    const [message, setMessage] = React.useState('');
    const [messageSent, setMessageSent] = React.useState(false);
    const [isErrorSendingMessage, setIsErrorSendingMessage] =
        React.useState(false);

    // State for file upload
    const [files, setFiles] = React.useState([]);
    const [totalRemaining, setTotalRemaining] = React.useState(100);
    const [remaining, setRemaining] = React.useState(0);
    const [isUploadingFile, setIsUploadingFile] = React.useState(false);
    const [isRemovingFile, setIsRemovingFile] = React.useState(false);
    const refFileInput = React.useRef<any>();
    const refFile = React.useRef<any>(null);

    const refMessageInput = React.useRef<any>();

    const refIsRemoved = React.useRef(false);

    React.useEffect(() => {
        return () => {
            refIsRemoved.current = true;
        };
    }, []);

    React.useEffect(() => {
        if (showWriteNewMessage && refMessageInput.current) {
            refMessageInput.current.focus();
        }
    }, [showWriteNewMessage]);

    const hasUnreadMessage = report != null && report.hasUnreadMessage;
    const reportId = report != null ? report.id : -1;
    const organisationId = organisation != null ? organisation.id : -1;

    React.useEffect(() => {
        if (hasUnreadMessage) {
            const markReadAsync = async () => {
                try {
                    const res = await axios.put(
                        `${config.apiBaseUrl}/organisation/${organisationId}/report/${reportId}/read/messages`,
                        null,
                        {
                            headers: {
                                Authorization: `Bearer ${accessToken}`,
                            },
                        }
                    );

                    setReport(res.data);

                    const updatedReports = [...reports];

                    for (let i = 0; i < updatedReports.length; i++) {
                        if (updatedReports[i].id === reportId) {
                            updatedReports[i] = {
                                ...updatedReports[i],
                                ...res.data,
                            };
                            break;
                        }
                    }

                    setReports(updatedReports);
                } catch (err) {
                    console.log(err);
                }
            };
            // console.log('Marking messages as read')
            markReadAsync();
        }
    }, [
        reportId,
        hasUnreadMessage,
        accessToken,
        organisationId,
        setReport,
        setReports,
        reports,
        report,
    ]);

    const onShowWriteNewMessage = () => {
        setMessageSent(false);
        setShowWriteNewMessage(true);
    };

    const isValidMessageForm = message.length > 0;

    const onFileChange = (e) => {
        e.preventDefault();
        if (
            refFileInput.current &&
            refFileInput.current.files &&
            refFileInput.current.files.length > 0
        ) {
            refFile.current = refFileInput.current.files[0];
            uploadFileAsync();
        }
        // setIsUploadingFile(true)

        e.target.value = null;
    };

    const uploadFileAsync = async () => {
        setIsUploadingFile(true);
        // 1. Get upload signature for S3
        const formDataSignature = new FormData();
        let signedUrl;
        let hasError = false;
        formDataSignature.append('filename', refFile.current.name);
        try {
            signedUrl = (
                await axios.post(
                    `${config.apiBaseUrl}/organisation/${organisation.id}/report/${report.id}/file/signature`,
                    formDataSignature,
                    {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                    }
                )
            ).data;
        } catch (err) {
            console.log(err);
            hasError = true;
        }

        if (!hasError && signedUrl && signedUrl.length > 1) {
            try {
                await axios.put(signedUrl, refFile.current, {
                    headers: {
                        'Content-Type': refFile.current.type,
                    },
                    onUploadProgress: (e) => {
                        if (e.loaded && e.total) {
                            if (totalRemaining !== e.total) {
                                setTotalRemaining(e.total);
                            }
                            if (remaining !== e.loaded) {
                                setRemaining(e.loaded);
                            }
                        }
                    },
                });
            } catch (err) {
                console.log(err);
                hasError = true;
            }
        }

        if (!hasError) {
            const fileNameUploaded = signedUrl
                .split('?')[0]
                .replace(
                    'https://tilkynna-private.s3.eu-west-1.amazonaws.com/',
                    ''
                );
            setFiles([...files, fileNameUploaded]);
        }

        if (!refIsRemoved.current) {
            refFile.current = null;
        }

        setIsUploadingFile(false);
    };

    const handleRemoveFileAsync = async (filename: string) => {
        setIsRemovingFile(true);
        // 1. Get delete signature for S3 file
        let hasError = false;
        try {
            await axios.delete(
                `${config.apiBaseUrl}/organisation/${organisation.id}/report/${report.id}/file`,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                    data: {
                        filename,
                    },
                }
            );
        } catch (err) {
            console.log(err);
            hasError = true;
        }

        if (!hasError) {
            setFiles([...files.filter((file) => file !== filename)]);
        }

        setIsRemovingFile(false);
    };

    const browseFiles = () => {
        if (refFileInput.current) {
            refFileInput.current.click();
        }
    };

    const sendMessageAsync = async () => {
        setIsSendingMessage(true);
        setIsErrorSendingMessage(false);

        let messageAdded = null;
        let hasError = false;
        try {
            const response = await axios.post(
                `${config.apiBaseUrl}/organisation/${organisation.id}/report/${report.id}/message`,
                {
                    message,
                    files,
                },
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            );
            messageAdded = response.data;
            setMessageSent(true);
            setMessage('');
            setFiles([]);
            setShowWriteNewMessage(false);
        } catch (err) {
            hasError = true;
        }
        setIsSendingMessage(false);

        if (hasError) {
            setIsErrorSendingMessage(true);
        }

        if (messageAdded != null) {
            setMessages([...messages, messageAdded]);
        }
    };

    const deleteMessageAsync = async (msg) => {
        setIsDeletingMessage(true);
        try {
            await axios.delete(
                `${config.apiBaseUrl}/organisation/${organisation.id}/report/${report.id}/message/${msg.id}`,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            );

            const updatedMessages = [...messages];

            for (let i = 0; i < updatedMessages.length; i++) {
                if (updatedMessages[i].id === msg.id) {
                    updatedMessages[i] = {
                        ...updatedMessages[i],
                        files: [],
                        status: 'deleted',
                    };
                }
            }

            setMessages(updatedMessages);
        } catch (err) {
            console.log(err);
        }
        setIsDeletingMessage(false);
    };

    const onChangeDefaultMessage = (e: any) => {
        const title = e.target.value;
        let wasFound = false;

        for (let i = 0; i < content.reportDefaultMessages.length; i++) {
            if (content.reportDefaultMessages[i].title.is === title) {
                setMessage(content.reportDefaultMessages[i].message.is);
                wasFound = true;
                break;
            } else if (content.reportDefaultMessages[i].title.en === title) {
                setMessage(content.reportDefaultMessages[i].message.en);
                wasFound = true;
                break;
            }
        }

        if (!wasFound) {
            setMessage('');
        }
    };

    return (
        <>
            <h2>{localization[language].app.chatPortalWithNotifier}</h2>
            <Card variant='outlined' className={style.followUpCard}>
                <CardContent>
                    <h2>{localization[language].app.labelMessages}</h2>
                    {isFetchingReport || report == null || messages == null ? (
                        <div>
                            <CircularProgress />
                        </div>
                    ) : (
                        <div className={style.messages}>
                            {messages.length === 0 && (
                                <p>{localization[language].app.noMessages}</p>
                            )}
                            {messages.map((msg: any) => (
                                <div
                                    key={msg.id}
                                    className={classNames(
                                        style.message,
                                        msg.isFromOrganisation &&
                                            style.isFromOrganisation
                                    )}
                                >
                                    <h4>
                                        <span>
                                            {msg.isFromOrganisation
                                                ? msg.fromName
                                                : localization[language].app
                                                      .labelNotifier}
                                        </span>
                                        <span className={style.time}>
                                            <SimpleDate
                                                dateString={msg.created}
                                            />
                                        </span>
                                    </h4>
                                    {msg.status === 'deleted' &&
                                        !msg.isFromOrganisation && (
                                            <p>
                                                <i>
                                                    {
                                                        localization[language]
                                                            .form
                                                            .notifierDeletedMessage
                                                    }
                                                </i>
                                            </p>
                                        )}

                                    {msg.status === 'deleted' &&
                                        msg.isFromOrganisation && (
                                            <p>
                                                <i>
                                                    {
                                                        localization[language]
                                                            .form.deletedMessage
                                                    }
                                                </i>
                                            </p>
                                        )}

                                    {msg.status !== 'deleted' && (
                                        <p>{msg.message}</p>
                                    )}

                                    {msg.files != null && msg.files.length > 0 && (
                                        <div className={style.attachments}>
                                            <h3>
                                                {
                                                    localization[language].form
                                                        .labelAttachment
                                                }
                                            </h3>
                                            <ul>
                                                {msg.files.map(
                                                    (file: string) => (
                                                        <li key={file}>
                                                            <span>
                                                                <FontAwesomeIcon
                                                                    icon={
                                                                        faPaperclip
                                                                    }
                                                                    fixedWidth
                                                                />
                                                            </span>
                                                            <DownloadBtn
                                                                file={file}
                                                                report={report}
                                                                isComment={true}
                                                            />
                                                        </li>
                                                    )
                                                )}
                                            </ul>
                                        </div>
                                    )}

                                    {msg.fromUserId === user?.id &&
                                        msg.status !== 'deleted' && (
                                            <div
                                                className={style.messageActions}
                                            >
                                                <Button
                                                    size='small'
                                                    variant='outlined'
                                                    onClick={() =>
                                                        deleteMessageAsync(msg)
                                                    }
                                                    disabled={isDeletingMessage}
                                                >
                                                    {
                                                        localization[language]
                                                            .app.labelDelete
                                                    }
                                                </Button>
                                            </div>
                                        )}
                                </div>
                            ))}
                        </div>
                    )}
                    {messageSent && (
                        <p>{localization[language].app.labelMessageSent}</p>
                    )}
                    {showWriteNewMessage && (
                        <div className={style.messageForm}>
                            <div className={style.message}>
                                <h4>
                                    <span>{user?.name}</span>
                                </h4>
                                <label htmlFor='message'>
                                    {localization[language].app.labelMessage}
                                </label>
                                <div className={style.defaultMessagesContainer}>
                                    <select onChange={onChangeDefaultMessage}>
                                        <option>
                                            {
                                                localization[language].app
                                                    .selectDefaultMessage
                                            }
                                        </option>
                                        {(
                                            content?.reportDefaultMessages || []
                                        ).map((defaultMsg: IDefaultMessage) => (
                                            <React.Fragment key={defaultMsg.id}>
                                                <optgroup
                                                    label={
                                                        defaultMsg.title[
                                                            language
                                                        ] ||
                                                        defaultMsg.title.is ||
                                                        defaultMsg.title.en
                                                    }
                                                >
                                                    {defaultMsg.title.is
                                                        ?.length > 0 && (
                                                        <option
                                                            value={
                                                                defaultMsg.title
                                                                    .is
                                                            }
                                                        >
                                                            {
                                                                defaultMsg.title
                                                                    .is
                                                            }{' '}
                                                            (IS)
                                                        </option>
                                                    )}

                                                    {defaultMsg.title.en
                                                        ?.length > 0 && (
                                                        <option
                                                            value={
                                                                defaultMsg.title
                                                                    .en
                                                            }
                                                        >
                                                            {
                                                                defaultMsg.title
                                                                    .en
                                                            }{' '}
                                                            (EN)
                                                        </option>
                                                    )}
                                                </optgroup>
                                            </React.Fragment>
                                        ))}
                                    </select>
                                    <Tooltip
                                        title={
                                            <p className='tooltipLarge'>
                                                {
                                                    localization[language].app
                                                        .tooltipDefaultMessages
                                                }
                                            </p>
                                        }
                                    >
                                        <Help
                                            style={{
                                                color: 'rgb(22, 127, 172)',
                                            }}
                                            fontSize='small'
                                        />
                                    </Tooltip>
                                </div>
                                <textarea
                                    ref={refMessageInput}
                                    id='message'
                                    name='message'
                                    value={message}
                                    onChange={(e) => setMessage(e.target.value)}
                                    disabled={isSendingMessage}
                                />

                                <div>
                                    <label
                                        className={style.label}
                                        htmlFor='attachment'
                                    >
                                        {
                                            localization[language].form
                                                .labelAttachment
                                        }
                                    </label>
                                    {/* <p className={style.tooltip}>
                                        {localization[language].form.labelAttachmentsTooltip}
                                    </p> */}
                                    <input
                                        ref={refFileInput}
                                        type='file'
                                        id='attachment'
                                        name='attachment'
                                        style={{ display: 'none' }}
                                        onChange={onFileChange}
                                        accept='*'
                                    />
                                    <div className={style.attachments}>
                                        {files.length > 0 && (
                                            <ul>
                                                {files.map((file) => (
                                                    <li key={file}>
                                                        <span>
                                                            <FontAwesomeIcon
                                                                icon={
                                                                    faPaperclip
                                                                }
                                                                fixedWidth
                                                            />
                                                        </span>
                                                        {getFileName(file)}

                                                        <IconButton
                                                            size='small'
                                                            disabled={
                                                                isRemovingFile
                                                            }
                                                            onClick={() =>
                                                                handleRemoveFileAsync(
                                                                    file
                                                                )
                                                            }
                                                        >
                                                            <DeleteForever fontSize='small' />
                                                        </IconButton>
                                                    </li>
                                                ))}
                                            </ul>
                                        )}

                                        <Button
                                            variant='outlined'
                                            size='small'
                                            startIcon={<CloudUpload />}
                                            onClick={browseFiles}
                                            disabled={
                                                isUploadingFile ||
                                                isSendingMessage
                                            }
                                        >
                                            {isUploadingFile
                                                ? `${
                                                      localization[language]
                                                          .form.labelUploading
                                                  } (${Math.floor(
                                                      (remaining /
                                                          totalRemaining) *
                                                          100
                                                  )}%)`
                                                : localization[language].form
                                                      .labelUploadFile}
                                        </Button>
                                    </div>
                                </div>

                                <p
                                    className={classNames(
                                        style.error,
                                        isErrorSendingMessage && style.hasError
                                    )}
                                >
                                    {
                                        localization[language].app
                                            .labelErrorSendingMessage
                                    }
                                </p>
                                <Button
                                    size='small'
                                    variant='contained'
                                    onClick={sendMessageAsync}
                                    disabled={
                                        isSendingMessage || !isValidMessageForm
                                    }
                                >
                                    {
                                        localization[language].app
                                            .labelSubmitMessage
                                    }
                                </Button>
                                <Button
                                    size='small'
                                    variant='outlined'
                                    onClick={() =>
                                        setShowWriteNewMessage(false)
                                    }
                                >
                                    {localization[language].form.labelCancel}
                                </Button>
                            </div>
                        </div>
                    )}
                </CardContent>
                {!showWriteNewMessage && !isFetchingReport && (
                    <CardActions>
                        <Button
                            onClick={onShowWriteNewMessage}
                            disabled={!canManageReports}
                        >
                            {
                                localization[language].app
                                    .labelWriteNewMessageToNotifier
                            }
                        </Button>
                    </CardActions>
                )}
            </Card>
        </>
    );
};

export default React.memo(ReportFollowUp);
