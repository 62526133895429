import React from 'react';
import axios from 'axios';
import classNames from 'classnames';
import {
    Button,
    Card,
    CardActions,
    CardContent,
    CircularProgress,
    IconButton,
} from '@mui/material';
import localization from '../../../localization';
import config from '../../../config';
import SimpleDate from '../../SimpleDate';
import { CloudUpload, DeleteForever } from '@mui/icons-material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperclip } from '@fortawesome/pro-light-svg-icons';
import DownloadBtn from '../DownloadBtn';
import ReportInvite from './ReportInvite';
import { useAppSelector } from '../../../hooks';
import * as style from './index.module.scss';

const getFileName = (filename: string | null) => {
    if (filename == null) return '';
    if (filename.split('/').length === 0) return filename;
    return filename.split('/')[filename.split('/').length - 1];
};

interface Props {
    accessToken: string;
    organisation: any;
    report: any;
    setReport: any;
    reports: any;
    setReports: any;
    language: string;
    isFetchingReport: boolean;
    comments: any;
    setComments: any;
    isReportLink: boolean;
    reportLink: any;
    links: any;
    setLinks: any;
    canManageReports: boolean;
}

const ReportComments = ({
    accessToken,
    organisation,
    report,
    setReport,
    reports,
    setReports,
    language,
    isFetchingReport,
    comments,
    setComments,
    isReportLink,
    reportLink,
    links,
    setLinks,
    canManageReports,
}: Props) => {
    const user = useAppSelector((state) => state.global.user);

    const [showWriteNewComment, setShowWriteNewComment] = React.useState(false);
    const [isSendingComment, setIsSendingComment] = React.useState(false);
    const [isDeletingComment, setIsDeletingComment] = React.useState(false);
    const [comment, setComment] = React.useState('');
    // const [commentSent, setCommentSent] = React.useState(false)
    const [isErrorSendingComment, setIsErrorSendingComment] =
        React.useState(false);

    // State for file upload
    const [files, setFiles] = React.useState<any>([]);
    const [totalRemaining, setTotalRemaining] = React.useState(100);
    const [remaining, setRemaining] = React.useState(0);
    const [isUploadingFile, setIsUploadingFile] = React.useState(false);
    const [isRemovingFile, setIsRemovingFile] = React.useState(false);
    const refFileInput = React.useRef<any>();
    const refFile = React.useRef<any>(null);
    const refIsRemoved = React.useRef(false);

    React.useEffect(() => {
        return () => (refIsRemoved.current = true);
    }, []);

    React.useEffect(() => {
        if (showWriteNewComment && refCommentInput.current) {
            refCommentInput.current.focus();
        }
    }, [showWriteNewComment]);

    const hasUnreadComment = report != null && report.hasUnreadComment;
    const reportId = report != null ? report.id : -1;
    const organisationId = organisation != null ? organisation.id : -1;

    React.useEffect(() => {
        if (!isReportLink && hasUnreadComment) {
            const markReadAsync = async () => {
                try {
                    const res = await axios.put(
                        `${config.apiBaseUrl}/organisation/${organisationId}/report/${reportId}/read/comments`,
                        null,
                        {
                            headers: {
                                Authorization: `Bearer ${accessToken}`,
                            },
                        }
                    );

                    setReport(res.data);

                    const updatedReports = [...reports];

                    for (let i = 0; i < updatedReports.length; i++) {
                        if (updatedReports[i].id === report.id) {
                            updatedReports[i] = {
                                ...updatedReports[i],
                                ...res.data,
                            };
                            break;
                        }
                    }

                    setReports(updatedReports);
                } catch (err) {
                    console.log(err);
                }
            };
            // console.log('Marking comments as read')
            markReadAsync();
        }
    }, [
        reportId,
        hasUnreadComment,
        accessToken,
        organisationId,
        setReport,
        setReports,
        reports,
        report,
        isReportLink,
    ]);

    const onFileChange = (e: any) => {
        e.preventDefault();
        if (
            refFileInput.current &&
            refFileInput.current.files &&
            refFileInput.current.files.length > 0
        ) {
            refFile.current = refFileInput.current.files[0];
            uploadFileAsync();
        }
        // setIsUploadingFile(true)

        e.target.value = null;
    };

    const uploadFileAsync = async () => {
        setIsUploadingFile(true);
        // 1. Get upload signature for S3
        const formDataSignature = new FormData();
        let signedUrl;
        let hasError = false;
        formDataSignature.append('filename', refFile.current.name);
        try {
            if (isReportLink) {
                signedUrl = (
                    await axios.post(
                        `${config.apiBaseUrl}/organisation/${organisation.id}/report/${report.id}/rl/${reportLink.id}/file/signature`,
                        formDataSignature
                    )
                ).data;
            } else {
                signedUrl = (
                    await axios.post(
                        `${config.apiBaseUrl}/organisation/${organisation.id}/report/${report.id}/file/signature`,
                        formDataSignature,
                        {
                            headers: {
                                Authorization: `Bearer ${accessToken}`,
                            },
                        }
                    )
                ).data;
            }
        } catch (err) {
            console.log(err);
            hasError = true;
        }

        if (!hasError && signedUrl && signedUrl.length > 1) {
            try {
                await axios.put(signedUrl, refFile.current, {
                    headers: {
                        'Content-Type': refFile.current.type,
                    },
                    onUploadProgress: (e) => {
                        if (e.loaded && e.total) {
                            if (totalRemaining !== e.total) {
                                setTotalRemaining(e.total);
                            }
                            if (remaining !== e.loaded) {
                                setRemaining(e.loaded);
                            }
                        }
                    },
                });
            } catch (err) {
                console.log(err);
                hasError = true;
            }
        }

        if (!hasError) {
            const fileNameUploaded = signedUrl
                .split('?')[0]
                .replace(
                    'https://tilkynna-private.s3.eu-west-1.amazonaws.com/',
                    ''
                );
            setFiles([...files, fileNameUploaded]);
        }

        if (!refIsRemoved.current) {
            refFile.current = null;
        }

        setIsUploadingFile(false);
    };

    // if (isReportLink)
    // console.log(reportLink)

    const handleRemoveFileAsync = async (filename) => {
        setIsRemovingFile(true);
        // 1. Get delete signature for S3 file
        let hasError = false;
        try {
            if (isReportLink) {
                await axios.delete(
                    `${config.apiBaseUrl}/organisation/${organisation.id}/report/${report.id}/rl/${reportLink.id}/file`,
                    {
                        data: {
                            filename,
                        },
                    }
                );
            } else {
                await axios.delete(
                    `${config.apiBaseUrl}/organisation/${organisation.id}/report/${report.id}/file`,
                    {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                        data: {
                            filename,
                        },
                    }
                );
            }
        } catch (err) {
            console.log(err);
            hasError = true;
        }

        if (!hasError) {
            setFiles([...files.filter((file) => file !== filename)]);
        }

        setIsRemovingFile(false);
    };

    const browseFiles = () => {
        if (refFileInput.current) {
            refFileInput.current.click();
        }
    };

    const refCommentInput = React.useRef();

    const onShowWriteNewComment = () => {
        // setCommentSent(false)
        setShowWriteNewComment(true);
    };

    const isValidCommentForm = comment.length > 2;

    const sendCommentAsync = async () => {
        setIsSendingComment(true);
        setIsErrorSendingComment(false);

        let commentAdded = null;
        let hasError = false;
        let response = null;
        try {
            if (isReportLink) {
                response = await axios.post(
                    `${config.apiBaseUrl}/organisation/${organisation.id}/report/${report.id}/rl/${reportLink.id}/comment`,
                    {
                        message: comment,
                        files,
                    }
                );
            } else {
                response = await axios.post(
                    `${config.apiBaseUrl}/organisation/${organisation.id}/report/${report.id}/comment`,
                    {
                        message: comment,
                        files,
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                    }
                );
            }
            commentAdded = response.data;
            // setCommentSent(true)
            setComment('');
            setFiles([]);
            setShowWriteNewComment(false);
        } catch (err) {
            console.log(err);
            hasError = true;
        }
        setIsSendingComment(false);

        if (hasError) {
            setIsErrorSendingComment(true);
        }

        if (commentAdded != null) {
            setComments([...comments, commentAdded]);
        }
    };

    const deleteCommentAsync = async (comment) => {
        setIsDeletingComment(true);

        try {
            if (isReportLink) {
                await axios.delete(
                    `${config.apiBaseUrl}/organisation/${organisation.id}/report/${report.id}/rl/${reportLink.id}/comment/${comment.id}`
                );
            } else {
                await axios.delete(
                    `${config.apiBaseUrl}/organisation/${organisation.id}/report/${report.id}/comment/${comment.id}`,
                    {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                    }
                );
            }

            const updatedComments = [...comments];

            for (let i = 0; i < updatedComments.length; i++) {
                if (updatedComments[i].id === comment.id) {
                    updatedComments[i] = {
                        ...updatedComments[i],
                        status: 'deleted',
                        files: [],
                    };
                }
            }

            setComments(updatedComments);
        } catch (err) {
            console.log(err);
        }
        setIsDeletingComment(false);
    };

    const renderNoCommentsMessage = () => {
        if (isReportLink && !reportLink.canReadComments) {
            return localization[language].app.labelNotAuthorizedToReadComments;
        }
        return localization[language].app.labelNoComment;
    };

    return (
        <>
            <Card variant='outlined' className={style.commentsPage}>
                <CardContent>
                    <h2>{localization[language].app.labelComments}</h2>
                    {isFetchingReport || comments == null ? (
                        <div>
                            <CircularProgress />
                        </div>
                    ) : (
                        <div className={style.messages}>
                            {comments.length === 0 && (
                                <p>{renderNoCommentsMessage()}</p>
                            )}
                            {comments.map((comment) => (
                                <div
                                    key={comment.id}
                                    className={classNames(
                                        style.message,
                                        !isReportLink &&
                                            comment.fromUserId === user.id &&
                                            style.isMe,
                                        isReportLink &&
                                            comment.fromUserId ===
                                                reportLink.id &&
                                            style.isMe
                                    )}
                                >
                                    <h4>
                                        <span>{comment.fromName}</span>
                                        <span>
                                            <SimpleDate
                                                dateString={comment.created}
                                            />
                                        </span>
                                    </h4>
                                    {(comment.status == null ||
                                        comment.status !== 'deleted') && (
                                        <p>{comment.message}</p>
                                    )}
                                    {comment.status != null &&
                                        comment.status === 'deleted' && (
                                            <p>
                                                <i>
                                                    {
                                                        localization[language]
                                                            .form.deletedComment
                                                    }
                                                </i>
                                            </p>
                                        )}
                                    {comment.files != null &&
                                        comment.files.length > 0 && (
                                            <div className={style.attachments}>
                                                <h3>
                                                    {
                                                        localization[language]
                                                            .form
                                                            .labelAttachment
                                                    }
                                                </h3>
                                                <ul>
                                                    {comment.files.map(
                                                        (file: string) => (
                                                            <li key={file}>
                                                                <span>
                                                                    <FontAwesomeIcon
                                                                        icon={
                                                                            faPaperclip
                                                                        }
                                                                        fixedWidth
                                                                    />
                                                                </span>
                                                                <DownloadBtn
                                                                    file={file}
                                                                    report={
                                                                        report
                                                                    }
                                                                    isComment={
                                                                        true
                                                                    }
                                                                    isReportLink={
                                                                        isReportLink
                                                                    }
                                                                    reportLink={
                                                                        reportLink
                                                                    }
                                                                />
                                                            </li>
                                                        )
                                                    )}
                                                </ul>
                                            </div>
                                        )}
                                    {((!isReportLink &&
                                        comment.fromUserId === user.id) ||
                                        (isReportLink &&
                                            comment.fromUserId ===
                                                reportLink.id)) &&
                                        comment.status !== 'deleted' && (
                                            <div
                                                className={style.messageActions}
                                            >
                                                <Button
                                                    size='small'
                                                    variant='outlined'
                                                    onClick={() =>
                                                        deleteCommentAsync(
                                                            comment
                                                        )
                                                    }
                                                    disabled={isDeletingComment}
                                                >
                                                    {
                                                        localization[language]
                                                            .app.labelDelete
                                                    }
                                                </Button>
                                            </div>
                                        )}
                                </div>
                            ))}
                        </div>
                    )}
                    {showWriteNewComment && (
                        <div className={style.messageForm}>
                            <div className={style.message}>
                                <h4>
                                    <span>
                                        {isReportLink
                                            ? reportLink.name
                                            : user?.name}
                                    </span>
                                </h4>
                                <label htmlFor='comment'>
                                    {localization[language].app.labelComment}
                                </label>
                                <textarea
                                    ref={refCommentInput}
                                    id='comment'
                                    name='comment'
                                    value={comment}
                                    onChange={(e) => setComment(e.target.value)}
                                    disabled={isSendingComment}
                                />

                                <div>
                                    <label
                                        className={style.label}
                                        htmlFor='attachment'
                                    >
                                        {
                                            localization[language].form
                                                .labelAttachment
                                        }
                                    </label>
                                    {/* <p className={style.tooltip}>
                                        {localization[language].form.labelAttachmentsTooltip}
                                    </p> */}
                                    <input
                                        ref={refFileInput}
                                        type='file'
                                        id='attachment'
                                        name='attachment'
                                        style={{ display: 'none' }}
                                        onChange={onFileChange}
                                        accept='*'
                                    />
                                    <div className={style.attachments}>
                                        {files.length > 0 && (
                                            <ul>
                                                {files.map((file: string) => (
                                                    <li key={file}>
                                                        <span>
                                                            <FontAwesomeIcon
                                                                icon={
                                                                    faPaperclip
                                                                }
                                                                fixedWidth
                                                            />
                                                        </span>
                                                        {getFileName(file)}

                                                        <IconButton
                                                            size='small'
                                                            disabled={
                                                                isRemovingFile ||
                                                                isSendingComment
                                                            }
                                                            onClick={() =>
                                                                handleRemoveFileAsync(
                                                                    file
                                                                )
                                                            }
                                                        >
                                                            <DeleteForever fontSize='small' />
                                                        </IconButton>
                                                    </li>
                                                ))}
                                            </ul>
                                        )}

                                        <Button
                                            variant='outlined'
                                            size='small'
                                            startIcon={<CloudUpload />}
                                            onClick={browseFiles}
                                            disabled={
                                                isUploadingFile ||
                                                isSendingComment
                                            }
                                        >
                                            {isUploadingFile
                                                ? `${
                                                      localization[language]
                                                          .form.labelUploading
                                                  } (${Math.floor(
                                                      (remaining /
                                                          totalRemaining) *
                                                          100
                                                  )}%)`
                                                : localization[language].form
                                                      .labelUploadFile}
                                        </Button>
                                    </div>
                                </div>

                                <p
                                    className={classNames(
                                        style.error,
                                        isErrorSendingComment && style.hasError
                                    )}
                                >
                                    {
                                        localization[language].app
                                            .labelErrorSendingComment
                                    }
                                </p>
                                <div className={style.commentActions}>
                                    <Button
                                        size='small'
                                        variant='contained'
                                        onClick={sendCommentAsync}
                                        disabled={
                                            isSendingComment ||
                                            !isValidCommentForm ||
                                            isUploadingFile
                                        }
                                    >
                                        {
                                            localization[language].app
                                                .labelSubmitComment
                                        }
                                    </Button>
                                    <Button
                                        size='small'
                                        variant='outlined'
                                        onClick={() =>
                                            setShowWriteNewComment(false)
                                        }
                                    >
                                        {
                                            localization[language].form
                                                .labelCancel
                                        }
                                    </Button>
                                </div>
                            </div>
                        </div>
                    )}
                </CardContent>
                {!showWriteNewComment && !isFetchingReport && (
                    <CardActions>
                        <Button
                            disabled={!canManageReports && !isReportLink}
                            onClick={onShowWriteNewComment}
                        >
                            {localization[language].app.labelWriteNewComment}
                        </Button>
                    </CardActions>
                )}
            </Card>
            {!isReportLink && canManageReports && (
                <ReportInvite
                    language={language}
                    report={report}
                    organisationId={organisationId}
                    accessToken={accessToken}
                    title={localization[language].app.inviteUserToParticipate}
                    info={localization[language].app.inviteInfoComment}
                    setLinks={setLinks}
                    links={links}
                />
            )}
        </>
    );
};

export default React.memo(ReportComments);
