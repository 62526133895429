import React from 'react';
import axios from 'axios';
import { Router, useMatch } from '@reach/router';

import {
    Button,
    Card,
    CardContent,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@mui/material';
import localization from '../../../localization';
import config from '../../../config';
import ReportComments from '../Reports/ReportComments';
import ReportDetails from './ReportDetails';
import { useAppSelector } from '../../../hooks';
import * as style from '../Reports/index.module.scss';

interface Props {
    organisation: any;
    setOrganisation: any;
    reportLink: any;
    setReportLink: any;
    isMessageDialogOpen: boolean;
    setIsMessageDialogOpen: any;
    setIsReportLinkInactive: any;
    isReportLinkInactive: boolean;
}

const ReportLink = ({
    organisation,
    setOrganisation,
    reportLink,
    setReportLink,
    isMessageDialogOpen,
    setIsMessageDialogOpen,
    setIsReportLinkInactive,
    isReportLinkInactive,
}: Props) => {
    const language = useAppSelector((state) => state.global.language);
    const [report, setReport] = React.useState<any>(null);
    const [assignedUsers, setAssignedUsers] = React.useState(null);
    const [comments, setComments] = React.useState<any>([]);
    const [hasDisplayedMessage, setHasDisplayedMessage] = React.useState(false);
    const [isFetching, setIsFetching] = React.useState(true);
    const [isLinkBroken, setIsLinkBroken] = React.useState(false);
    const [reportLabels, setReportLabels] = React.useState<any>([]);
    const [assignedLabels, setAssignedLabels] = React.useState<any>([]);

    const matchReportLink = useMatch(
        `/app/:organisationId/link/:linkId/id/:reportId/*`
    );

    let reportLinkId: string | null = null;
    let organisationId: string | null = null;
    let reportId: string | null = null;

    if (matchReportLink != null) {
        reportLinkId = matchReportLink.linkId;
        organisationId = matchReportLink.organisationId;
        reportId = matchReportLink.reportId;
    }

    React.useEffect(() => {
        if (
            !hasDisplayedMessage &&
            reportLink != null &&
            reportLink.message != null &&
            reportLink.message.length > 0
        ) {
            setHasDisplayedMessage(true);
            setIsMessageDialogOpen(true);
        }
    }, [reportLink, hasDisplayedMessage]);

    React.useEffect(() => {
        if (reportLinkId != null && organisation == null) {
            const getOrgByLinkAsync = async () => {
                console.log('### Fetching organisation by link.');
                let error: any = null;
                try {
                    const response = await axios.get(
                        `${config.apiBaseUrl}/organisation/${organisationId}/reportlink/${reportId}/${reportLinkId}`
                    );
                    setOrganisation(response.data);
                    setReportLink(response.data.reportLink);
                    setReport(response.data.report);
                    setAssignedUsers(response.data.assignedUsers);
                    if (response.data.comments) {
                        setComments(response.data.comments);
                    }
                    if (response.data.reportLabels) {
                        setReportLabels(response.data.reportLabels);
                    }

                    if (response.data.report.assignedLabels) {
                        setAssignedLabels(response.data.report.assignedLabels);
                    }
                } catch (err: any) {
                    if (err && err.response && err.response.status) {
                        error = err.response.status;

                        if (err.response.status === 401) {
                            setIsReportLinkInactive(true);
                        }
                    } else {
                        console.error(err);
                        error = 1;
                    }
                    setIsLinkBroken(true);
                    // setOrganisationError(error)
                }
                setIsFetching(false);
            };
            setIsFetching(true);
            getOrgByLinkAsync();
        }
    }, [reportLinkId, organisationId, reportId, organisation]);

    if (isReportLinkInactive) {
        return <h2>{localization[language].app.labelReportLinkNotActive}</h2>;
    }

    if (isLinkBroken) {
        return (
            <div className={style.reportPage}>
                <Card variant='outlined'>
                    <CardContent>
                        <h2>{localization[language].app.genericError}</h2>
                    </CardContent>
                </Card>
            </div>
        );
    }

    return (
        <div className={style.reportsPage}>
            <Dialog
                open={isMessageDialogOpen}
                onClose={() => setIsMessageDialogOpen(false)}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
            >
                <DialogTitle id='alert-dialog-title'>{`${
                    localization[language].app.labelMessageFrom
                } ${
                    reportLink != null ? reportLink.fromName : ''
                }`}</DialogTitle>
                <DialogContent>
                    <DialogContentText id='alert-dialog-description'>
                        {reportLink != null ? reportLink.message : ''}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setIsMessageDialogOpen(false)}
                        autoFocus
                    >
                        {localization[language].app.labelContinue}
                    </Button>
                </DialogActions>
            </Dialog>
            <div className={style.reportPage}>
                {report != null && (
                    <div className={style.header}>
                        <ol>
                            <li className={style.active}>
                                {localization[language].app.labelReport} #
                                {report != null ? report.number : ''}
                            </li>
                        </ol>
                        {/* <h2>Tilkynningar / {localization[language].app.labelReport} #{report != null ? report.number : ''}</h2> */}
                    </div>
                )}
                <Router>
                    <ReportDetails
                        path='/:linkId/id/:reportId/'
                        report={report}
                        language={language}
                        organisation={organisation}
                        assignedUsers={assignedUsers}
                        isFetching={isFetching}
                        reportLink={reportLink}
                        reportLabels={reportLabels}
                        assignedLabelsIds={assignedLabels}
                    />
                    <ReportComments
                        path='/:linkId/id/:reportId/comments'
                        language={language}
                        organisation={organisation}
                        comments={comments}
                        setComments={setComments}
                        report={report}
                        setReport={setReport}
                        isReportLink={true}
                        reportLink={reportLink}
                        isFetchingReport={isFetching}
                    />
                </Router>
            </div>
        </div>
    );
};

export default React.memo(ReportLink);
