import React from 'react';
import axios from 'axios';
import {
    Button,
    DialogActions,
    DialogContent,
    DialogContentText,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperclip } from '@fortawesome/pro-light-svg-icons';
import config from '../../../config';
import localization from '../../../localization';
import * as style from './index.module.scss';

const getFileName = (filename: string | null) => {
    if (filename == null) return '';
    if (filename.split('/').length === 0) return filename;
    return filename.split('/')[filename.split('/').length - 1];
};

interface Props {
    report: any;
    reports: any;
    setReports: any;
    setReport: any;
    organisationId: string;
    setIsErasePersonalDataDialogOpen: any;
    language: string;
    accessToken: string;
}

const DeletePersonalData = ({
    report,
    reports,
    setReports,
    setReport,
    organisationId,
    setIsErasePersonalDataDialogOpen,
    language,
    accessToken,
}: Props) => {
    const [step, setStep] = React.useState(0);
    const [isDeleting, setIsDeleting] = React.useState(false);

    const [fields, setFields] = React.useState(
        report != null && report.fields != null ? [...report.fields] : []
    );

    const onFieldTextChange = (field: any, text: string) => {
        let updatedFields = [...fields];

        for (let i = 0; i < updatedFields.length; i++) {
            if (updatedFields[i].id === field.id) {
                updatedFields[i] = {
                    ...updatedFields[i],
                    value: text,
                };
                // updatedFields[i].value = text;
                break;
            }
        }

        setFields(updatedFields);
    };

    const deletePersonalDataAsync = async () => {
        setIsDeleting(true);

        try {
            const res = await axios.put(
                `${config.apiBaseUrl}/organisation/${organisationId}/reports/id/${report.id}/personaldata`,
                {
                    fields,
                },
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            );

            setReport(res.data);

            const updatedReports = [...reports];

            for (let i = 0; i < updatedReports.length; i++) {
                if (updatedReports[i].id === report.id) {
                    updatedReports[i] = {
                        ...updatedReports[i],
                        ...res.data,
                    };
                    break;
                }
            }

            setReports(updatedReports);
            setStep(step + 1);
        } catch (err) {
            console.log('Error deleting personal data: ', err);
        }

        setIsDeleting(false);
    };

    if (step === 0) {
        return (
            <>
                <DialogContent>
                    <DialogContentText>
                        {localization[language].app.eraseAllPersonalInformation}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setIsErasePersonalDataDialogOpen(false)}
                    >
                        {localization[language].app.labelCancel}
                    </Button>
                    <Button
                        onClick={() => setStep(step + 1)}
                        variant='contained'
                    >
                        {localization[language].app.labelContinue}
                    </Button>
                </DialogActions>
            </>
        );
    }

    if (step === 1) {
        return (
            <>
                <DialogContent>
                    <>
                        <DialogContentText>
                            Hér getur þú eytt öllum persónugreinanlegum
                            upplýsingum í tilkynningunni.
                        </DialogContentText>
                        {fields
                            .filter(
                                (f) =>
                                    (f.fieldType === 'text' ||
                                        f.fieldType === 'textarea') &&
                                    f.value != null &&
                                    f.value.length > 0
                            )
                            .map((field) => (
                                <div className={style.item} key={field.id}>
                                    <h3>{field.title[language]}</h3>
                                    {field.fieldType === 'text' && (
                                        <input
                                            type='text'
                                            value={field.value}
                                            id={field.id}
                                            name={field.id}
                                            onChange={(e) =>
                                                onFieldTextChange(
                                                    field,
                                                    e.target.value
                                                )
                                            }
                                        />
                                    )}
                                    {field.fieldType === 'textarea' && (
                                        <textarea
                                            value={field.value}
                                            id={field.id}
                                            name={field.id}
                                            onChange={(e) =>
                                                onFieldTextChange(
                                                    field,
                                                    e.target.value
                                                )
                                            }
                                        />
                                    )}

                                    {/* {(field.fieldType === 'radio' || field.fieldType === 'dropdown') && field.choices != null && field.choices.length > 0 && (
                                    <>
                                        <p>{field.choices.filter(choice => choice.isSelected === true)[0] != null ? field.choices.filter(choice => choice.isSelected === true)[0].title[language] : null}</p>
                                    </>
                                )} */}
                                    {/* {field.fieldType === 'checkboxes' && field.choices != null && field.choices.length > 0 && (
                                    <ul>
                                        {field.choices.filter(choice => choice.isSelected === true).map(choice => (
                                            <li key={choice.id}>
                                                {choice.title[language]}
                                            </li>
                                        ))}
                                    </ul>
                                )} */}
                                </div>
                            ))}
                    </>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setIsErasePersonalDataDialogOpen(false)}
                    >
                        {localization[language].app.labelCancel}
                    </Button>
                    <Button onClick={() => setStep(step - 1)}>
                        {localization[language].form.labelBack}
                    </Button>
                    <Button onClick={() => setStep(step + 1)}>
                        {localization[language].app.labelContinue}
                    </Button>
                </DialogActions>
            </>
        );
    }

    if (step === 2) {
        return (
            <>
                <DialogContent>
                    <>
                        <DialogContentText>
                            Þessar skrár verða eyddar
                        </DialogContentText>
                        {fields
                            .filter((f) => f.fieldType === 'file')
                            .map((field) => (
                                <div className={style.item} key={field.id}>
                                    <h3>{field.title[language]}</h3>

                                    <>
                                        {(field.files == null ||
                                            field.files.length === 0) && (
                                            <p>
                                                {
                                                    localization[language].form
                                                        .labelNoAttachment
                                                }
                                            </p>
                                        )}

                                        {field.files != null &&
                                            field.files.length > 0 && (
                                                <ul>
                                                    {field.files.map(
                                                        (file: string) => (
                                                            <li key={file}>
                                                                <span>
                                                                    <FontAwesomeIcon
                                                                        icon={
                                                                            faPaperclip
                                                                        }
                                                                        fixedWidth
                                                                    />{' '}
                                                                    {getFileName(
                                                                        file
                                                                    )}
                                                                </span>
                                                            </li>
                                                        )
                                                    )}
                                                </ul>
                                            )}
                                    </>

                                    {/* {(field.fieldType === 'radio' || field.fieldType === 'dropdown') && field.choices != null && field.choices.length > 0 && (
                                    <>
                                        <p>{field.choices.filter(choice => choice.isSelected === true)[0] != null ? field.choices.filter(choice => choice.isSelected === true)[0].title[language] : null}</p>
                                    </>
                                )} */}
                                    {/* {field.fieldType === 'checkboxes' && field.choices != null && field.choices.length > 0 && (
                                    <ul>
                                        {field.choices.filter(choice => choice.isSelected === true).map(choice => (
                                            <li key={choice.id}>
                                                {choice.title[language]}
                                            </li>
                                        ))}
                                    </ul>
                                )} */}
                                </div>
                            ))}
                    </>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setIsErasePersonalDataDialogOpen(false)}
                    >
                        {localization[language].app.labelCancel}
                    </Button>
                    <Button onClick={() => setStep(step - 1)}>
                        {localization[language].form.labelBack}
                    </Button>
                    <Button onClick={() => setStep(step + 1)}>
                        {localization[language].app.labelContinue}
                    </Button>
                </DialogActions>
            </>
        );
    }

    if (step === 3) {
        return (
            <>
                <DialogContent>
                    <DialogContentText
                        dangerouslySetInnerHTML={{
                            __html: localization[language].app
                                .confirmErasePersonalData,
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setIsErasePersonalDataDialogOpen(false)}
                        disabled={isDeleting}
                    >
                        {localization[language].app.labelCancel}
                    </Button>
                    <Button
                        onClick={() => setStep(step - 1)}
                        disabled={isDeleting}
                    >
                        {localization[language].form.labelBack}
                    </Button>
                    <Button
                        onClick={deletePersonalDataAsync}
                        disabled={isDeleting}
                    >
                        {localization[language].form.labelConfirm}
                    </Button>
                </DialogActions>
            </>
        );
    }

    return (
        <>
            <DialogContent>
                <DialogContentText
                    dangerouslySetInnerHTML={{
                        __html: localization[language].app
                            .personalDataHasBeenRemoved,
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setIsErasePersonalDataDialogOpen(false)}>
                    {localization[language].app.labelClose}
                </Button>
            </DialogActions>
        </>
    );
};

export default React.memo(DeletePersonalData);
