import React from 'react';
import { navigate } from 'gatsby';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Chip,
    IconButton,
    Tab,
    Tabs,
    Card,
    CardContent,
    CircularProgress,
    TableSortLabel,
    Grid,
    Tooltip,
    Stack,
} from '@mui/material';
import { useMatch } from '@reach/router';
import {
    Archive,
    LibraryBooks,
    NotificationsActive,
    PostAdd,
    Refresh,
    Search,
} from '@mui/icons-material';
import localization from '../../../localization';
import SimpleDate from '../../SimpleDate';
import * as style from './index.module.scss';

const tabMap = {
    attention: 1,
    closed: 2,
    search: 3,
};

const ReportRow = ({ report, setReport, language, users, content }) => {
    const [assignedUsers, setAssignedUsers] = React.useState([]);
    const [assignedLabels, setAssignedLabels] = React.useState([]);

    const openReport = () => {
        setReport({
            ...report,
        });
        navigate(`/app/${report.organisationId}/reports/id/${report.id}`);
    };

    React.useEffect(() => {
        if (users != null) {
            let tempAssignedUsers = [];
            let assignedDict = {};

            if (report.assignedUserIds != null) {
                for (let i = 0; i < report.assignedUserIds.length; i++) {
                    assignedDict[report.assignedUserIds[i]] = true;
                }
            }

            for (let i = 0; i < users.length; i++) {
                if (assignedDict[users[i].id] === true) {
                    tempAssignedUsers.push(users[i]);
                }
            }

            setAssignedUsers(tempAssignedUsers);
        }
    }, [report.assignedUserIds, users]);

    React.useEffect(() => {
        if (users != null && content.reportLabels != null) {
            let tempAssignedLabels = [];
            let assignedDict = {};

            if (report.assignedLabels != null) {
                for (let i = 0; i < report.assignedLabels.length; i++) {
                    assignedDict[report.assignedLabels[i]] = true;
                }
            }

            for (let i = 0; i < content.reportLabels.length; i++) {
                if (assignedDict[content.reportLabels[i].id] === true) {
                    tempAssignedLabels.push(content.reportLabels[i]);
                }
            }

            setAssignedLabels(tempAssignedLabels);
        }
    }, [report.assignedLabels, content.reportLabels]);

    const renderReportName = (name) => {
        if (name != null && name.length > 15) {
            return name.substring(0, 15) + '...';
        }
        if (name == null) {
            return (
                <i style={{ opacity: 0.5 }}>
                    {localization[language].app.noCaseName}
                </i>
            );
        }
        return name;
    };

    return (
        <TableRow
            onClick={() => openReport(report)}
            className={style.reportRow}
        >
            <TableCell align='left' size='small'>
                {report.number}
            </TableCell>
            <TableCell align='left'>{renderReportName(report.name)}</TableCell>

            <TableCell align='center'>
                {report.status !== 'new' && (
                    <div>
                        <Chip
                            style={{ margin: '4px 0' }}
                            variant='outlined'
                            size='small'
                            label={
                                localization[language].reportStatus[
                                    report.status
                                ]
                            }
                        />
                    </div>
                )}
                {report.status === 'new' && (
                    <div>
                        <Chip
                            style={{ margin: '2px 0' }}
                            size='small'
                            color='secondary'
                            label={localization[language].app.newReport}
                        />
                    </div>
                )}
                {report.hasUnreadMessage && (
                    <div>
                        <Chip
                            style={{ margin: '2px 0' }}
                            size='small'
                            color='secondary'
                            label={localization[language].app.labelNewMessage}
                        />
                    </div>
                )}
                {report.hasUnreadComment && (
                    <div>
                        <Chip
                            style={{
                                margin: '2px 0',
                                backgroundColor: 'rgb(255, 159, 26)',
                                color: '#ffffff',
                            }}
                            size='small'
                            // color="secondary"
                            label={localization[language].app.labelNewComment}
                        />
                    </div>
                )}
            </TableCell>
            <TableCell align='center'>
                <Stack direction='column' spacing={0.2}>
                    {assignedLabels.map((label) => (
                        <div key={label.id}>
                            <Chip
                                size='small'
                                className={style.assignedLabelList}
                                title={label.title.is || label.title.en}
                                label={label.title.is || label.title.en}
                                style={{
                                    backgroundColor: label.color,
                                    color: '#ffffff',
                                    margin: '2px 0',
                                }}
                            />
                        </div>
                    ))}
                </Stack>
            </TableCell>
            <TableCell align='left'>
                <Stack direction='column' spacing={0.2}>
                    {assignedUsers.map((user) => (
                        <div key={user.id}>
                            <Chip
                                variant='outlined'
                                size='small'
                                className={style.assignedUserList}
                                label={user.name}
                            />
                        </div>
                    ))}
                </Stack>
                {(assignedUsers == null || assignedUsers.length === 0) && (
                    <i style={{ opacity: 0.5 }}>
                        {localization[language].app.none}
                    </i>
                )}
            </TableCell>
            <TableCell align='left'>
                <SimpleDate dateString={report.created} />
            </TableCell>
            <TableCell align='left'>
                <SimpleDate dateString={report.updated} />
            </TableCell>
        </TableRow>
    );
};

let lastFetchedClosedReports = 0;

const weightByStatus = (report: any) => {
    if (report.hasUnreadMessage || report.hasUnreadComment) {
        return 7;
    }
    if (report.status === 'new') return 6;

    if (report.status === 'open_inprogress') {
        return 5;
    }
    if (report.status.startsWith('open')) return 4;
    if (report.status.startsWith('pending')) return 3;
    if (report.status === 'closed_resolved') {
        return 2;
    }
    if (report.status === 'closed_insufficient_info') {
        return 1;
    }
    if (report.status.startsWith('closed')) return 0;

    return 0;
};

const weightByLabel = (report: any) => {
    if (report.assignedLabels != null && report.assignedLabels.length > 0) {
        return report.assignedLabels.length;
    }

    return 0;
};

const hasItem = (items: any, item: any) => {
    if (items == null) {
        return false;
    }
    for (let i = 0; i < items.length; i++) {
        if (items[i] === item) {
            return true;
        }
    }

    return false;
};

interface Props {
    organisation: any;
    content: any;
    reports: any;
    setReport: any;
    language: string;
    closedReports: any;
    location: any;
    users: any;
    setRefreshClosedReports: any;
    setRefreshReports: any;
    refreshReports: any;
    notificationReports: any;
    organisationId: string;
}

const ReportList = ({
    organisation,
    content,
    reports,
    setReport,
    language,
    closedReports,
    location,
    users,
    setRefreshClosedReports,
    setRefreshReports,
    refreshReports,
    notificationReports,
    organisationId,
}: Props) => {
    const matchActiveTab = useMatch(
        `/app/${organisationId}/reports/:activeTab`
    );

    const [sortedReports, setSortedReports] = React.useState([]);
    const [sortBy, setSortBy] = React.useState('modified');
    const [isDesc, setIsDesc] = React.useState(false);

    const [filterNumber, setFilterNumber] = React.useState('');
    const [filterName, setFilterName] = React.useState('');
    const [filterStatus, setFilterStatus] = React.useState('none');
    const [filterLabel, setFilterLabel] = React.useState('none');
    const [filterUser, setFilterUser] = React.useState('none');
    const [filterReportArea, setFilterReportArea] = React.useState('none');

    const [activeTab, setActiveTab] = React.useState<any>(() => {
        if (
            matchActiveTab != null &&
            tabMap[matchActiveTab.activeTab] != null
        ) {
            return tabMap[matchActiveTab.activeTab];
        }

        return 0;
    });

    const compareUpdated = (a, b) => {
        if (isDesc) {
            return a.updatedTimestamp - b.updatedTimestamp;
        } else {
            return b.updatedTimestamp - a.updatedTimestamp;
        }
    };

    const compareCreated = (a, b) => {
        if (isDesc) {
            return a.createdTimestamp - b.createdTimestamp;
        } else {
            return b.createdTimestamp - a.createdTimestamp;
        }
    };

    const compareNumber = (a, b) => {
        if (isDesc) {
            return Number(a.number) - Number(b.number);
        } else {
            return Number(b.number) - Number(a.number);
        }
    };

    const compareStatus = (a, b) => {
        if (isDesc) {
            return weightByStatus(a) - weightByStatus(b);
        } else {
            return weightByStatus(b) - weightByStatus(a);
        }
    };

    const compareLabel = (a, b) => {
        if (isDesc) {
            return weightByLabel(a) - weightByLabel(b);
        } else {
            return weightByLabel(b) - weightByLabel(a);
        }
    };

    const compareReportArea = (a, b) => {
        if (isDesc) {
            return a.reportAreaId || 0 - b.reportAreaId || 0;
        } else {
            return b.reportAreaId || 0 - a.reportAreaId || 0;
        }
    };

    const compareName = (a, b) => {
        if (isDesc) {
            return (b.name || 'z')
                .toLowerCase()
                .localeCompare((a.name || 'z').toLowerCase());
        } else {
            return (a.name || 'z')
                .toLowerCase()
                .localeCompare((b.name || 'z').toLowerCase());
        }
    };

    React.useEffect(() => {
        let updatedReports;

        if (activeTab === 1) {
            updatedReports = [...notificationReports];
        } else if (activeTab === 2) {
            updatedReports = [...closedReports];
        } else if (activeTab === 3) {
            updatedReports = [...closedReports, ...reports];

            if (filterNumber !== '' && filterNumber !== 0) {
                updatedReports = updatedReports.filter(
                    (r) => r.number === filterNumber.toString()
                );
            }
            if (filterName !== '') {
                updatedReports = updatedReports.filter(
                    (r) =>
                        r.name != null &&
                        r.name
                            .toLowerCase()
                            .startsWith(filterName.toLowerCase())
                );
            }
            if (filterStatus !== 'none') {
                updatedReports = updatedReports.filter(
                    (r) => r.status != null && r.status === filterStatus
                );
            }
            if (filterLabel !== 'none') {
                updatedReports = updatedReports.filter((r) =>
                    hasItem(r.assignedLabels, filterLabel)
                );
            }
            if (filterUser !== 'none') {
                updatedReports = updatedReports.filter((r) =>
                    hasItem(r.assignedUserIds, filterUser)
                );
            }
            if (filterReportArea !== 'none') {
                updatedReports = updatedReports.filter(
                    (r) =>
                        r.reportAreaId != null &&
                        r.reportAreaId === filterReportArea
                );
            }
        } else {
            updatedReports = [...reports];
        }

        switch (sortBy) {
            case 'number':
                updatedReports.sort(compareNumber);
                break;
            case 'created':
                updatedReports.sort(compareCreated);
                break;
            case 'status':
                updatedReports.sort(compareStatus);
                break;
            case 'label':
                updatedReports.sort(compareLabel);
                break;
            case 'reportArea':
                updatedReports.sort(compareReportArea);
                break;
            case 'name':
                updatedReports.sort(compareName);
                break;
            case 'modified':
            default:
                updatedReports.sort(compareUpdated);
                break;
        }

        setSortedReports(updatedReports);
    }, [
        reports,
        closedReports,
        sortBy,
        isDesc,
        activeTab,
        filterNumber,
        filterName,
        filterStatus,
        filterLabel,
        filterUser,
        filterReportArea,
        notificationReports,
    ]);

    React.useEffect(() => {
        // console.log('Path is ', location.pathname)
        if (
            location.pathname === `/app/${organisationId}/reports` &&
            activeTab !== 0
        ) {
            setActiveTab(0);
        }
    }, [location.pathname]);

    React.useEffect(() => {
        if (activeTab >= 2) {
            let timeNow = new Date().getTime();
            if (timeNow - lastFetchedClosedReports > 1000 * 10) {
                lastFetchedClosedReports = new Date().getTime();
                setRefreshClosedReports(true);
            }
        }
    }, [activeTab]);

    const onRefresh = () => {
        if (activeTab === 2) {
            setRefreshClosedReports(true);
        } else {
            setRefreshReports(true);
        }
    };

    const handleTabChange = (e, newValue: number) => {
        setActiveTab(newValue);
        switch (newValue) {
            case 0:
                navigate(`/app/${organisationId}/reports`);
                break;
            case 1:
                navigate(`/app/${organisationId}/reports/attention`);
                break;
            case 2:
                navigate(`/app/${organisationId}/reports/closed`);
                break;
            case 3:
                navigate(`/app/${organisationId}/reports/search`);
                break;
            default:
                navigate(`/app/${organisationId}/reports`);
                break;
        }
    };

    const renderReports = () => {
        if (organisation == null || refreshReports) {
            return (
                <TableRow>
                    <TableCell>
                        <CircularProgress />
                    </TableCell>
                </TableRow>
            );
        }

        return sortedReports.map((n) => (
            <ReportRow
                key={n.id}
                report={n}
                setReport={setReport}
                language={language}
                activeTab={activeTab}
                users={users}
                content={content}
            />
        ));
    };

    const onSort = (isDescending: boolean, name: string) => {
        if (isDesc !== isDescending) {
            setIsDesc(isDescending);
        }
        if (name !== sortBy) {
            setSortBy(name);
            setIsDesc(false);
        }
    };

    return (
        <>
            <h2>{localization[language].app.labelReports}</h2>
            <Card variant='outlined' className={style.tableCard}>
                <CardContent className={style.tableHeaderContent}>
                    <Tabs
                        value={activeTab}
                        onChange={handleTabChange}
                        variant='standard'
                        indicatorColor='primary'
                        textColor='primary'
                        aria-label='icon label tabs'
                    >
                        <Tab
                            icon={<LibraryBooks />}
                            label={
                                localization[language].app.labelActiveReports
                            }
                        />
                        <Tab
                            icon={<NotificationsActive />}
                            label={
                                <>
                                    {localization[language].app.newActivity}{' '}
                                    {notificationReports.length > 0 ? (
                                        <>({notificationReports.length})</>
                                    ) : null}
                                </>
                            }
                        />
                        <Tab
                            icon={<Archive />}
                            label={localization[language].app.labelClosed}
                        />
                        <Tab
                            icon={<Search />}
                            label={localization[language].app.search}
                        />
                    </Tabs>
                    <div className={style.tableHeader}>
                        <Tooltip title={localization[language].form.title}>
                            <IconButton
                                onClick={() =>
                                    navigate(
                                        `/app/${organisationId}/reports/new`
                                    )
                                }
                            >
                                <PostAdd />
                            </IconButton>
                        </Tooltip>
                        <Tooltip
                            title={localization[language].app.refreshReports}
                        >
                            <IconButton
                                onClick={onRefresh}
                                disabled={refreshReports}
                            >
                                <Refresh />
                            </IconButton>
                        </Tooltip>
                    </div>
                </CardContent>
                {activeTab === 3 && (
                    <CardContent>
                        <form
                            noValidate
                            autoComplete='off'
                            className={style.searchForm}
                        >
                            {/* <h3>{localization[language].app.search}</h3> */}
                            <Grid container spacing={2}>
                                <Grid item xs={2} md={2} lg={2} xl={1}>
                                    <label>
                                        {
                                            localization[language].app
                                                .reportNumber
                                        }
                                    </label>
                                    <input
                                        type='number'
                                        maxLength={100}
                                        value={filterNumber}
                                        onChange={(e) =>
                                            setFilterNumber(e.target.value)
                                        }
                                    />
                                </Grid>
                                <Grid item xs={8} md={6} lg={4} xl={2}>
                                    <label>
                                        {localization[language].app.caseName}
                                    </label>
                                    <input
                                        type='text'
                                        maxLength={400}
                                        value={filterName}
                                        onChange={(e) =>
                                            setFilterName(e.target.value)
                                        }
                                    />
                                </Grid>
                                <Grid item xs={8} md={6} lg={4} xl={2}>
                                    <label>
                                        {localization[language].app.labelStatus}
                                    </label>
                                    <select
                                        value={filterStatus}
                                        onChange={(e) =>
                                            setFilterStatus(e.target.value)
                                        }
                                    >
                                        <option value='none'>
                                            {
                                                localization[language].app
                                                    .nothingSelected
                                            }
                                        </option>
                                        <option value='open'>
                                            {
                                                localization[language]
                                                    .reportStatus.open
                                            }
                                        </option>
                                        <option value='open_inprogress'>
                                            {
                                                localization[language]
                                                    .reportStatus
                                                    .open_inprogress
                                            }
                                        </option>
                                        <option value='pending_information'>
                                            {
                                                localization[language]
                                                    .reportStatus
                                                    .pending_information
                                            }
                                        </option>
                                        <option value='pending_comment'>
                                            {
                                                localization[language]
                                                    .reportStatus
                                                    .pending_comment
                                            }
                                        </option>
                                        <option value='pending_reply_from_notifier'>
                                            {
                                                localization[language]
                                                    .reportStatus
                                                    .pending_reply_from_notifier
                                            }
                                        </option>
                                        <option value='closed_insufficient_info'>
                                            {
                                                localization[language]
                                                    .reportStatus
                                                    .closed_insufficient_info
                                            }
                                        </option>
                                        <option value='closed_invalid_report'>
                                            {
                                                localization[language]
                                                    .reportStatus
                                                    .closed_invalid_report
                                            }
                                        </option>
                                        <option value='closed_spam'>
                                            {
                                                localization[language]
                                                    .reportStatus.closed_spam
                                            }
                                        </option>
                                        <option value='closed_resolved'>
                                            {
                                                localization[language]
                                                    .reportStatus
                                                    .closed_resolved
                                            }
                                        </option>
                                    </select>
                                </Grid>
                                <Grid item xs={8} md={6} lg={3} xl={2}>
                                    <label>
                                        {localization[language].app.label}
                                    </label>
                                    <select
                                        value={filterLabel}
                                        onChange={(e) =>
                                            setFilterLabel(e.target.value)
                                        }
                                    >
                                        <option value='none'>
                                            {
                                                localization[language].app
                                                    .nothingSelected
                                            }
                                        </option>
                                        {content.reportLabels != null &&
                                            content.reportLabels.map(
                                                (reportLabel) => (
                                                    <option
                                                        key={reportLabel.id}
                                                        value={reportLabel.id}
                                                    >
                                                        {reportLabel.title.is ||
                                                            reportLabel.title
                                                                .en}
                                                    </option>
                                                )
                                            )}
                                    </select>
                                </Grid>
                                <Grid item xs={8} md={6} lg={3} xl={2}>
                                    <label>
                                        {localization[language].app.reportAreas}
                                    </label>
                                    <select
                                        value={filterReportArea}
                                        onChange={(e) =>
                                            setFilterReportArea(e.target.value)
                                        }
                                    >
                                        <option value='none'>
                                            {
                                                localization[language].app
                                                    .nothingSelected
                                            }
                                        </option>
                                        {organisation?.reportChannels != null &&
                                            organisation.reportChannels.map(
                                                (reportChannel) => (
                                                    <option
                                                        key={reportChannel.id}
                                                        value={reportChannel.id}
                                                    >
                                                        {
                                                            reportChannel.subdomain
                                                        }
                                                    </option>
                                                )
                                            )}
                                    </select>
                                </Grid>
                                <Grid item xs={8} md={6} lg={4} xl={2}>
                                    <label>
                                        {
                                            localization[language].app
                                                .assignedUser
                                        }
                                    </label>
                                    <select
                                        value={filterUser}
                                        onChange={(e) =>
                                            setFilterUser(e.target.value)
                                        }
                                    >
                                        <option value='none'>
                                            {
                                                localization[language].app
                                                    .noneSelected
                                            }
                                        </option>
                                        {users != null &&
                                            users
                                                .filter(
                                                    (u) =>
                                                        !u.isPendingInvitation
                                                )
                                                .map((user) => (
                                                    <option
                                                        key={user.id}
                                                        value={user.id}
                                                    >
                                                        {user.name}
                                                    </option>
                                                ))}
                                    </select>
                                </Grid>
                            </Grid>
                        </form>
                    </CardContent>
                )}
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TableContainer>
                            <Table aria-label='table'>
                                <TableHead>
                                    <TableRow
                                        style={{
                                            backgroundColor: '#eeeeee',
                                        }}
                                    >
                                        <TableCell size='small' align='left'>
                                            <TableSortLabel
                                                onClick={() =>
                                                    onSort(!isDesc, 'number')
                                                }
                                                active={sortBy === 'number'}
                                                direction={
                                                    isDesc &&
                                                    sortBy === 'number'
                                                        ? 'desc'
                                                        : 'asc'
                                                }
                                            >
                                                #
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell align='left'>
                                            <TableSortLabel
                                                onClick={() =>
                                                    onSort(!isDesc, 'name')
                                                }
                                                active={sortBy === 'name'}
                                                direction={
                                                    isDesc && sortBy === 'name'
                                                        ? 'desc'
                                                        : 'asc'
                                                }
                                            >
                                                {
                                                    localization[language].app
                                                        .caseName
                                                }
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell align='center' size='small'>
                                            <TableSortLabel
                                                onClick={() =>
                                                    onSort(!isDesc, 'status')
                                                }
                                                active={sortBy === 'status'}
                                                direction={
                                                    isDesc &&
                                                    sortBy === 'status'
                                                        ? 'desc'
                                                        : 'asc'
                                                }
                                            >
                                                {
                                                    localization[language].app
                                                        .labelStatus
                                                }
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell align='center' size='small'>
                                            <TableSortLabel
                                                onClick={() =>
                                                    onSort(!isDesc, 'label')
                                                }
                                                active={sortBy === 'label'}
                                                direction={
                                                    isDesc && sortBy === 'label'
                                                        ? 'desc'
                                                        : 'asc'
                                                }
                                            >
                                                {
                                                    localization[language].app
                                                        .labels
                                                }
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell align='left'>
                                            {
                                                localization[language].app
                                                    .assignedUsers
                                            }
                                        </TableCell>
                                        <TableCell align='left'>
                                            <TableSortLabel
                                                onClick={() =>
                                                    onSort(!isDesc, 'created')
                                                }
                                                active={sortBy === 'created'}
                                                direction={
                                                    isDesc &&
                                                    sortBy === 'created'
                                                        ? 'desc'
                                                        : 'asc'
                                                }
                                            >
                                                {
                                                    localization[language].app
                                                        .received
                                                }
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell align='left'>
                                            <TableSortLabel
                                                onClick={() =>
                                                    onSort(!isDesc, 'modified')
                                                }
                                                active={sortBy === 'modified'}
                                                direction={
                                                    isDesc &&
                                                    sortBy === 'modified'
                                                        ? 'desc'
                                                        : 'asc'
                                                }
                                            >
                                                {
                                                    localization[language].app
                                                        .labelReportLastUpdated
                                                }
                                            </TableSortLabel>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>{renderReports()}</TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </Card>
        </>
    );
};

export default React.memo(ReportList);
