import React from 'react';
// import axios from 'axios'
import { Router } from '@reach/router';

import localization from '../../../localization';
import * as style from './index.module.scss';
// import config from '../../../config'

import SEO from '../../seo';
import ListGroups from './ListGroups';
import FormGroupFields from './FormGroupFields';
import FormFieldEditor from './FormFieldEditor';

interface Props {
    language: string;
    organisation: any;
    content: any;
    setContent: any;
}

const Form = ({ language, organisation, content, setContent }: Props) => {
    // const languages = [{ short: 'is', title: 'Íslenska' }, { short: 'en', title: 'English' }]
    const refIsRemoved = React.useRef(false);

    // const [isSavingContent, setIsSavingContent] = React.useState(false)
    const [selectedGroup, setSelectedGroup] = React.useState(null);
    const [isCreating, setIsCreating] = React.useState(false);

    React.useEffect(() => {
        return () => {
            refIsRemoved.current = true;
        };
    }, []);

    // const onDragEndHandler = async (res) => {
    //     if (!res || !res.destination || res.destination.index === res.source.index) {
    //         return
    //     }
    //     let updatedGroups = [
    //         ...content.groups,
    //     ]

    //     const [removed] = updatedGroups.splice(res.source.index, 1)
    //     updatedGroups.splice(res.destination.index, 0, removed)

    //     setContent({
    //         ...content,
    //         groups: updatedGroups
    //     })

    //     try {
    //         setIsSavingContent(true)
    //         await axios.post(
    //             `${config.apiBaseUrl}/organisation/${organisationId}/content/groups/changeorder/${res.source.index}/${res.destination.index}`,
    //             null,
    //             {
    //                 headers: {
    //                     Authorization: `Bearer ${accessToken}`,
    //                 },
    //             },
    //         )
    //     } catch (err) {
    //         console.log(err)
    //     }
    //     setIsSavingContent(false)
    // }

    return (
        <div className={style.formPage}>
            <SEO title={localization[language].formEditor.reportForm} />
            {/* <Card
                default
                className={style.item}
                variant="outlined"
            > */}
            {/* <CardContent> */}
            <Router>
                <ListGroups
                    default
                    // organisation={organisation}
                    content={content}
                    setContent={setContent}
                    language={language}
                />
                <FormGroupFields
                    path='/:groupId/*'
                    selectedGroup={selectedGroup}
                    setSelectedGroup={setSelectedGroup}
                    content={content}
                    setContent={setContent}
                    isCreating={isCreating}
                    setIsCreating={setIsCreating}
                    language={language}
                />
                <FormFieldEditor
                    path='/:groupId/create-field'
                    organisation={organisation}
                    selectedGroup={selectedGroup}
                    setSelectedGroup={setSelectedGroup}
                    content={content}
                    setContent={setContent}
                    isCreatingField={true}
                    language={language}
                />
                <FormFieldEditor
                    path='/:groupId/:fieldId/'
                    organisation={organisation}
                    selectedGroup={selectedGroup}
                    setSelectedGroup={setSelectedGroup}
                    content={content}
                    setContent={setContent}
                    language={language}
                />
            </Router>
            {/* </CardContent> */}
            {/* </Card> */}
        </div>
    );
};

export default Form;
