import React from 'react';
import axios from 'axios';
import {
    Button,
    Card,
    CardActions,
    CardContent,
    Checkbox,
    CircularProgress,
    FormControlLabel,
    Switch,
    Tooltip,
} from '@mui/material';

import localization from '../../../localization';
import config from '../../../config';
import { Email, Help, InsertLink } from '@mui/icons-material';
import * as style from './index.module.scss';

interface ReportInviteBodyProps {
    language: string;
    report: any;
    organisationId: string;
    accessToken: any;
    setExpandInvitationCard: any;
    links: any;
    setLinks: any;
}

const ReportInviteBody = ({
    language,
    report,
    organisationId,
    accessToken,
    setExpandInvitationCard,
    links,
    setLinks,
}: ReportInviteBodyProps) => {
    // State for invitation
    const [recipientName, setRecipientName] = React.useState<string>('');
    const [recipientEmail, setRecipientEmail] = React.useState<string>('');
    const [optionalInviteMessage, setOptionalInviteMessage] =
        React.useState('');
    const [canReadReport, setCanReadReport] = React.useState(false);
    const [canReadComments, setCanReadComments] = React.useState(false);
    const [canWriteComment, setCanWriteComment] = React.useState(true);
    const [isOneTimeLink, setIsOneTimeLink] = React.useState(true);
    const [isCreatingReportLink, setIsCreatingReportLink] =
        React.useState(false);
    const [createdReportLink, setCreatedReportLink] = React.useState<any>(null);
    const [hasCreatedReportLink, setHasCreatedReportLink] =
        React.useState(false);
    const [sendInviteWithEmail, setSendInviteWithEmail] = React.useState(false);
    const recipientNameRef = React.useRef<any>();
    const recipientEmailRef = React.useRef<any>();

    const createReportLinkAsync = async () => {
        setIsCreatingReportLink(true);

        try {
            const response = await axios.post(
                `${config.apiBaseUrl}/organisation/${organisationId}/report/${report.id}/link`,
                {
                    name: recipientName,
                    email: recipientEmail,
                    message: optionalInviteMessage,
                    canReadReport,
                    canReadComments,
                    canWriteComment,
                    sendInviteWithEmail,
                    isOneTimeLink,
                },
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            );
            if (!sendInviteWithEmail) {
                // setCreatedReportLink(response.data.link)
                setCreatedReportLink(
                    `https://tilkynna.is/app/${organisationId}/link/${response.data.reportLink.id}/id/${response.data.reportLink.reportId}`
                );
            }
            setHasCreatedReportLink(true);
            setLinks([response.data.reportLink, ...links]);
            console.log(response.data);
        } catch (err) {
            console.log(err);
        }
        setIsCreatingReportLink(false);
    };

    const cancelInvitation = () => {
        setExpandInvitationCard(false);
        setSendInviteWithEmail(false);
        setRecipientEmail('');
        setRecipientName('');
        setOptionalInviteMessage('');
        setHasCreatedReportLink(false);
        setCreatedReportLink(null);
    };

    React.useEffect(() => {
        if (recipientNameRef.current != null) {
            recipientNameRef.current.focus();
        }
    }, []);

    React.useEffect(() => {
        if (sendInviteWithEmail && recipientEmailRef.current != null) {
            recipientEmailRef.current.focus();
        }
    }, [sendInviteWithEmail]);

    if (isCreatingReportLink) {
        return (
            <CardContent>
                <CircularProgress />
            </CardContent>
        );
    }

    if (hasCreatedReportLink) {
        if (sendInviteWithEmail) {
            return (
                <>
                    <CardContent>
                        <h3>{localization[language].app.emailSent}</h3>
                    </CardContent>
                    <CardActions>
                        <Button onClick={cancelInvitation}>
                            {localization[language].app.labelClose}
                        </Button>
                    </CardActions>
                </>
            );
        }
        return (
            <>
                <CardContent>
                    {createdReportLink != null && (
                        <>
                            <label className={style.label} htmlFor='copyLink'>
                                {localization[language].app.labelLink}
                            </label>
                            <textarea
                                name='copyLink'
                                id='copyLink'
                                value={createdReportLink}
                                readOnly
                            />
                        </>
                    )}
                </CardContent>
                <CardActions>
                    <Button onClick={cancelInvitation}>
                        {localization[language].app.labelClose}
                    </Button>
                </CardActions>
            </>
        );
    }
    return (
        <>
            <CardContent>
                <div className={style.item}>
                    <label className={style.label}>
                        {localization[language].app.labelRecipientFullName}{' '}
                        <span>*</span>
                    </label>
                    <input
                        type='text'
                        maxLength={200}
                        value={recipientName}
                        ref={recipientNameRef}
                        onChange={(e) => setRecipientName(e.target.value)}
                        disabled={isCreatingReportLink}
                    />
                </div>
                <div className={style.item}>
                    <label className={style.label}>
                        {localization[language].app.labelOptionalMessage}
                    </label>
                    <textarea
                        maxLength={500}
                        value={optionalInviteMessage}
                        onChange={(e) =>
                            setOptionalInviteMessage(e.target.value)
                        }
                        disabled={isCreatingReportLink}
                    />
                </div>
                <div className={style.item}>
                    <label>{localization[language].app.labelRole}</label>
                    <div>
                        <div className={style.permissionItem}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        // size="small"
                                        checked={canWriteComment}
                                        onChange={(e) =>
                                            setCanWriteComment(e.target.checked)
                                        }
                                        name='canWriteComment'
                                        disabled={isCreatingReportLink}
                                    />
                                }
                                label={
                                    localization[language].report.writeComment
                                }
                            />
                            <Tooltip
                                title={
                                    <p className='tooltipLarge'>
                                        {
                                            localization[language].app
                                                .tooltipCanWriteComments
                                        }
                                    </p>
                                }
                            >
                                <Help
                                    style={{ color: 'rgb(22, 127, 172)' }}
                                    fontSize='small'
                                />
                            </Tooltip>
                        </div>
                        <div className={style.permissionItem}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        // size="small"
                                        checked={canReadComments}
                                        onChange={(e) =>
                                            setCanReadComments(e.target.checked)
                                        }
                                        name='canReadComments'
                                        disabled={isCreatingReportLink}
                                    />
                                }
                                label={
                                    localization[language].report.readComments
                                }
                            />
                            <Tooltip
                                title={
                                    <p className='tooltipLarge'>
                                        {
                                            localization[language].app
                                                .tooltipCanReadComments
                                        }
                                    </p>
                                }
                            >
                                <Help
                                    style={{ color: 'rgb(22, 127, 172)' }}
                                    fontSize='small'
                                />
                            </Tooltip>
                        </div>

                        <div className={style.permissionItem}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        // size="small"
                                        checked={canReadReport}
                                        onChange={(e) =>
                                            setCanReadReport(e.target.checked)
                                        }
                                        name='canReadReport'
                                        disabled={isCreatingReportLink}
                                    />
                                }
                                label={localization[language].report.readReport}
                            />
                            <Tooltip
                                title={
                                    <p className='tooltipLarge'>
                                        {
                                            localization[language].app
                                                .tooltipCanReadReport
                                        }
                                    </p>
                                }
                            >
                                <Help
                                    style={{ color: 'rgb(22, 127, 172)' }}
                                    fontSize='small'
                                />
                            </Tooltip>
                        </div>
                    </div>
                </div>
                <div className={style.item}>
                    <label>{localization[language].app.labelLink}</label>
                    <div>
                        <FormControlLabel
                            control={
                                <Switch
                                    // size="small"
                                    checked={isOneTimeLink}
                                    onChange={(e) =>
                                        setIsOneTimeLink(e.target.checked)
                                    }
                                    name='oneTimeLink'
                                    disabled={isCreatingReportLink}
                                />
                            }
                            label={localization[language].app.labelOneTimeLink}
                        />
                    </div>
                </div>

                {!sendInviteWithEmail && (
                    <div className={style.actions}>
                        <Button
                            variant='outlined'
                            onClick={createReportLinkAsync}
                            disabled={
                                isCreatingReportLink ||
                                recipientName.length === 0
                            }
                        >
                            <InsertLink
                                fontSize='small'
                                style={{ marginRight: 6 }}
                            />{' '}
                            {localization[language].app.labelCreateReportLink}
                        </Button>
                        <Button
                            variant='outlined'
                            onClick={() => setSendInviteWithEmail(true)}
                            disabled={isCreatingReportLink}
                        >
                            <Email
                                fontSize='small'
                                style={{ marginRight: 6 }}
                            />{' '}
                            {
                                localization[language].app
                                    .labelCreateReportLinkEmail
                            }
                        </Button>
                    </div>
                )}
            </CardContent>

            {!sendInviteWithEmail && (
                <>
                    <CardActions>
                        <Button
                            onClick={cancelInvitation}
                            disabled={isCreatingReportLink}
                        >
                            {localization[language].form.labelCancel}
                        </Button>
                    </CardActions>
                </>
            )}
            {sendInviteWithEmail && (
                <CardContent>
                    <Card variant='outlined'>
                        <CardContent>
                            <label className={style.label}>
                                {localization[language].app.labelEmail}
                            </label>
                            <input
                                type='email'
                                maxLength={1000}
                                value={recipientEmail}
                                onChange={(e) =>
                                    setRecipientEmail(e.target.value)
                                }
                                disabled={isCreatingReportLink}
                                ref={recipientEmailRef}
                            />
                        </CardContent>
                        <CardActions>
                            <Button
                                disabled={
                                    isCreatingReportLink ||
                                    recipientName.length === 0 ||
                                    recipientEmail.length === 0 ||
                                    recipientEmail.indexOf('@') < 1
                                }
                                onClick={createReportLinkAsync}
                            >
                                {localization[language].app.labelSendEmail}
                            </Button>
                            <Button
                                onClick={() => setSendInviteWithEmail(false)}
                                disabled={isCreatingReportLink}
                            >
                                {localization[language].form.labelCancel}
                            </Button>
                        </CardActions>
                    </Card>
                </CardContent>
            )}
        </>
    );
};

interface ReportInviteProps {
    language: string;
    report: any;
    organisationId: string;
    accessToken: string;
    title: string;
    info: any;
    links: any;
    setLinks: any;
}

const ReportInvite = ({
    language,
    report,
    organisationId,
    accessToken,
    title,
    info,
    links,
    setLinks,
}: ReportInviteProps) => {
    const [expandInvitationCard, setExpandInvitationCard] =
        React.useState(false);

    const onExpandInvitationCard = () => {
        setExpandInvitationCard(true);
    };

    return (
        <Card variant='outlined' className={style.invitationCard}>
            <CardContent>
                <h2>{title}</h2>
                <p>{info}</p>
            </CardContent>
            {!expandInvitationCard ? (
                <CardActions>
                    <Button
                        onClick={onExpandInvitationCard}
                        disabled={accessToken == null}
                    >
                        {localization[language].app.labelContinue}
                    </Button>
                </CardActions>
            ) : (
                <ReportInviteBody
                    setExpandInvitationCard={setExpandInvitationCard}
                    language={language}
                    report={report}
                    organisationId={organisationId}
                    accessToken={accessToken}
                    links={links}
                    setLinks={setLinks}
                />
            )}
        </Card>
    );
};

export default React.memo(ReportInvite);
