// extracted by mini-css-extract-plugin
export var actions = "index-module--actions--XpGVf";
export var btnAttachment = "index-module--btnAttachment--LIVgy";
export var btnSecondary = "index-module--btnSecondary--ML4sU";
export var color = "index-module--color--5zxrg";
export var currentSetting = "index-module--currentSetting--PJkV8";
export var dialog = "index-module--dialog--xMLO7";
export var fileInput = "index-module--fileInput--4u8Ua";
export var followUp = "index-module--followUp--9dSny";
export var fullSize = "index-module--fullSize--wiEfm";
export var imgLogo = "index-module--imgLogo--tSbj9";
export var infoNoLogo = "index-module--infoNoLogo--6IN-z";
export var item = "index-module--item--xyT1L";
export var label = "index-module--label--mwI8v";
export var labelDialogContent = "index-module--labelDialogContent--nH9WH";
export var notifier = "index-module--notifier--1InoW";
export var organisationTextPreview = "index-module--organisationTextPreview--SmoiH";
export var passwordProtected = "index-module--passwordProtected--l+kTK";
export var preview = "index-module--preview--0omLb";
export var reportChannel = "index-module--reportChannel--MkZ95";
export var reportChannelDialogContent = "index-module--reportChannelDialogContent--mI-b1";
export var reportChannelSettings = "index-module--reportChannelSettings--OBh73";
export var reportChannels = "index-module--reportChannels--EE14z";
export var reportDefaultMessages = "index-module--reportDefaultMessages--T+GmJ";
export var reportLabels = "index-module--reportLabels--JpPt-";
export var settingsPage = "index-module--settingsPage--xUroN";
export var subCardContent = "index-module--subCardContent--kF94a";
export var tooltip = "index-module--tooltip--Vsp-f";