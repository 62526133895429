import React, { useState } from 'react';
import axios from 'axios';

import {
    Button,
    Card,
    CardContent,
    FormControlLabel,
    Checkbox,
    DialogTitle,
    DialogContent,
    DialogActions,
    Dialog,
    CircularProgress,
    FormGroup,
    FormHelperText,
    Paper,
    CardActions,
    Grid,
} from '@mui/material';
import localization from '../../../localization';
import { useAppSelector } from '../../../hooks';
import config from '../../../config';

import SEO from '../../seo';
import { navigate } from 'gatsby-link';
import { Link } from 'gatsby';
import { Content } from '../../../interfaces/Content';
import classNames from 'classnames';
import MarkdownEditor from '../../MarkdownEditor';

import * as style from './index.module.scss';

interface Props {
    language: string;
    content: Content;
    setContent: any;
    pageId: string;
    organisation: any;
    organisationId: string;
    isCreatingPage: boolean;
}

const Page = ({
    language,
    content,
    setContent,
    pageId,
    organisation,
    organisationId,
    isCreatingPage,
}: Props) => {
    const accessToken = useAppSelector(
        (state) => state.global.auth.accessToken
    );

    const languages = [
        { short: 'is', title: 'Íslenska' },
        { short: 'en', title: 'English' },
    ];
    const [isSavingContent, setIsSavingContent] = useState(false);
    const refIsRemoved = React.useRef(false);

    const [isCreatingNewPage, setIsCreatingNewPage] = useState(false);
    const [selectedPage, setSelectedPage] = useState<any | null>(null);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);

    // const [selectedEditorTab, setSelectedEditorTab] = useState<any>({
    //     is: 'write',
    //     en: 'write',
    // });

    const [isContentReady, setIsContentReady] = useState(false);

    React.useEffect(() => {
        if (
            selectedPage === null &&
            pageId != null &&
            content != null &&
            content.pages != null &&
            !content.isFetching
        ) {
            let found = false;
            for (let i = 0; i < content.pages.length; i++) {
                if (content.pages[i].id === pageId) {
                    setSelectedPage(content.pages[i]);
                    found = true;
                    break;
                }
            }

            setIsContentReady(true);

            if (!found) {
                navigate(`/app/${organisationId}/content/`);
            }
        }
    }, [selectedPage, pageId, content, organisationId]);

    React.useEffect(() => {
        return () => {
            refIsRemoved.current = true;
        };
    }, []);

    React.useLayoutEffect(() => {
        if (isCreatingPage) {
            let pageId = new Date().getTime().toString();
            setIsCreatingNewPage(true);
            setSelectedPage({
                id: pageId,
                hidden: false,
                isPrivacyPolicy: false,
                excludedReportAreaIds: [],
                name: '',
                title: {
                    is: ``,
                    en: ``,
                },
                content: {
                    is: ``,
                    en: ``,
                },
            });
        }
    }, [isCreatingPage]);

    const onSaveAsync = async () => {
        if (isCreatingNewPage) {
            setContent({
                ...content,
                pages: [...content.pages, selectedPage],
            });
        } else {
            let pageIndex = -1;
            for (let i = 0; i < content.pages.length; i++) {
                if (content.pages[i].id === selectedPage.id) {
                    pageIndex = i;
                    break;
                }
            }
            if (pageIndex === -1) {
                console.log('Page not found when updating a page');
            } else {
                let updatedPages = [...content.pages];
                updatedPages[pageIndex] = {
                    ...selectedPage,
                };

                updatedPages.sort(
                    (a, b) => parseInt(a.index) - parseInt(b.index)
                );

                setContent({
                    ...content,
                    pages: updatedPages,
                });
            }
        }

        try {
            setIsSavingContent(true);
            await axios.post(
                `${config.apiBaseUrl}/organisation/${organisationId}/content/${selectedPage.id}`,
                {
                    ...selectedPage,
                },
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            );
        } catch (err) {
            console.log(err);
        }
        // setIsSavingContent(false)
        // setSelectedPage(null)
        // setIsCreatingNewPage(false)
        navigate(`/app/${organisationId}/content/`);
    };

    const onDeleteAsync = async () => {
        try {
            setIsSavingContent(true);
            await axios.delete(
                `${config.apiBaseUrl}/organisation/${organisationId}/content/${selectedPage.id}`,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            );

            setContent({
                ...content,
                pages: [
                    ...content.pages.filter(
                        (p: any) => p.id !== selectedPage.id
                    ),
                ],
            });
        } catch (err) {
            console.log(err);
        }
        setShowDeleteDialog(false);
        setIsSavingContent(false);
        setSelectedPage(null);
        setIsCreatingNewPage(false);
    };

    const onTitleChange = (title: string, language: string) => {
        setSelectedPage({
            ...selectedPage,
            title: {
                ...selectedPage.title,
                [language]: title,
            },
        });
    };

    const onContentChange = (content: any, language: string) => {
        setSelectedPage({
            ...selectedPage,
            content: {
                ...selectedPage.content,
                [language]: content,
            },
        });
    };

    const onToggleHidden = (e: any) => {
        setSelectedPage({
            ...selectedPage,
            hidden: e.target.checked,
        });
    };
    const onToggleIsPrivacyPolicy = (e: any) => {
        setSelectedPage({
            ...selectedPage,
            isPrivacyPolicy: e.target.checked,
        });
    };

    const isPageVisibleForReportArea = (reportAreaId: string) => {
        if (
            selectedPage != null &&
            selectedPage.excludedReportAreaIds != null
        ) {
            for (
                let i = 0;
                i < selectedPage.excludedReportAreaIds.length;
                i++
            ) {
                if (selectedPage.excludedReportAreaIds[i] === reportAreaId) {
                    return false;
                }
            }
        }

        return true;
    };

    const togglePageVisibilityForReportArea = (e: any) => {
        if (selectedPage == null || e.target.name == null) {
            return;
        }

        const reportAreaId = e.target.name.toString();

        let updatedSelectedPage = {
            ...selectedPage,
        };

        if (updatedSelectedPage.excludedReportAreaIds == null) {
            updatedSelectedPage = {
                ...updatedSelectedPage,
                excludedReportAreaIds: [],
            };
        }

        if (!e.target.checked) {
            updatedSelectedPage = {
                ...updatedSelectedPage,
                excludedReportAreaIds: [
                    ...updatedSelectedPage.excludedReportAreaIds,
                    reportAreaId,
                ],
            };
        } else {
            updatedSelectedPage = {
                ...updatedSelectedPage,
                excludedReportAreaIds: [
                    ...updatedSelectedPage.excludedReportAreaIds.filter(
                        (excludedReportAreaId: string) =>
                            excludedReportAreaId !== reportAreaId
                    ),
                ],
            };
        }

        setSelectedPage(updatedSelectedPage);
    };

    if (selectedPage == null) {
        return <CircularProgress />;
    }

    return (
        <>
            <SEO
                title={
                    isCreatingPage
                        ? localization[language].content.createPage
                        : selectedPage.title[language] ||
                          selectedPage.title.en ||
                          selectedPage.title.is
                }
            />
            <Card className={style.item} variant='outlined'>
                <CardContent>
                    <h3>
                        <Link to={`/app/${organisationId}/content/`}>
                            {localization[language].content.pages}
                        </Link>{' '}
                        /{' '}
                        <>
                            {isCreatingPage
                                ? localization[language].content.createPage
                                : selectedPage.title[language] ||
                                  selectedPage.title.en ||
                                  selectedPage.title.is}
                        </>
                    </h3>
                    <Paper variant='outlined' className={style.settingsCard}>
                        <h3>{localization[language].app.labelSettings}</h3>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        size='small'
                                        checked={selectedPage.hidden}
                                        onChange={onToggleHidden}
                                        name='hidden'
                                        color='primary'
                                        disabled={isSavingContent}
                                    />
                                }
                                label={localization[language].content.hidePage}
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        size='small'
                                        checked={selectedPage.isPrivacyPolicy}
                                        onChange={onToggleIsPrivacyPolicy}
                                        name='isPrivacyPolicy'
                                        color='primary'
                                        disabled={isSavingContent}
                                    />
                                }
                                label={
                                    localization[language].content
                                        .markPageAsPrivacyPolicy
                                }
                            />
                            <FormHelperText style={{ marginTop: 0 }}>
                                {
                                    localization[language].content
                                        .whatIsPrivacyPolicy
                                }
                            </FormHelperText>
                        </FormGroup>
                        <FormGroup style={{ marginTop: '1em' }}>
                            <h3>
                                {
                                    localization[language].app
                                        .pageIsVisibleForThoseReportAreas
                                }
                                :
                            </h3>
                            <Grid container spacing={2}>
                                {organisation != null &&
                                    organisation.reportChannels.map(
                                        (reportChannel: any) => (
                                            <Grid
                                                item
                                                xs={12}
                                                sm={12}
                                                md={6}
                                                lg={4}
                                                key={reportChannel.id}
                                            >
                                                <FormControlLabel
                                                    key={reportChannel.id}
                                                    control={
                                                        <Checkbox
                                                            size='small'
                                                            checked={isPageVisibleForReportArea(
                                                                reportChannel.id
                                                            )}
                                                            onChange={
                                                                togglePageVisibilityForReportArea
                                                            }
                                                            name={
                                                                reportChannel.id
                                                            }
                                                            color='primary'
                                                            disabled={
                                                                isSavingContent
                                                            }
                                                        />
                                                    }
                                                    label={
                                                        <>
                                                            {
                                                                reportChannel.subdomain
                                                            }
                                                            <i>.tilkynna.is</i>
                                                        </>
                                                    }
                                                />
                                            </Grid>
                                        )
                                    )}
                            </Grid>
                        </FormGroup>
                    </Paper>

                    {languages
                        // .filter((l) => l.short === 'is')
                        .map((lang) => (
                            <Paper
                                key={lang.short}
                                variant='outlined'
                                className={style.contentCard}
                            >
                                <h3>{lang.title}</h3>

                                <div className={style.item}>
                                    <label
                                        htmlFor={`title_${lang.short}`}
                                        className={style.label}
                                    >
                                        {localization[language].content.title}
                                    </label>
                                    <input
                                        type='text'
                                        maxLength={300}
                                        id={`title_${lang.short}`}
                                        name={`title_${lang.short}`}
                                        value={selectedPage.title[lang.short]}
                                        onChange={(e) =>
                                            onTitleChange(
                                                e.target.value,
                                                lang.short
                                            )
                                        }
                                    />
                                </div>
                                <div className={style.item}>
                                    <label
                                        className={style.label}
                                        htmlFor={`content_${lang.short}`}
                                    >
                                        {localization[language].content.content}
                                    </label>

                                    <div
                                        className={classNames(
                                            style.organisationText
                                        )}
                                    >
                                        <MarkdownEditor
                                            initialContent={
                                                selectedPage?.content?.[
                                                    lang.short
                                                ]
                                            }
                                            onChange={(
                                                updatedContent: string
                                            ) =>
                                                onContentChange(
                                                    updatedContent,
                                                    lang.short
                                                )
                                            }
                                        />
                                    </div>
                                </div>
                            </Paper>
                        ))}
                </CardContent>
                <CardActions>
                    <Button
                        onClick={onSaveAsync}
                        variant='contained'
                        color='primary'
                        disabled={isSavingContent}
                    >
                        {localization[language].app.labelSave}
                    </Button>
                    <Button
                        onClick={() =>
                            navigate(`/app/${organisationId}/content/`)
                        }
                        variant='outlined'
                        disabled={isSavingContent}
                    >
                        {localization[language].app.labelCancel}
                    </Button>
                    {!isCreatingNewPage && (
                        <Button
                            style={{ marginLeft: 'auto' }}
                            onClick={() => setShowDeleteDialog(true)}
                            variant='outlined'
                            disabled={isSavingContent}
                            color='secondary'
                        >
                            {localization[language].app.labelDelete}
                        </Button>
                    )}
                </CardActions>
                <Dialog
                    open={showDeleteDialog}
                    onClose={() => setShowDeleteDialog(false)}
                    aria-labelledby='alert-dialog-title'
                    aria-describedby='alert-dialog-description'
                >
                    <DialogTitle id='alert-dialog-title'>
                        {localization[language].content.confirmDelete}
                    </DialogTitle>
                    <DialogContent>
                        <CircularProgress
                            style={{ opacity: isSavingContent ? 1 : 0 }}
                            size={30}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => setShowDeleteDialog(false)}
                            disabled={isSavingContent}
                        >
                            {localization[language].app.labelCancel}
                        </Button>
                        <Button
                            onClick={onDeleteAsync}
                            autoFocus
                            disabled={isSavingContent}
                        >
                            {localization[language].app.labelContinue}
                        </Button>
                    </DialogActions>
                </Dialog>
            </Card>
        </>
    );
};

export default React.memo(Page);
