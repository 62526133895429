import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
    Button,
    Card,
    CardContent,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormHelperText,
} from '@mui/material';
import localization from '../../../localization';
import config from '../../../config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil } from '@fortawesome/pro-light-svg-icons';
import { useAppSelector } from '../../../hooks';
import * as style from './index.module.scss';
import {
    ICreatable,
    ILanguageSelect,
    IDefaultMessage,
} from '../../../interfaces/TilkynnaInterfaces';

const languages: ILanguageSelect[] = [
    { short: 'is', title: 'Íslenska' },
    { short: 'en', title: 'English' },
];

interface Props {
    language: string;
    organisation: any;
    content: any;
    setContent: any;
}

const ReportDefaultMessages = ({
    language,
    organisation,
    content,
    setContent,
}: Props) => {
    const [isSavingContent, setIsSavingContent] = useState(false);

    const [selectedDefaultMsg, setSelectedDefaultMsg] = useState<
        (IDefaultMessage & ICreatable) | null
    >(null);
    const [showDefaultMsgDialog, setShowDefaultMsgDialog] = useState(false);

    const accessToken = useAppSelector(
        (state) => state.global.auth.accessToken
    );
    const refIsRemoved = React.useRef(false);

    useEffect(() => {
        return () => {
            refIsRemoved.current = true;
        };
    }, []);

    useEffect(() => {
        setShowDefaultMsgDialog(selectedDefaultMsg != null);
    }, [selectedDefaultMsg]);

    if (organisation == null) {
        return null;
    }

    const onDefaultMsgTitleChange = (title: string, language: string) => {
        if (selectedDefaultMsg) {
            setSelectedDefaultMsg({
                ...selectedDefaultMsg,
                title: {
                    ...selectedDefaultMsg.title,
                    [language]: title,
                },
            });
        }
    };

    const onDefaultMsgChange = (message: string, language: string) => {
        if (selectedDefaultMsg) {
            setSelectedDefaultMsg({
                ...selectedDefaultMsg,
                message: {
                    ...selectedDefaultMsg.message,
                    [language]: message,
                },
            });
        }
    };

    const createDefaultMsgClick = () => {
        const id = `msg-${new Date().getTime().toString()}`;
        setSelectedDefaultMsg({
            isNew: true,
            id,
            title: {
                is: '',
                en: '',
            },
            message: {
                is: '',
                en: '',
            },
        });
    };

    const deleteDefaultMsgAsync = async () => {
        if (selectedDefaultMsg === null) {
            return;
        }

        let updatedDefaultMessages = [
            ...content.reportDefaultMessages.filter(
                (c: any) => c.id !== selectedDefaultMsg.id
            ),
        ];

        try {
            setIsSavingContent(true);
            await axios.delete(
                `${config.apiBaseUrl}/organisation/${organisation.id}/content/defaultmessages/${selectedDefaultMsg.id}`,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            );

            setContent({
                ...content,
                reportDefaultMessages: updatedDefaultMessages,
            });
        } catch (err) {
            console.log(err);
        }
        setIsSavingContent(false);
        setSelectedDefaultMsg(null);
    };

    const onSaveAsync = async () => {
        if (selectedDefaultMsg === null) {
            return;
        }

        let updatedDefaultMessages;

        setIsSavingContent(true);

        if (selectedDefaultMsg.isNew) {
            updatedDefaultMessages = [
                ...content.reportDefaultMessages,
                {
                    ...selectedDefaultMsg,
                    isNew: false,
                },
            ];

            setContent({
                ...content,
                reportDefaultMessages: updatedDefaultMessages,
            });
        } else {
            updatedDefaultMessages = [...content.reportDefaultMessages];

            for (let i = 0; i < updatedDefaultMessages.length; i++) {
                if (updatedDefaultMessages[i].id === selectedDefaultMsg.id) {
                    updatedDefaultMessages[i] = {
                        ...selectedDefaultMsg,
                    };
                    break;
                }
            }
        }

        try {
            await axios.post(
                `${config.apiBaseUrl}/organisation/${organisation.id}/content/defaultmessages`,
                {
                    ...selectedDefaultMsg,
                },
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            );
            setContent({
                ...content,
                reportDefaultMessages: updatedDefaultMessages,
            });
        } catch (err) {
            console.log(err);
        }
        setIsSavingContent(false);
        setSelectedDefaultMsg(null);
    };

    const onCloseDefaultMsgDialog = () => {
        setSelectedDefaultMsg(null);
    };

    const isValidMessage =
        selectedDefaultMsg != null &&
        (selectedDefaultMsg.title.is.length > 0 ||
            selectedDefaultMsg.title.en.length > 0) &&
        (selectedDefaultMsg.message.is.length > 0 ||
            selectedDefaultMsg.message.en.length > 0);

    return (
        <Card className={style.item} variant='outlined'>
            <CardContent className={style.subCardContent}>
                <h3>{localization[language].app.defaultMessages}</h3>
                <FormHelperText>
                    {localization[language].app.defaultMessagesInfo}
                </FormHelperText>

                {content?.reportDefaultMessages != null ? (
                    <ul className={style.reportDefaultMessages}>
                        {content.reportDefaultMessages.map((cat: any) => (
                            <li
                                key={cat.id}
                                onClick={() => setSelectedDefaultMsg(cat)}
                                title={
                                    localization[language].app
                                        .editDefaultMessage +
                                        ' ' +
                                        cat.title.is || cat.title.en
                                }
                            >
                                <button
                                    type='button'
                                    onClick={() => setSelectedDefaultMsg(cat)}
                                >
                                    <FontAwesomeIcon
                                        icon={faPencil}
                                        fixedWidth
                                    />
                                </button>
                                <span>{cat.title.is || cat.title.en}</span>
                            </li>
                        ))}
                    </ul>
                ) : (
                    <CircularProgress />
                )}

                <Button
                    color='primary'
                    variant='outlined'
                    size='small'
                    onClick={createDefaultMsgClick}
                >
                    {localization[language].app.createDefaultMessage}
                </Button>
            </CardContent>

            <Dialog
                className={style.dialog}
                open={showDefaultMsgDialog}
                onClose={onCloseDefaultMsgDialog}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
            >
                <DialogTitle id='alert-dialog-title'>
                    {selectedDefaultMsg != null && selectedDefaultMsg.isNew
                        ? localization[language].app.createDefaultMessage
                        : localization[language].app.editDefaultMessage}
                </DialogTitle>
                {selectedDefaultMsg != null && (
                    <DialogContent className={style.labelDialogContent}>
                        <h3>{localization[language].app.titleOrDescription}</h3>
                        <Card
                            variant='outlined'
                            style={{ marginBottom: '1em' }}
                        >
                            <CardContent>
                                {languages.map((lang) => (
                                    <React.Fragment key={lang.short}>
                                        <label
                                            htmlFor={`title_${lang.short}`}
                                            className={style.label}
                                        >
                                            {lang.title}
                                        </label>
                                        <input
                                            disabled={isSavingContent}
                                            autoFocus={lang.short === 'is'}
                                            type='text'
                                            maxLength={200}
                                            id={`title_${lang.short}`}
                                            name={`title_${lang.short}`}
                                            value={
                                                selectedDefaultMsg.title[
                                                    lang.short
                                                ]
                                            }
                                            onChange={(e: any) =>
                                                onDefaultMsgTitleChange(
                                                    e.target.value,
                                                    lang.short
                                                )
                                            }
                                        />
                                    </React.Fragment>
                                ))}
                                <FormHelperText>
                                    {
                                        localization[language].app
                                            .defaultMessageTitleInfo
                                    }
                                </FormHelperText>
                            </CardContent>
                        </Card>
                        <h3>{localization[language].app.defaultMessages}</h3>
                        <Card
                            variant='outlined'
                            style={{ marginBottom: '1em' }}
                        >
                            <CardContent>
                                {languages.map((lang) => (
                                    <React.Fragment key={lang.short}>
                                        <label
                                            htmlFor={`title_${lang.short}`}
                                            className={style.label}
                                        >
                                            {lang.title}
                                        </label>
                                        <textarea
                                            disabled={isSavingContent}
                                            maxLength={200}
                                            id={`title_${lang.short}`}
                                            name={`title_${lang.short}`}
                                            value={
                                                selectedDefaultMsg.message[
                                                    lang.short
                                                ]
                                            }
                                            onChange={(e) =>
                                                onDefaultMsgChange(
                                                    e.target.value,
                                                    lang.short
                                                )
                                            }
                                        />
                                    </React.Fragment>
                                ))}
                                <FormHelperText>
                                    {
                                        localization[language].app
                                            .createDefaultMessageHelp
                                    }
                                </FormHelperText>
                            </CardContent>
                        </Card>
                    </DialogContent>
                )}
                {selectedDefaultMsg != null && !selectedDefaultMsg.isNew && (
                    <DialogActions>
                        <Button
                            color='secondary'
                            size='small'
                            onClick={deleteDefaultMsgAsync}
                            disabled={isSavingContent}
                        >
                            {localization[language].app.deleteDefaultMessage}
                        </Button>
                    </DialogActions>
                )}
                <DialogActions>
                    <Button
                        onClick={onSaveAsync}
                        color='primary'
                        variant='contained'
                        disabled={isSavingContent || !isValidMessage}
                    >
                        {localization[language].app.labelSave}
                    </Button>
                    <Button
                        onClick={onCloseDefaultMsgDialog}
                        variant='outlined'
                        disabled={isSavingContent}
                    >
                        {localization[language].app.labelCancel}
                    </Button>
                </DialogActions>
            </Dialog>
        </Card>
    );
};

export default React.memo(ReportDefaultMessages);
