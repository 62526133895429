import React from 'react';
import axios from 'axios';
import {
    faBell,
    faUserCheck,
    faUserShield,
} from '@fortawesome/pro-solid-svg-icons';
import { navigate } from 'gatsby-link';
import { faPaperclip } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    Button,
    Card,
    CardContent,
    Chip,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
} from '@mui/material';
import config from '../../../config';
import localization from '../../../localization';
import * as style from './index.module.scss';

import DownloadBtn from '../DownloadBtn';
import ReportActions from './ReportActions';
import DeletePersonalData from './DeletePersonalData';
import { Content } from '../../../interfaces/Content';
import {
    Done,
    Email,
    HourglassBottom,
    MarkEmailRead,
} from '@mui/icons-material';

interface Props {
    language: string;
    organisation: any;
    accessToken: string;
    content: Content;
    reports: any;
    report: any;
    setReports: any;
    setReport: any;
    setRefreshClosedReports: any;
    setRefreshReports: any;
    setRefreshRecords: any;
    messages: any;
    setMessages: any;
    comments: any;
    isFetchingReport: boolean;
    users: any;
    canManageReports: boolean;
    organisationId: string;
}

const ReportDetails = ({
    language,
    organisation,
    accessToken,
    content,
    reports,
    report,
    setReports,
    setReport,
    setRefreshClosedReports,
    setRefreshReports,
    setRefreshRecords,
    messages,
    setMessages,
    comments,
    isFetchingReport,
    users,
    canManageReports,
    organisationId,
}: Props) => {
    const [isPersonalDetailsDialogOpen, setIsPersonalDetailsDialogOpen] =
        React.useState(false);
    const [isErasePersonalDataDialogOpen, setIsErasePersonalDataDialogOpen] =
        React.useState(false);
    const [isCloseDialogOpen, setIsCloseDialogOpen] = React.useState(false);
    const [isSendingMessage, setIsSendingMessage] = React.useState(false);
    const [personalDetails, setPersonalDetails] = React.useState<any>(null);
    const [reason, setReason] = React.useState('');
    const [reasonForClosing, setReasonForClosing] = React.useState<string>('');
    const [isFetchingPersonalDetails, setIsFetchingPersonalDetails] =
        React.useState(false);

    const getPersonalDetailsAsync = async () => {
        try {
            setIsFetchingPersonalDetails(true);
            let res = await axios.post(
                `${config.apiBaseUrl}/organisation/${organisation.id}/report/${report.id}/personaldetails`,
                {
                    reason,
                },
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            );

            setPersonalDetails(res.data);
        } catch (err) {
            console.log(err);
        }

        setIsFetchingPersonalDetails(false);
    };

    const sendMessageAsync = async () => {
        setIsSendingMessage(true);
        // setIsErrorSendingMessage(false)

        let messageAdded = null;
        let hasError = false;
        try {
            const response = await axios.post(
                `${config.apiBaseUrl}/organisation/${organisation.id}/report/${report.id}/message`,
                {
                    message: reasonForClosing,
                },
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            );
            messageAdded = response.data;
            // setMessageSent(true)
            setReasonForClosing('');
            // setShowWriteNewMessage(false)
        } catch (err) {
            hasError = true;
        }
        setIsSendingMessage(false);

        // if (hasError) {
        //     setIsErrorSendingMessage(true)
        // }

        if (messageAdded != null) {
            setMessages([...messages, messageAdded]);
        }

        if (isCloseDialogOpen) {
            setIsCloseDialogOpen(false);
        }
    };

    React.useEffect(() => {
        if (!isCloseDialogOpen) {
            setReasonForClosing('');
        }
    }, [isCloseDialogOpen]);

    if (!isFetchingReport && report == null) {
        return (
            <div>
                <CircularProgress />
            </div>
        );
    }

    const renderReportFields = () => {
        if (isFetchingReport) {
            return <CircularProgress />;
        }
        return (
            <>
                {report?.fields != null && report.fields.length > 0 ? (
                    <>
                        {report.fields.map((field: any) => (
                            <div className={style.item} key={field.id}>
                                <h3>{field.title[language]}</h3>
                                {(field.fieldType === 'text' ||
                                    field.fieldType === 'textarea') && (
                                    <p>
                                        {field.value != null &&
                                        field.value.length > 0 ? (
                                            field.value
                                        ) : (
                                            <i>
                                                {
                                                    localization[language].form
                                                        .labelNotGiven
                                                }
                                            </i>
                                        )}
                                    </p>
                                )}
                                {field.fieldType === 'file' && (
                                    <>
                                        {(field.files == null ||
                                            field.files.length === 0) && (
                                            <p>
                                                {
                                                    localization[language].form
                                                        .labelNoAttachment
                                                }
                                            </p>
                                        )}

                                        {field.files != null &&
                                            field.files.length > 0 && (
                                                <ul>
                                                    {field.files.map(
                                                        (file: string) => (
                                                            <li key={file}>
                                                                <span>
                                                                    <FontAwesomeIcon
                                                                        icon={
                                                                            faPaperclip
                                                                        }
                                                                        fixedWidth
                                                                    />
                                                                </span>
                                                                <DownloadBtn
                                                                    file={file}
                                                                    report={
                                                                        report
                                                                    }
                                                                />
                                                            </li>
                                                        )
                                                    )}
                                                </ul>
                                            )}
                                    </>
                                )}
                                {(field.fieldType === 'radio' ||
                                    field.fieldType === 'dropdown') &&
                                    field.choices != null &&
                                    field.choices.length > 0 && (
                                        <>
                                            <p>
                                                {field.choices.filter(
                                                    (choice: any) =>
                                                        choice.isSelected ===
                                                        true
                                                )[0] != null
                                                    ? field.choices.filter(
                                                          (choice: any) =>
                                                              choice.isSelected ===
                                                              true
                                                      )[0].title[language]
                                                    : null}
                                            </p>
                                        </>
                                    )}
                                {field.fieldType === 'checkboxes' &&
                                    field.choices != null &&
                                    field.choices.length > 0 && (
                                        <ul>
                                            {field.choices
                                                .filter(
                                                    (choice: any) =>
                                                        choice.isSelected ===
                                                        true
                                                )
                                                .map((choice: any) => (
                                                    <li key={choice.id}>
                                                        {choice.title[language]}
                                                    </li>
                                                ))}
                                        </ul>
                                    )}
                            </div>
                        ))}
                    </>
                ) : (
                    <>
                        <p>{localization[language].app.reportFormEmpty}</p>
                    </>
                )}

                <div className={style.item}>
                    <h3>{localization[language].app.labelNotifier}</h3>
                    {report?.notifierMethod === '0' && (
                        <p>{localization[language].app.isAnonymous}</p>
                    )}

                    {report?.notifierMethod === '1' && (
                        <>
                            {report.isVerified && (
                                <p className={style.verified}>
                                    <FontAwesomeIcon
                                        fixedWidth
                                        icon={faUserCheck}
                                    />{' '}
                                    <i>
                                        {
                                            localization[language].icelandauth
                                                .identified
                                        }
                                    </i>
                                </p>
                            )}
                            {report.anonymity && (
                                <p className={style.anonymity}>
                                    <FontAwesomeIcon
                                        fixedWidth
                                        icon={faUserShield}
                                    />
                                    <i>
                                        {' '}
                                        {
                                            localization[language].notifier
                                                .wantsAnonymity
                                        }
                                    </i>
                                </p>
                            )}
                            {report.notifierAllowEmailNotifications && (
                                <p className={style.anonymity}>
                                    <FontAwesomeIcon fixedWidth icon={faBell} />
                                    <i>
                                        {' '}
                                        {
                                            localization[language].app
                                                .receivesEmailNotifications
                                        }
                                    </i>
                                </p>
                            )}
                            <Button
                                variant='outlined'
                                size='small'
                                onClick={() =>
                                    setIsPersonalDetailsDialogOpen(true)
                                }
                            >
                                {localization[language].app.viewPersonalDetails}
                            </Button>
                        </>
                    )}
                </div>
                {report?.isManuallyCreated && (
                    <div className={style.item}>
                        <h3>
                            {localization[language].app.reportCreatedManually}
                        </h3>
                        <p>
                            {localization[language].app.labelCreatedBy}:{' '}
                            {report.createdByName}{' '}
                            <small>({report.createdByEmail})</small>
                        </p>
                    </div>
                )}
            </>
        );
    };

    const renderNotifierCommunication = () => {
        if (isFetchingReport) {
            return (
                <div className={style.messageInformation}>
                    <CircularProgress />
                </div>
            );
        }
        return (
            <div className={style.messageInformation}>
                {report?.hasUnreadMessage ? (
                    <Chip
                        label={
                            localization[language].app.newMessageFromNotifier
                        }
                        color='secondary'
                    />
                ) : (
                    <p>{localization[language].app.labelNoNewMessage}</p>
                )}
            </div>
        );
    };

    const renderComments = () => {
        if (isFetchingReport) {
            return (
                <div className={style.messageInformation}>
                    <CircularProgress />
                </div>
            );
        }
        return (
            <div className={style.messageInformation}>
                {report?.hasUnreadComment ? (
                    <Chip
                        style={{
                            backgroundColor: 'rgb(255, 159, 26)',
                            color: '#ffffff',
                        }}
                        label={localization[language].app.labelNewComment}
                    />
                ) : (
                    <p>{localization[language].app.noNewComment}</p>
                )}
            </div>
        );
    };

    const getStatusIcon = () => {
        if (report == null) {
            return null;
        }

        if (report.status === 'new') {
            return <Email />;
        }

        if (report.status.startsWith('open')) {
            return <MarkEmailRead />;
        }

        if (report.status.startsWith('pending')) {
            return <HourglassBottom />;
        }

        if (report.status.startsWith('closed')) {
            return <Done />;
        }

        return null;
    };

    console.log(content);

    return (
        <>
            <div className={style.reportCard}>
                <div>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={5} lg={4}>
                            <Card
                                variant='outlined'
                                className={style.reportActionsCard}
                            >
                                <CardContent>
                                    {isFetchingReport ||
                                    report == null ||
                                    content.reportLabels == null ? (
                                        <div className={style.reportActions}>
                                            <h2>
                                                {
                                                    localization[language].app
                                                        .management
                                                }
                                            </h2>
                                            <div className={style.reportName}>
                                                <CircularProgress />
                                            </div>
                                        </div>
                                    ) : (
                                        <ReportActions
                                            isFetchingReport={isFetchingReport}
                                            content={content}
                                            organisation={organisation}
                                            report={report}
                                            setReport={setReport}
                                            language={language}
                                            accessToken={accessToken}
                                            setReports={setReports}
                                            reports={reports}
                                            setRefreshClosedReports={
                                                setRefreshClosedReports
                                            }
                                            setRefreshReports={
                                                setRefreshReports
                                            }
                                            setRefreshRecords={
                                                setRefreshRecords
                                            }
                                            users={users}
                                            canManageReports={canManageReports}
                                            organisationId={organisationId}
                                            setIsCloseDialogOpen={
                                                setIsCloseDialogOpen
                                            }
                                            setIsErasePersonalDataDialogOpen={
                                                setIsErasePersonalDataDialogOpen
                                            }
                                        />
                                    )}
                                </CardContent>
                            </Card>
                            <Card
                                variant='outlined'
                                className={style.messagesCard}
                            >
                                <CardContent>
                                    <h2>
                                        {
                                            localization[language].app
                                                .chatPortalWithNotifier
                                        }{' '}
                                        <Chip
                                            label={messages.length}
                                            size='small'
                                        />
                                    </h2>

                                    {renderNotifierCommunication()}

                                    <Button
                                        variant='outlined'
                                        onClick={() => navigate(`follow-up`)}
                                    >
                                        {
                                            localization[language].app
                                                .openCommunication
                                        }
                                    </Button>
                                </CardContent>
                            </Card>
                            <Card
                                variant='outlined'
                                className={style.commentsCard}
                            >
                                <CardContent>
                                    <h2>
                                        {
                                            localization[language].app
                                                .internalComments
                                        }{' '}
                                        <Chip
                                            label={comments.length}
                                            size='small'
                                        />
                                    </h2>

                                    {renderComments()}

                                    <Button
                                        variant='outlined'
                                        onClick={() => navigate(`comments`)}
                                    >
                                        {
                                            localization[language].app
                                                .openComments
                                        }
                                    </Button>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={7} lg={8}>
                            <Card variant='outlined'>
                                <CardContent>
                                    <h2>
                                        {localization[language].app.labelReport}
                                    </h2>

                                    {renderReportFields()}
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </div>
                {/* )} */}
            </div>

            <Dialog
                open={isErasePersonalDataDialogOpen}
                onClose={() => setIsErasePersonalDataDialogOpen(false)}
                aria-labelledby='form-dialog-title'
                className={style.deletePersonalDataDialog}
                fullWidth={true}
                maxWidth='md'
            >
                <DialogTitle id='form-dialog-title'>
                    {localization[language].app.erasePersonalInformation}
                </DialogTitle>
                <DeletePersonalData
                    report={report}
                    setReport={setReport}
                    reports={reports}
                    setReports={setReports}
                    language={language}
                    accessToken={accessToken}
                    // setRefreshRecords={setRefreshRecords}
                    // canManageReports={canManageReports}
                    organisationId={organisationId}
                    setIsErasePersonalDataDialogOpen={
                        setIsErasePersonalDataDialogOpen
                    }
                />
            </Dialog>

            <Dialog
                open={isCloseDialogOpen}
                onClose={() => setIsCloseDialogOpen(false)}
                aria-labelledby='form-dialog-title'
            >
                <DialogTitle id='form-dialog-title'>
                    {localization[language].activity.REPORT_CLOSED}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {
                            localization[language].app
                                .optionalReasonForClosingReport
                        }
                    </DialogContentText>
                    <textarea
                        style={{
                            width: '100%',
                            height: 100,
                            padding: 10,
                            fontSize: '1em',
                        }}
                        value={reasonForClosing}
                        onChange={(e) => setReasonForClosing(e.target.value)}
                        disabled={isSendingMessage}
                        autoFocus
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setIsCloseDialogOpen(false)}>
                        {localization[language].app.closeDialog}
                    </Button>
                    <Button
                        onClick={sendMessageAsync}
                        color='primary'
                        disabled={
                            reasonForClosing.length < 2 || isSendingMessage
                        }
                    >
                        {localization[language].app.labelSubmitMessage}
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={isPersonalDetailsDialogOpen}
                onClose={() => setIsPersonalDetailsDialogOpen(false)}
                aria-labelledby='form-dialog-title'
            >
                <DialogTitle id='form-dialog-title'>
                    {localization[language].app.viewPersonalDetails}
                </DialogTitle>
                <DialogContent>
                    {personalDetails != null ? (
                        <>
                            <p>
                                <strong>
                                    {localization[language].form.labelName}:
                                </strong>{' '}
                                {personalDetails.name.length > 0
                                    ? personalDetails.name
                                    : localization[language].form.labelNotGiven}
                            </p>
                            {(content.reportSettings.askForPersonalIdNumber ||
                                (personalDetails.personalIdNumber != null &&
                                    personalDetails.personalIdNumber.length >
                                        0)) && (
                                <p>
                                    <strong>
                                        {
                                            localization[language].form
                                                .labelPersonalIdNumber
                                        }
                                        :
                                    </strong>{' '}
                                    {personalDetails.personalIdNumber != null &&
                                    personalDetails.personalIdNumber.length >
                                        0 ? (
                                        personalDetails.personalIdNumber
                                    ) : (
                                        <i>
                                            {
                                                localization[language].form
                                                    .labelNotGiven
                                            }
                                        </i>
                                    )}
                                </p>
                            )}
                            {(content.reportSettings.askForEmail ||
                                (personalDetails.email != null &&
                                    personalDetails.email.length > 0)) && (
                                <p>
                                    <strong>
                                        {localization[language].form.labelEmail}
                                        :
                                    </strong>{' '}
                                    {personalDetails.email != null &&
                                    personalDetails.email.length > 0 ? (
                                        personalDetails.email
                                    ) : (
                                        <i>
                                            {
                                                localization[language].form
                                                    .labelNotGiven
                                            }
                                        </i>
                                    )}
                                </p>
                            )}
                            {(content.reportSettings.askForPhoneNumber ||
                                (personalDetails.phoneNumber != null &&
                                    personalDetails.phoneNumber.length >
                                        0)) && (
                                <p>
                                    <strong>
                                        {
                                            localization[language].form
                                                .labelPhoneNumber
                                        }
                                        :
                                    </strong>{' '}
                                    {personalDetails.phoneNumber != null &&
                                    personalDetails.phoneNumber.length > 0 ? (
                                        personalDetails.phoneNumber
                                    ) : (
                                        <i>
                                            {
                                                localization[language].form
                                                    .labelNotGiven
                                            }
                                        </i>
                                    )}
                                </p>
                            )}
                        </>
                    ) : (
                        <>
                            <DialogContentText>
                                {
                                    localization[language].app
                                        .reasonForPersonalDetails
                                }
                            </DialogContentText>
                            <textarea
                                style={{
                                    width: '100%',
                                    height: 100,
                                    padding: 10,
                                    fontSize: '1em',
                                }}
                                value={reason}
                                onChange={(e) => setReason(e.target.value)}
                                disabled={isFetchingPersonalDetails}
                                autoFocus
                            />
                        </>
                    )}
                </DialogContent>
                <DialogActions>
                    {personalDetails != null ? (
                        <Button
                            onClick={() =>
                                setIsPersonalDetailsDialogOpen(false)
                            }
                            variant='contained'
                        >
                            {localization[language].app.labelClose}
                        </Button>
                    ) : (
                        <>
                            <Button
                                onClick={() =>
                                    setIsPersonalDetailsDialogOpen(false)
                                }
                            >
                                {localization[language].app.labelCancel}
                            </Button>
                            <Button
                                variant='contained'
                                onClick={getPersonalDetailsAsync}
                                disabled={isFetchingPersonalDetails}
                            >
                                {localization[language].app.labelContinue}
                            </Button>
                        </>
                    )}
                </DialogActions>
            </Dialog>
        </>
    );
};

export default React.memo(ReportDetails);
