import React from 'react';
import axios from 'axios';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    CircularProgress,
    Button,
} from '@mui/material';
import localization from '../../../localization';
import ActivityRecord from '../ActivityRecord';
import config from '../../../config';
import * as style from './index.module.scss';

let lastFetchedActivity = 0;

interface Props {
    accessToken: string;
    language: string;
    records: any[];
    setRecords: any;
    refreshRecords: any;
    setRefreshRecords: any;
    organisationId: string;
}

const Activity = ({
    accessToken,
    language,
    records,
    setRecords,
    refreshRecords,
    setRefreshRecords,
    organisationId,
}: Props) => {
    const [numRecordsToShow, setNumRecordsToShow] = React.useState(10);
    const [isFetching, setIsFetching] = React.useState(false);
    const [isFetchingMore, setIsFetchingMore] = React.useState(false);
    const [nextKey, setNextKey] = React.useState(null);

    const onFetchMore = async () => {
        if (nextKey == null || organisationId == null) {
            return;
        }
        setIsFetchingMore(true);
        console.log(nextKey);

        try {
            const response = await axios.post(
                `${config.apiBaseUrl}/organisation/${organisationId}/activity/offset`,
                {
                    startKey: nextKey,
                },
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            );

            setRecords([...records, ...response.data.records]);
            setNextKey(response.data.startKey);
        } catch (err) {
            console.log(err);
        }

        setIsFetchingMore(false);
    };

    React.useEffect(() => {
        if (refreshRecords && organisationId != null && accessToken != null) {
            const getRecordsAsync = async () => {
                let tempNextKey = null;
                setIsFetching(true);
                try {
                    const response = await axios.get(
                        `${config.apiBaseUrl}/organisation/${organisationId}/activity`,
                        {
                            headers: {
                                Authorization: `Bearer ${accessToken}`,
                            },
                        }
                    );

                    setRecords(response.data.records);
                    if (nextKey == null) {
                        tempNextKey = response.data.startKey;
                    }
                } catch (err) {
                    console.log(err);
                }
                setIsFetching(false);
                setRefreshRecords(false);
                setNextKey(tempNextKey);
            };

            getRecordsAsync();
        }
    }, [organisationId, accessToken, refreshRecords, nextKey]);

    React.useEffect(() => {
        if (refreshRecords) {
            lastFetchedActivity = new Date().getTime();
        }
    }, [refreshRecords]);

    React.useEffect(() => {
        const timeNow = new Date().getTime();
        if (timeNow - lastFetchedActivity > 15000) {
            // console.log('WAT')
            setRefreshRecords(true);
        }
    }, [setRefreshRecords]);

    return (
        <div className={style.activityPage}>
            <h2>{localization[language].app.labelActivity}</h2>
            <TableContainer component={Paper} variant='outlined'>
                <Table aria-label='table'>
                    <TableHead>
                        <TableRow
                            style={{
                                backgroundColor: '#eeeeee',
                            }}
                        >
                            <TableCell align='left'>
                                {localization[language].app.labelUser}
                            </TableCell>
                            <TableCell align='left'>
                                {localization[language].app.labelAction}
                            </TableCell>
                            <TableCell align='left'>
                                {localization[language].app.labelInformation}
                            </TableCell>
                            <TableCell align='left'>
                                {localization[language].app.labelReport}
                            </TableCell>
                            <TableCell align='left'>
                                {localization[language].app.labelDate}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {refreshRecords && (
                            <TableRow>
                                <TableCell>
                                    <CircularProgress />
                                </TableCell>
                            </TableRow>
                        )}
                        {records.map((r, i) => (
                            <ActivityRecord
                                record={r}
                                language={language}
                                key={r.id}
                            />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            {nextKey != null && (
                <div className={style.bottomActions}>
                    <Button
                        disabled={isFetchingMore}
                        variant='outlined'
                        color='primary'
                        onClick={onFetchMore}
                    >
                        {localization[language].app.fetchMoreRecords}
                    </Button>
                </div>
            )}
        </div>
    );
};

export default React.memo(Activity);
