import React from 'react';
import axios from 'axios';
import classNames from 'classnames';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Button } from '@mui/material';
import localization from '../../../localization';
import * as style from './index.module.scss';
import config from '../../../config';

import { navigate } from 'gatsby-link';
import { useAppSelector } from '../../../hooks';

interface Props {
    language: string;
    organisationId: string;
    content: any;
    setContent: any;
}

const ListGroups = ({
    language,
    organisationId,
    content,
    setContent,
}: Props) => {
    const accessToken = useAppSelector(
        (state) => state.global.auth.accessToken
    );

    const languages = [
        { short: 'is', title: 'Íslenska' },
        { short: 'en', title: 'English' },
    ];
    const refIsRemoved = React.useRef(false);

    const [isSavingContent, setIsSavingContent] = React.useState(false);

    React.useEffect(() => {
        return () => {
            refIsRemoved.current = true;
        };
    }, []);

    const onDragEndHandler = async (res) => {
        if (
            !res ||
            !res.destination ||
            res.destination.index === res.source.index
        ) {
            return;
        }
        let updatedGroups = [...content.groups];

        const [removed] = updatedGroups.splice(res.source.index, 1);
        updatedGroups.splice(res.destination.index, 0, removed);

        setContent({
            ...content,
            groups: updatedGroups,
        });

        try {
            setIsSavingContent(true);
            await axios.post(
                `${config.apiBaseUrl}/organisation/${organisationId}/content/groups/changeorder/${res.source.index}/${res.destination.index}`,
                null,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            );
        } catch (err) {
            console.log(err);
        }
        setIsSavingContent(false);
    };

    return (
        <>
            <h3>{localization[language].formEditor.reportForm}</h3>
            {content != null &&
                content.groups != null &&
                content.groups.length > 0 && (
                    <>
                        <DragDropContext onDragEnd={onDragEndHandler}>
                            <Droppable droppableId='droppable'>
                                {(provided, snapshot) => (
                                    <div
                                        className={style.itemList}
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                    >
                                        {/* <FormHelperText
                                                style={{ marginBottom: 10 }}
                                            >
                                                {localization[language].formEditor.formDragAndDropHint}
                                            </FormHelperText> */}
                                        {content.groups.map(
                                            (group: any, index: number) => (
                                                <Draggable
                                                    key={group.id}
                                                    draggableId={group.id}
                                                    index={index}
                                                    isDragDisabled={
                                                        isSavingContent
                                                    }
                                                >
                                                    {(provided, snapshot) => (
                                                        <div
                                                            className={classNames(
                                                                style.item,
                                                                snapshot.isDragging &&
                                                                    style.isDragging
                                                            )}
                                                            ref={
                                                                provided.innerRef
                                                            }
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            style={{
                                                                ...provided
                                                                    .draggableProps
                                                                    .style,
                                                            }}
                                                        >
                                                            {languages.map(
                                                                (l, i) => (
                                                                    <div
                                                                        key={
                                                                            l.short
                                                                        }
                                                                        className={
                                                                            style.titles
                                                                        }
                                                                    >
                                                                        <h4>
                                                                            <strong>
                                                                                {
                                                                                    l.short
                                                                                }
                                                                            </strong>{' '}
                                                                            {
                                                                                group
                                                                                    .title[
                                                                                    l
                                                                                        .short
                                                                                ]
                                                                            }
                                                                        </h4>
                                                                    </div>
                                                                )
                                                            )}

                                                            <div
                                                                className={
                                                                    style.formFieldItemActions
                                                                }
                                                            >
                                                                <Button
                                                                    variant='outlined'
                                                                    size='small'
                                                                    onClick={() =>
                                                                        navigate(
                                                                            `${content.groups[index].id}`
                                                                        )
                                                                    }
                                                                >
                                                                    {
                                                                        localization[
                                                                            language
                                                                        ].app
                                                                            .labelEdit
                                                                    }
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Draggable>
                                            )
                                        )}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </>
                )}
        </>
    );
};

export default React.memo(ListGroups);
