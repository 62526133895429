// extracted by mini-css-extract-plugin
export var actions = "index-module--actions--puiRy";
export var active = "index-module--active--gv21g";
export var anonymity = "index-module--anonymity--DYayl";
export var anonymousPortalCreated = "index-module--anonymousPortalCreated--6aHl8";
export var assignedLabel = "index-module--assignedLabel--U2wFe";
export var assignedLabelList = "index-module--assignedLabelList--GB7TK";
export var assignedLabels = "index-module--assignedLabels--EVUGQ";
export var assignedUser = "index-module--assignedUser--JMAl8";
export var assignedUserList = "index-module--assignedUserList--n2IUk";
export var assignedUsers = "index-module--assignedUsers--iApue";
export var attachments = "index-module--attachments--jW9GS";
export var badges = "index-module--badges--A5NOr";
export var bottomPageActions = "index-module--bottomPageActions--s1DcJ";
export var btnEdit = "index-module--btnEdit--AhWgg";
export var commentActions = "index-module--commentActions--i4RxC";
export var commentSectionActions = "index-module--commentSectionActions--H6l3L";
export var commentsCard = "index-module--commentsCard--KXMFp";
export var commentsPage = "index-module--commentsPage--2+BF-";
export var defaultMessagesContainer = "index-module--defaultMessagesContainer--Hl6sG";
export var deletePersonalDataDialog = "index-module--deletePersonalDataDialog--BlzVe";
export var error = "index-module--error--DOvdJ";
export var followUpCard = "index-module--followUpCard--ZMf0-";
export var formActions = "index-module--formActions--Ubgqw";
export var hasError = "index-module--hasError--BRzdg";
export var header = "index-module--header--w7ubo";
export var invitationCard = "index-module--invitationCard--fenyB";
export var inviteCard = "index-module--inviteCard--pckHv";
export var isFromOrganisation = "index-module--isFromOrganisation--cB9zP";
export var isMe = "index-module--isMe--LquZx";
export var item = "index-module--item--GPnJt";
export var label = "index-module--label--5BjRY";
export var linksPage = "index-module--linksPage--b8f6g";
export var message = "index-module--message--qav0H";
export var messageActions = "index-module--messageActions--AiiXM";
export var messageForm = "index-module--messageForm--wpCdb";
export var messageInformation = "index-module--messageInformation--LJJZQ";
export var messages = "index-module--messages--fw+Ea";
export var messagesCard = "index-module--messagesCard--BgDsJ";
export var permissionItem = "index-module--permissionItem--oIL+1";
export var personalInformation = "index-module--personalInformation--nedf4";
export var reportActions = "index-module--reportActions--p1lJl";
export var reportActionsCard = "index-module--reportActionsCard--coazc";
export var reportArea = "index-module--reportArea--2jBrG";
export var reportCard = "index-module--reportCard--5Cklb";
export var reportName = "index-module--reportName--fkiyM";
export var reportNameActions = "index-module--reportNameActions--Aa8Oc";
export var reportPage = "index-module--reportPage--6CgYl";
export var reportRow = "index-module--reportRow--gVY84";
export var reportSharingCard = "index-module--reportSharingCard--QxiAe";
export var reportStatus = "index-module--reportStatus--Q2rFK";
export var reportsPage = "index-module--reportsPage--92gfV";
export var reviewPage = "index-module--reviewPage--Hoscb";
export var role = "index-module--role--i+KOs";
export var roles = "index-module--roles--fb1vI";
export var searchForm = "index-module--searchForm--YVKWG";
export var separator = "index-module--separator--3IJm6";
export var tableCard = "index-module--tableCard--PV3iY";
export var tableHeader = "index-module--tableHeader--ELAog";
export var tableHeaderContent = "index-module--tableHeaderContent--X48zy";
export var tooltipIcon = "index-module--tooltipIcon--gAQmy";
export var verified = "index-module--verified--aNsJ8";