import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
    Button,
    Card,
    CardContent,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormHelperText,
} from '@mui/material';
import localization from '../../../localization';
import config from '../../../config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faPencil } from '@fortawesome/pro-light-svg-icons';
import { useAppSelector } from '../../../hooks';
import * as style from './index.module.scss';
import {
    ICreatable,
    ILabel,
    ILanguageSelect,
} from '../../../interfaces/TilkynnaInterfaces';

const languages: ILanguageSelect[] = [
    { short: 'is', title: 'Íslenska' },
    { short: 'en', title: 'English' },
];

const colors = [
    '#61bd4f',
    '#f2d600',
    '#ff9f1a',
    '#eb5a46',
    '#c377e0',
    '#0079bf',
    '#00c2e0',
    '#51e898',
    '#ff78cb',
    '#344563',
];

interface Props {
    language: string;
    organisation: any;
    content: any;
    setContent: any;
}

const ReportLabels = ({
    language,
    organisation,
    content,
    setContent,
}: Props) => {
    const [isSavingContent, setIsSavingContent] = useState(false);
    const [selectedLabel, setSelectedLabel] = useState<
        (ILabel & ICreatable) | null
    >(null);
    const [showLabelDialog, setShowLabelDialog] = useState(false);

    const accessToken = useAppSelector(
        (state) => state.global.auth.accessToken
    );
    const refIsRemoved = React.useRef(false);

    useEffect(() => {
        return () => {
            refIsRemoved.current = true;
        };
    }, []);

    useEffect(() => {
        setShowLabelDialog(selectedLabel != null);
    }, [selectedLabel]);

    if (organisation == null) {
        return null;
    }

    const createLabelClick = () => {
        const id = `label-${new Date().getTime().toString()}`;
        setSelectedLabel({
            isNew: true,
            id,
            color: '#61bd4f',
            title: {
                is: '',
                en: '',
            },
        });
    };

    const onLabelTitleChange = (title: string, language: string) => {
        if (selectedLabel) {
            setSelectedLabel({
                ...selectedLabel,
                title: {
                    ...selectedLabel.title,
                    [language]: title,
                },
            });
        }
    };

    const setLabelColor = (color: string) => {
        if (selectedLabel) {
            setSelectedLabel({
                ...selectedLabel,
                color,
            });
        }
    };

    const deleteLabelAsync = async () => {
        if (selectedLabel === null) {
            return;
        }

        let updatedLabels = [
            ...content.reportLabels.filter((c) => c.id !== selectedLabel.id),
        ];

        try {
            setIsSavingContent(true);
            await axios.delete(
                `${config.apiBaseUrl}/organisation/${organisation.id}/content/labels/${selectedLabel.id}`,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            );

            setContent({
                ...content,
                reportLabels: updatedLabels,
            });
        } catch (err) {
            console.log(err);
        }
        setIsSavingContent(false);
        setSelectedLabel(null);
    };

    const onSaveLabelAsync = async () => {
        if (selectedLabel === null) {
            return;
        }

        let updatedLabels;

        setIsSavingContent(true);

        if (selectedLabel.isNew) {
            updatedLabels = [
                ...content.reportLabels,
                {
                    ...selectedLabel,
                    isNew: false,
                },
            ];

            setContent({
                ...content,
                reportLabels: updatedLabels,
            });
        } else {
            updatedLabels = [...content.reportLabels];

            for (let i = 0; i < updatedLabels.length; i++) {
                if (updatedLabels[i].id === selectedLabel.id) {
                    updatedLabels[i] = {
                        ...selectedLabel,
                    };
                    break;
                }
            }
        }

        try {
            await axios.post(
                `${config.apiBaseUrl}/organisation/${organisation.id}/content/labels`,
                {
                    ...selectedLabel,
                },
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            );
            setContent({
                ...content,
                reportLabels: updatedLabels,
            });
        } catch (err) {
            console.log(err);
        }
        setIsSavingContent(false);
        setSelectedLabel(null);
    };

    const onCloseLabelDialog = () => {
        setSelectedLabel(null);
    };

    const isValidReportLabel =
        selectedLabel != null &&
        (selectedLabel.title.is.length > 0 ||
            selectedLabel.title.en.length > 0);

    return (
        <Card className={style.item} variant='outlined'>
            <CardContent className={style.subCardContent}>
                <h3>{localization[language].app.labels}</h3>
                <FormHelperText>
                    {localization[language].app.labelsInfo}
                </FormHelperText>

                {content?.reportLabels != null ? (
                    <ul className={style.reportLabels}>
                        {content.reportLabels.map((cat: any) => (
                            <li
                                key={cat.id}
                                onClick={() => setSelectedLabel(cat)}
                                title={cat.title.is || cat.title.en}
                            >
                                <button
                                    type='button'
                                    onClick={() => setSelectedLabel(cat)}
                                >
                                    <FontAwesomeIcon
                                        icon={faPencil}
                                        fixedWidth
                                    />
                                </button>
                                <span style={{ backgroundColor: cat.color }}>
                                    {cat.title.is || cat.title.en}
                                </span>
                            </li>
                        ))}
                    </ul>
                ) : (
                    <CircularProgress />
                )}

                <Button
                    color='primary'
                    variant='outlined'
                    size='small'
                    onClick={createLabelClick}
                >
                    {localization[language].app.createLabel}
                </Button>
            </CardContent>

            <Dialog
                className={style.dialog}
                open={showLabelDialog}
                onClose={onCloseLabelDialog}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
            >
                <DialogTitle id='alert-dialog-title'>
                    {selectedLabel != null && selectedLabel.isNew
                        ? localization[language].app.createLabel
                        : localization[language].app.editLabel}
                </DialogTitle>
                {selectedLabel != null && (
                    <DialogContent className={style.labelDialogContent}>
                        <h3>{localization[language].content.title}</h3>
                        <Card
                            variant='outlined'
                            style={{ marginBottom: '1em' }}
                        >
                            <CardContent>
                                {languages.map((lang) => (
                                    <React.Fragment key={lang.short}>
                                        <label
                                            htmlFor={`title_${lang.short}`}
                                            className={style.label}
                                        >
                                            {lang.title}
                                        </label>
                                        <input
                                            disabled={isSavingContent}
                                            // ref={lang.short === 'is' ? choiceTitleRef : null}
                                            autoFocus={lang.short === 'is'}
                                            type='text'
                                            maxLength={200}
                                            id={`title_${lang.short}`}
                                            name={`title_${lang.short}`}
                                            value={
                                                selectedLabel.title[lang.short]
                                            }
                                            onChange={(e) =>
                                                onLabelTitleChange(
                                                    e.target.value,
                                                    lang.short
                                                )
                                            }
                                        />
                                    </React.Fragment>
                                ))}
                                <FormHelperText>
                                    {
                                        localization[language].app
                                            .localizationNotRequiredHelp
                                    }
                                </FormHelperText>
                            </CardContent>
                        </Card>
                        <h3>{localization[language].app.selectColor}</h3>
                        <Card variant='outlined'>
                            <CardContent>
                                {colors.map((color) => (
                                    <button
                                        disabled={isSavingContent}
                                        key={color}
                                        type='button'
                                        className={style.color}
                                        style={{ backgroundColor: color }}
                                        onClick={() => setLabelColor(color)}
                                    >
                                        {selectedLabel.color === color && (
                                            <FontAwesomeIcon
                                                icon={faCheck}
                                                fixedWidth
                                            />
                                        )}
                                    </button>
                                ))}
                                {/* <div>
                                        <h4>{localization[language].app.noColor}</h4>
                                        <button
                                            type="button"
                                            className={style.color}
                                            onClick={() => setLabelColor('none')}
                                        >
                                            {selectedLabel.color === 'none' && (
                                                <FontAwesomeIcon icon={faCheck} fixedWidth />
                                            )}
                                        </button>
                                    </div> */}
                            </CardContent>
                        </Card>
                    </DialogContent>
                )}
                {selectedLabel != null && !selectedLabel.isNew && (
                    <DialogActions>
                        <Button
                            color='secondary'
                            size='small'
                            onClick={deleteLabelAsync}
                            disabled={isSavingContent}
                        >
                            {localization[language].app.deleteLabel}
                        </Button>
                    </DialogActions>
                )}
                <DialogActions>
                    <Button
                        onClick={onSaveLabelAsync}
                        color='primary'
                        variant='contained'
                        disabled={isSavingContent || !isValidReportLabel}
                    >
                        {localization[language].app.labelSave}
                    </Button>
                    <Button
                        onClick={onCloseLabelDialog}
                        variant='outlined'
                        disabled={isSavingContent}
                    >
                        {localization[language].app.labelCancel}
                    </Button>
                </DialogActions>
            </Dialog>
        </Card>
    );
};

export default React.memo(ReportLabels);
