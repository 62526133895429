import React, { useCallback } from 'react';
import axios from 'axios';
import { Link, navigate } from 'gatsby';

import config from '../../../config';
import localization from '../../../localization';
import ReportForm from '../../Report/ReportForm';
import { Button, Card, CardActions, CardContent } from '@mui/material';
import NotifierForm from '../../Report/NotifierForm';
import { faSpinnerThird } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as style from './index.module.scss';

interface Props {
    organisation: any;
    content: any;
    language: string;
    accessToken: string;
    reports: any;
    setReports: any;
    organisationId: string;
}

const NewReport = ({
    organisation,
    content,
    language,
    accessToken,
    reports,
    setReports,
    organisationId,
}: Props) => {
    // Complete state
    const [isSubmitted, setIsSubmitted] = React.useState(false);
    const [formResult, setFormResult] = React.useState<any>(null);
    const [isSending, setIsSending] = React.useState(false);
    const [notifierMethod, setNotifierMethod] = React.useState('1');
    const [notifierEmail, setNotifierEmail] = React.useState('');
    const [notifierPhoneNumber, setNotifierPhoneNumber] = React.useState('');
    const [notifierName, setNotifierName] = React.useState('');
    const [notifierPersonalIdNumber, setNotifierPersonalIdNumber] =
        React.useState('');
    const [anonymity, setAnonymity] = React.useState(true);

    const [fields, setFields] = React.useState<any>(null);

    const hasCustomFields =
        content != null && content.groups != null && content.groups.length > 0;

    const customFields = hasCustomFields ? content.groups[0].formFields : null;

    const isValidNotifier =
        notifierMethod !== '1' ||
        (notifierMethod === '1' && notifierName.length > 2);

    const isValidEmail =
        notifierMethod !== '1' ||
        notifierEmail.length === 0 ||
        (notifierEmail.length > 0 && notifierEmail.indexOf('@') > 0);

    const areFieldsValid = () => {
        if (fields == null) {
            return false;
        }
        let isValid = true;
        for (let i = 0; i < fields.length; i++) {
            if (fields[i].required) {
                if (
                    fields[i].fieldType === 'text' ||
                    fields[i].fieldType === 'textarea'
                ) {
                    if (fields[i].value == null || fields[i].value === '') {
                        isValid = false;
                        break;
                    }
                }
            }
        }

        return isValid;
    };

    const renderButtonText = () => {
        if (isSending) {
            return (
                <>
                    {localization[language].form.labelSending}{' '}
                    <FontAwesomeIcon icon={faSpinnerThird} spin fixedWidth />
                </>
            );
        }
        return <>{localization[language].form.labelConfirm}</>;
    };

    const resetForm = useCallback((shouldResetFormResult) => {
        console.log('# Resetting form');
        setNotifierMethod('1');
        setNotifierName('');
        setNotifierEmail('');
        setNotifierPersonalIdNumber('');
        setAnonymity(true);
        setFields(null);

        if (shouldResetFormResult) {
            setIsSubmitted(false);
            setFormResult(null);
        }
    }, []);

    React.useEffect(() => {
        if (hasCustomFields && fields == null && customFields != null) {
            let updatedFields = [...customFields];

            for (let i = 0; i < updatedFields.length; i++) {
                if (
                    updatedFields[i].fieldType === 'file' &&
                    updatedFields[i].files == null
                ) {
                    updatedFields[i].files = [];
                } else {
                    //if (updatedFields[i].value == null) {
                    updatedFields[i].value = '';
                }
            }

            setFields(updatedFields);
        }
    }, [fields, customFields, hasCustomFields]);

    const createReportAsync = async () => {
        setIsSending(true);
        let hasError = false;
        try {
            const response = await axios.post(
                `${config.apiBaseUrl}/organisation/${organisation.id}/report`,
                {
                    notifierMethod,
                    notifierName,
                    notifierEmail,
                    notifierPersonalIdNumber,
                    notifierPhoneNumber,
                    anonymity,
                    fields,
                    isManuallyCreated: true,
                },
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            );
            setFormResult(response.data);
            setReports([response.data.report, ...reports]);
        } catch (err) {
            console.log(err);
            hasError = true;
        }

        if (!hasError) {
            setIsSubmitted(true);
            resetForm(false);
        }
        setIsSending(false);
    };

    if (isSubmitted || formResult != null) {
        return (
            <>
                <div className={style.header}>
                    <ol>
                        <li>
                            <Link to={`/app/${organisationId}/reports`}>
                                {localization[language].app.labelReports}
                            </Link>
                        </li>
                        <li aria-hidden='true' className={style.separator}>
                            /
                        </li>
                        <li className={style.active}>
                            {localization[language].app.newReport}
                        </li>
                    </ol>
                </div>

                <div className={style.reviewPage}>
                    <Card variant='outlined'>
                        <CardContent>
                            <h2>
                                {localization[language].form.labelReportSent}
                            </h2>

                            <div className={style.anonymousPortalCreated}>
                                <h3>
                                    {
                                        localization[language].form
                                            .labelAnonymousPortalCreated
                                    }
                                </h3>
                                <p>
                                    {
                                        localization[language].form
                                            .labelAnonymousPortalInfo
                                    }
                                </p>
                                <div className={style.item}>
                                    <label>
                                        {
                                            localization[language].app
                                                .labelIdentificationNumber
                                        }
                                    </label>
                                    <input
                                        type='text'
                                        readOnly
                                        value={formResult.anonymousId}
                                    />
                                </div>
                                <div className={style.item}>
                                    <label>
                                        {
                                            localization[language].password
                                                .labelPassword
                                        }
                                    </label>
                                    <input
                                        type='text'
                                        readOnly
                                        value={formResult.password}
                                    />
                                </div>
                                <h3>
                                    {
                                        localization[language].form
                                            .labelWebsiteForFollowup
                                    }
                                </h3>
                                <div>
                                    <a
                                        className={style.followUpLink}
                                        target='_blank'
                                        rel='noreferrer'
                                        href={`https://${organisation.subdomain}.tilkynna.is/${language}/report/follow-up`}
                                        title={
                                            localization[language].form
                                                .labelOpenInNewWindow
                                        }
                                    >
                                        {`https://${organisation.subdomain}.tilkynna.is/${language}/report/follow-up`}
                                    </a>
                                </div>
                                <p className={style.alert}>
                                    {
                                        localization[language].form
                                            .labelSaveAndCloseWindow
                                    }{' '}
                                    <strong>
                                        {
                                            localization[language].form
                                                .labelRecoveryNotPossible
                                        }
                                    </strong>
                                </p>
                            </div>
                        </CardContent>
                        <CardActions>
                            <Button
                                variant='outlined'
                                onClick={() =>
                                    navigate(`/app/${organisationId}/reports`)
                                }
                            >
                                {localization[language].app.backToReports}
                            </Button>
                        </CardActions>
                    </Card>
                </div>
            </>
        );
    }

    return (
        <>
            <div className={style.header}>
                <ol>
                    <li>
                        <Link to={`/app/${organisationId}/reports`}>
                            {localization[language].app.labelReports}
                        </Link>
                    </li>
                    <li aria-hidden='true' className={style.separator}>
                        /
                    </li>
                    <li className={style.active}>
                        {localization[language].app.newReport}
                    </li>
                </ol>
            </div>
            <div className={style.reportPage}>
                <h2>{localization[language].app.newReport}</h2>
                <Card variant='outlined'>
                    <CardContent>
                        {fields != null && (
                            <>
                                <NotifierForm
                                    isManualMode={true}
                                    notifierMethod={notifierMethod}
                                    setNotifierMethod={setNotifierMethod}
                                    notifierEmail={notifierEmail}
                                    setNotifierEmail={setNotifierEmail}
                                    notifierPhoneNumber={notifierPhoneNumber}
                                    setNotifierPhoneNumber={
                                        setNotifierPhoneNumber
                                    }
                                    notifierName={notifierName}
                                    setNotifierName={setNotifierName}
                                    notifierPersonalIdNumber={
                                        notifierPersonalIdNumber
                                    }
                                    setNotifierPersonalIdNumber={
                                        setNotifierPersonalIdNumber
                                    }
                                    anonymity={anonymity}
                                    setAnonymity={setAnonymity}
                                    language={language}
                                    organisation={organisation}
                                    reportSettings={content.reportSettings}
                                />
                                <ReportForm
                                    notifierMethod={notifierMethod}
                                    notifierName={notifierName}
                                    isManualMode={true}
                                    fields={fields}
                                    setFields={setFields}
                                    language={language}
                                    organisation={organisation}
                                    isSubmitted={false}
                                />
                            </>
                        )}
                        <div className={style.formActions}>
                            <button
                                type='button'
                                className='btn marginRight'
                                onClick={createReportAsync}
                                disabled={
                                    !areFieldsValid() ||
                                    !isValidEmail ||
                                    !isValidNotifier ||
                                    isSending
                                }
                            >
                                {renderButtonText()}
                            </button>
                            <button
                                type='button'
                                onClick={() =>
                                    navigate(`/${language}/report/details`)
                                }
                                className='btn outline'
                                disabled={isSending}
                            >
                                {localization[language].form.labelCancel}
                            </button>
                        </div>
                    </CardContent>
                </Card>
            </div>
        </>
    );
};

export default React.memo(NewReport);
