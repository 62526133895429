import React from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Button,
    Card,
    CardContent,
    CircularProgress,
} from '@mui/material';
import { navigate } from 'gatsby';
import localization from '../../../localization';
import SimpleDate from '../../SimpleDate';
import * as style from './index.module.scss';

const getRole = (role: string, language: string) =>
    localization[language].roles[role];

interface Props {
    language: string;
    users: any[];
    setUser: any;
    isOrgAdmin: boolean;
    isFetchingUsers: boolean;
    organisationId: string;
}

const ListUsers = ({
    setUser,
    users,
    language,
    isOrgAdmin,
    isFetchingUsers,
    organisationId,
}: Props) => {
    const openUser = (u: any) => {
        setUser(u);
        navigate(`/app/${organisationId}/users/${u.id}`);
    };
    const onInviteUser = () => {
        navigate(`/app/${organisationId}/users/invite`);
    };

    if (users == null) {
        return null;
    }

    return (
        <>
            <h2>{localization[language].app.labelManageUsers}</h2>
            <TableContainer component={Card} variant='outlined'>
                {isOrgAdmin && (
                    <CardContent>
                        <Button
                            variant='outlined'
                            onClick={onInviteUser}
                            disabled={isFetchingUsers}
                        >
                            {localization[language].invite.title}
                        </Button>
                    </CardContent>
                )}
                <Table aria-label='collapsible table'>
                    <TableHead>
                        <TableRow
                            style={{
                                backgroundColor: '#eeeeee',
                            }}
                        >
                            {isOrgAdmin && (
                                <TableCell align='left'>
                                    {localization[language].app.labelActions}
                                </TableCell>
                            )}
                            <TableCell align='left'>
                                {localization[language].app.labelName}
                            </TableCell>
                            <TableCell align='left'>
                                {localization[language].app.labelEmail}
                            </TableCell>
                            <TableCell align='left'>
                                {localization[language].app.labelRole}
                            </TableCell>
                            <TableCell align='left'>
                                {localization[language].app.labelCreated}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {users.map((user) => (
                            <TableRow key={user.id}>
                                {isOrgAdmin && (
                                    <TableCell align='left'>
                                        <Button
                                            // variant="outlined"
                                            onClick={() => openUser(user)}
                                        >
                                            {
                                                localization[language].app
                                                    .labelEdit
                                            }
                                        </Button>
                                    </TableCell>
                                )}

                                <TableCell align='left'>
                                    {user.isPendingInvitation ||
                                    user.hasAccepted === false ? (
                                        <span className={style.invited}>
                                            {
                                                localization[language].invite
                                                    .labelSent
                                            }
                                        </span>
                                    ) : (
                                        user.name
                                    )}
                                </TableCell>
                                <TableCell align='left'>{user.email}</TableCell>
                                <TableCell align='left'>
                                    {getRole(user.role, language)}
                                </TableCell>
                                <TableCell align='left'>
                                    <SimpleDate dateString={user.created} />
                                </TableCell>
                            </TableRow>
                        ))}
                        {isFetchingUsers && (
                            <TableRow>
                                <TableCell>
                                    <CircularProgress />
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};

export default React.memo(ListUsers);
