import React from 'react';
import axios from 'axios';
import { Link, navigate } from 'gatsby';
import { Router } from '@reach/router';

import config from '../../../config';
import localization from '../../../localization';
import ReportFollowUp from './ReportFollowUp';
import ReportActivity from './ReportActivity';
import ReportComments from './ReportComments';
import ReportDetails from './ReportDetails';
import ReportSharing from './ReportSharing';
import * as style from './index.module.scss';
import {
    ArrowForwardIos,
    Comment,
    Done,
    Email,
    Event,
    MarkEmailRead,
    Assignment,
    PendingActions,
} from '@mui/icons-material';
import { Chip } from '@mui/material';
import SimpleDate from '../../SimpleDate';

interface Props {
    reportId: string;
    organisation: any;
    report: any;
    setReport: any;
    content: any;
    language: string;
    accessToken: string;
    reports: any;
    setReports: any;
    setRefreshClosedReports: any;
    setRefreshReports: any;
    setRefreshRecords: any;
    refreshReports: any;
    users: any;
    canManageReports: boolean;
    organisationId: any;
}

const Report = ({
    reportId,
    organisation,
    report,
    setReport,
    content,
    language,
    accessToken,
    reports,
    setReports,
    setRefreshClosedReports,
    setRefreshReports,
    setRefreshRecords,
    refreshReports,
    users,
    canManageReports,
    organisationId,
}: Props) => {
    const [messages, setMessages] = React.useState([]);
    const [comments, setComments] = React.useState([]);
    const [isFetchingReport, setIsFetchingReport] = React.useState(true);
    const [records, setRecords] = React.useState([]);
    const [links, setLinks] = React.useState([]);

    const isRemovedRef = React.useRef(false);

    React.useEffect(() => {
        return () => {
            console.log('Setting report as null');
            setReport(null);
            isRemovedRef.current = true;
        };
    }, []);

    // console.log(assignedUser)

    const getReportAsync = async () => {
        // setIsFetchingReport(true)
        let error = null;
        try {
            const response = await axios.get(
                `${config.apiBaseUrl}/organisation/${organisationId}/report/${reportId}`,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            );
            if (!isRemovedRef.current) {
                if (response.data.comments) {
                    setComments(response.data.comments);
                }
                setMessages(response.data.messages);
                setReport({
                    ...response.data.report,
                });

                if (!response.data.report.status.startsWith('closed')) {
                    const updatedReports = [...reports];

                    let foundReport = false;

                    for (let i = 0; i < updatedReports.length; i++) {
                        if (updatedReports[i].id === response.data.report.id) {
                            updatedReports[i] = {
                                ...response.data.report,
                            };
                            foundReport = true;
                            break;
                        }
                    }
                    if (foundReport) {
                        setReports(updatedReports);
                    }
                }
            }
        } catch (err) {
            setReport(null);
            console.log(err);
        }
        if (!isRemovedRef.current) {
            setIsFetchingReport(false);
        }
    };

    React.useEffect(() => {
        if (report != null && report.id !== reportId) {
            setIsFetchingReport(true);
        }
    }, [report, reportId]);

    React.useEffect(() => {
        if (isFetchingReport && !refreshReports && organisation != null) {
            console.log('Get report async');
            getReportAsync();
        }
    }, [isFetchingReport, refreshReports, organisation]);

    React.useEffect(() => {
        if (!isFetchingReport && report == null) {
            console.log('Redirecting back');
            navigate(`/app/${organisationId}/reports`);
        }
    }, [report, isFetchingReport, organisationId]);

    const getStatusIcon = () => {
        if (report == null) {
            return null;
        }

        if (report.status === 'new') {
            return <Email />;
        }

        if (report.status.startsWith('open')) {
            return <MarkEmailRead />;
        }

        if (report.status.startsWith('pending')) {
            return <PendingActions />;
        }

        if (report.status.startsWith('closed')) {
            return <Done />;
        }

        return null;
    };

    return (
        <>
            <div className={style.header}>
                <ol>
                    <li>
                        <Link to={`/app/${organisationId}/reports`}>
                            {localization[language].app.labelReports}
                        </Link>
                    </li>
                    <li aria-hidden='true' className={style.separator}>
                        <ArrowForwardIos fontSize='small' />
                    </li>
                    <li className={style.active}>
                        <Link
                            to={`/app/${organisationId}/reports/id/${reportId}`}
                        >
                            {localization[language].app.labelReport} #
                            {report != null ? report.number : ''}
                        </Link>
                    </li>
                </ol>
            </div>

            <div className={style.badges}>
                <Chip
                    title={localization[language].app.labelReport}
                    icon={<Assignment />}
                    label={
                        <>
                            {localization[language].app.labelReport} #
                            {report != null ? report.number : ''}
                        </>
                    }
                />

                {!isFetchingReport && (
                    <Chip
                        title={localization[language].app.labelCreated}
                        icon={<Event />}
                        label={
                            <SimpleDate
                                customFormat='dd MMM, yyyy'
                                dateString={
                                    report != null ? report.created : null
                                }
                            />
                        }
                    />
                )}

                {report?.hasUnreadMessage && (
                    <Chip
                        icon={<Comment />}
                        label={
                            localization[language].app.newMessageFromNotifier
                        }
                    />
                )}

                {!isFetchingReport && (
                    <Chip
                        title={localization[language].app.labelStatus}
                        icon={getStatusIcon()}
                        label={
                            localization[language].reportStatus[report?.status]
                        }
                    />
                )}
            </div>

            <div className={style.reportPage}>
                <Router>
                    <ReportDetails
                        path='/'
                        default
                        language={language}
                        accessToken={accessToken}
                        content={content}
                        organisation={organisation}
                        messages={messages}
                        setMessages={setMessages}
                        comments={comments}
                        isFetchingReport={isFetchingReport}
                        report={report}
                        setReport={setReport}
                        reports={reports}
                        setReports={setReports}
                        setRefreshClosedReports={setRefreshClosedReports}
                        setRefreshReports={setRefreshReports}
                        setRefreshRecords={setRefreshRecords}
                        users={users}
                        canManageReports={canManageReports}
                        organisationId={organisationId}
                    />
                    <ReportFollowUp
                        path='/follow-up'
                        language={language}
                        accessToken={accessToken}
                        content={content}
                        organisation={organisation}
                        messages={messages}
                        setMessages={setMessages}
                        isFetchingReport={isFetchingReport}
                        report={report}
                        setReport={setReport}
                        reports={reports}
                        setReports={setReports}
                        canManageReports={canManageReports}
                        organisationId={organisationId}
                    />
                    <ReportComments
                        path='/comments'
                        language={language}
                        accessToken={accessToken}
                        organisation={organisation}
                        comments={comments}
                        setComments={setComments}
                        isFetchingReport={isFetchingReport}
                        report={report}
                        setReport={setReport}
                        reports={reports}
                        setReports={setReports}
                        links={links}
                        setLinks={setLinks}
                        canManageReports={canManageReports}
                        organisationId={organisationId}
                    />
                    <ReportActivity
                        path='/activity'
                        language={language}
                        accessToken={accessToken}
                        organisation={organisation}
                        isFetchingReport={isFetchingReport}
                        report={report}
                        records={records}
                        setRecords={setRecords}
                        canManageReports={canManageReports}
                        organisationId={organisationId}
                    />
                    <ReportSharing
                        path='/sharing'
                        language={language}
                        accessToken={accessToken}
                        // organisation={organisation}
                        isFetchingReport={isFetchingReport}
                        report={report}
                        links={links}
                        setLinks={setLinks}
                        canManageReports={canManageReports}
                        organisationId={organisationId}
                    />
                </Router>
            </div>
        </>
    );
};

export default React.memo(Report);
