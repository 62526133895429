import React from 'react';
import { TableCell, TableRow } from '@mui/material';
import SimpleDate from '../../SimpleDate';
import localization from '../../../localization';
import { Link } from 'gatsby';

const getRecordAction = (record: any, language: string) =>
    localization[language].activity[record.action];

const getRecordInformation = (record: any, language: string) => {
    switch (record.action) {
        case 'REPORT_COMMENT_CREATED_RL':
            return '';
        case 'REPORT_COMMENT_CREATED':
            return '';
        case 'REPORT_LINK_USED':
            return '';
        case 'REPORT_LINK_CREATED':
            return '';
        case 'REPORT_STATUS_CHANGED':
            return localization[language].reportStatus[record.reason];
        case 'REPORT_UNASSIGN_USER':
            return ``;
        case 'REPORT_ASSIGN_USER':
            return record.targetName;
        case 'ORGANISATION_CREATED':
            return '';
        case 'FOLLOWUP_MESSAGE_CREATED':
        case 'FOLLOWUP_MESSAGE_DELETED':
        case 'REPORT_FOLLOWUP_MESSAGE_CREATED':
        case 'REPORT_FOLLOWUP_MESSAGE_DELETED':
            return ``;
        case 'REPORT_CREATED':
            return ``;
        case 'REPORT_OPEN':
            return ``;
        case 'REPORT_CLOSED':
            return ``;
        case 'REPORT_DELETED':
            return ``;
        case 'USER_DELETED':
            return record.targetUserEmail;
        case 'USER_UPDATED':
            return record.targetUserEmail;
        case 'FILE_DOWNLOAD':
        case 'RL_FILE_DOWNLOAD':
        case 'ANON_FILE_DOWNLOAD':
            try {
                return record.targetName.split('/')[
                    record.targetName.split('/').length - 1
                ];
            } catch (err) {
                return ``;
            }
        case 'USER_INVITATION_SENT':
            return record.targetUserEmail;
        case 'USER_INVITATION_ACCEPTED':
        case 'USER_INVITATION_DECLINED':
            return '';
        case 'FILE_UPLOADED':
        case 'FILE_DELETED':
        case 'REPORT_CHANNEL_CREATED':
        case 'REPORT_CHANNEL_DELETED':
            return record.targetName;
        case 'REPORT_VIEW_PERSONAL_INFORMATION':
            return record.reason;
        case 'REPORT_COMMENT_DELETED_RL':
        case 'REPORT_COMMENT_DELETED':
            return ``;
        case `REPORT_ASSIGN_LABEL`:
        case `REPORT_UNASSIGN_LABEL`:
            return ``;
        case `REPORT_NAME_CHANGED`:
            return record.targetName;
        case `REPORT_CHANNEL_UPDATED`:
            return record.targetName;
    }

    return ``;
};

const getRecordCreatedBy = (record: any, language: string) => {
    if (record.fromAnonymousId != null) {
        return localization[language].app.labelNotifier;
    } else if (
        record.fromUserEmail != null &&
        record.fromUserEmail.length > 0
    ) {
        return record.fromUserEmail;
    } else if (record.fromName != null) {
        return record.fromName;
    } else {
        return 'Tilkynna';
    }
};

interface Props {
    language: string;
    record: any;
}

const ActivityRecord = ({ language, record }: Props) => {
    const action = getRecordAction(record, language);
    return (
        <TableRow>
            <TableCell align='left'>
                {getRecordCreatedBy(record, language)}
            </TableCell>
            <TableCell align='left'>
                {action == null ? record.action : action}
            </TableCell>
            <TableCell align='left'>
                {getRecordInformation(record, language)}
            </TableCell>
            <TableCell align='left'>
                {record.reportId != null ? (
                    <Link to={`/app/reports/id/${record.reportId}`}>
                        {record.reportId}
                    </Link>
                ) : (
                    ''
                )}
            </TableCell>
            <TableCell align='left'>
                <SimpleDate dateString={record.created} />
            </TableCell>
        </TableRow>
    );
};

export default React.memo(ActivityRecord);
