import React from 'react';
import axios from 'axios';
import {
    Button,
    Card,
    CardActions,
    CardContent,
    CircularProgress,
} from '@mui/material';
import localization from '../../../localization';
import config from '../../../config';
import * as style from './index.module.scss';
import ReportAreas from './ReportAreas';
import ReportLabels from './ReportLabels';
import { useAppSelector } from '../../../hooks';
import ReportDefaultMessages from './ReportDefaultMessages';

interface Props {
    language: string;
    organisation: any;
    setOrganisation: any;
    content: any;
    setContent: any;
}

const Settings = ({
    language,
    organisation,
    setOrganisation,
    content,
    setContent,
}: Props) => {
    const [name, setName] = React.useState('');
    const [isDeletingLogo, setIsDeletingLogo] = React.useState(false);
    const [isEditingName, setIsEditingName] = React.useState(false);
    const [isSavingName, setIsSavingName] = React.useState(false);
    const [isUploadingLogo, setIsUploadingLogo] = React.useState(false);

    // File upload state
    const [remainingUpload, setRemainingUpload] = React.useState(0);
    const [totalUpload, setTotalUpload] = React.useState(0);
    const accessToken = useAppSelector(
        (state) => state.global.auth.accessToken
    );
    const refName = React.useRef();
    const refFileInput = React.useRef();
    const refFile = React.useRef(null);
    const refIsRemoved = React.useRef(false);

    React.useEffect(() => {
        return () => {
            refIsRemoved.current = true;
        };
    }, []);

    const isValidName = name.length > 1;

    const browseFiles = () => {
        if (refFileInput.current) {
            refFileInput.current.click();
        }
    };

    const handleSaveName = async () => {
        setIsSavingName(true);
        const formData = new FormData();
        formData.append('name', name);

        try {
            const data = (
                await axios.put(
                    `${config.apiBaseUrl}/organisation/${organisation.id}/name`,
                    formData,
                    {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                    }
                )
            ).data;
            setOrganisation(data);
        } catch (err) {
            console.log(err);
        }
        setIsSavingName(false);
        setIsEditingName(false);
    };

    // const handleSavePassword = async (newPassword) => {
    //     setIsSavingPassword(true)
    //     const formData = new FormData()
    //     if (newPassword != null) {
    //         formData.append('password', newPassword)
    //     }

    //     try {
    //         const data = (await axios.put(
    //             `${config.apiBaseUrl}/organisation/${organisation.id}/password`,
    //             formData,
    //             {
    //                 headers: {
    //                     Authorization: `Bearer ${accessToken}`,
    //                 },
    //             },
    //         )).data
    //         setOrganisation(data)
    //     } catch (err) {
    //         console.log(err)
    //     }
    //     setIsSavingPassword(false)
    //     setIsEditingPassword(false)
    // }

    const handleDeleteLogo = () => {
        const deleteLogoAsync = async () => {
            setIsDeletingLogo(true);
            try {
                const data = (
                    await axios.delete(
                        `${config.apiBaseUrl}/organisation/${organisation.id}/logo`,
                        {
                            headers: {
                                Authorization: `Bearer ${accessToken}`,
                            },
                        }
                    )
                ).data;
                setOrganisation(data);
                console.log(data);
            } catch (err) {
                console.log(err);
            }
            setIsDeletingLogo(false);
        };

        deleteLogoAsync();
    };

    const uploadLogoAsync = async () => {
        setIsUploadingLogo(true);
        // 1. Get upload signature for S3
        const formDataSignature = new FormData();
        let signedUrl;
        let hasError = false;
        formDataSignature.append('filename', refFile.current.name);
        try {
            signedUrl = (
                await axios.post(
                    `${config.apiBaseUrl}/organisation/${organisation.id}/signature/logo`,
                    formDataSignature,
                    {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                    }
                )
            ).data;
        } catch (err) {
            console.log(err);
            hasError = true;
        }

        if (!hasError && signedUrl && signedUrl.length > 1) {
            try {
                await axios.put(signedUrl, refFile.current, {
                    headers: {
                        'Content-Type': refFile.current.type,
                    },
                    onUploadProgress: (e) => {
                        if (e.loaded && e.total) {
                            // console.log(e.loaded, e.total)
                            if (totalUpload !== e.total) {
                                setTotalUpload(e.total);
                            }
                            if (remainingUpload !== e.loaded) {
                                setRemainingUpload(e.loaded);
                            }
                        }
                    },
                });
            } catch (err) {
                console.log(err);
                hasError = true;
            }
        }
        if (!hasError) {
            // 2. Modify file
            const formData = new FormData();
            formData.append(
                'url',
                `https://cdn.tilkynna.is/${organisation.id}/logo/${refFile.current.name}`
            );

            try {
                const data = (
                    await axios.post(
                        `${config.apiBaseUrl}/organisation/${organisation.id}/logo`,
                        formData,
                        {
                            headers: {
                                Authorization: `Bearer ${accessToken}`,
                            },
                        }
                    )
                ).data;
                if (!refIsRemoved.current) {
                    // TOOD: Set the new URL
                    setOrganisation(data);
                    console.log(data);
                }
            } catch (err) {
                console.log(err);
            }
        }

        if (!refIsRemoved.current) {
            refFile.current = null;
        }

        setIsUploadingLogo(false);
    };

    const onFileLogoChange = (e) => {
        e.preventDefault();
        refFile.current = refFileInput.current.files[0];
        uploadLogoAsync();
        // setIsUploadingLogo(true)

        e.target.value = null;
    };

    // const onSaveReportSettingsAsync = async (e) => {
    //     setIsSaving(true)

    //     try {
    //         const data = (await axios.put(
    //             `${config.apiBaseUrl}/organisation/${organisation.id}/settings`,
    //             {
    //                 ...reportSettings,
    //                 [e.target.name]: e.target.checked,
    //             },
    //             {
    //                 headers: {
    //                     Authorization: `Bearer ${accessToken}`,
    //                 },
    //             },
    //         )).data
    //         setContent({
    //             ...content,
    //             reportSettings: data,
    //         })
    //         console.log(data)
    //     } catch (err) {
    //         console.log(err)
    //     }
    //     setIsSaving(false)
    // }

    // React.useEffect(() => {
    //     if (content != null && content.reportSettings != null) {
    //         setReportSettings({
    //             ...content.reportSettings,
    //         })
    //     }
    // }, [content])

    React.useEffect(() => {
        if (organisation != null) {
            setName(organisation.name);
            if (isEditingName && refName.current != null) {
                refName.current.focus();
            }
        }
    }, [isEditingName, organisation]);

    // React.useEffect(() => {
    //     setPassword('')
    //     if (isEditingPassword && refPassword.current != null) {
    //         refPassword.current.focus()
    //     }
    // }, [isEditingPassword])

    // React.useEffect(() => {
    //     if (organisation != null) {
    //         setSubdomain(organisation.subdomain || '')
    //     }
    //     if (isEditingSubdomain) {
    //         refSubdomain.current.focus()
    //     }
    // }, [isEditingSubdomain, organisation])

    if (organisation == null) {
        return (
            <div className={style.settingsPage}>
                <h2>{localization[language].app.labelSettings}</h2>
                <CircularProgress />
            </div>
        );
    }

    return (
        <div className={style.settingsPage}>
            <h2>{localization[language].app.labelSettings}</h2>

            <ReportAreas
                organisation={organisation}
                setOrganisation={setOrganisation}
                content={content}
                setContent={setContent}
                language={language}
            />

            {/* <Card className={style.item} variant="outlined">
                {reportSettings == null ? (
                    <CardContent>
                        <CircularProgress />
                    </CardContent>
                ) : (
                    <CardContent>
                        <h3>{localization[language].app.labelReports}</h3>

                        <select>
                            {organisation.reportChannels.map(reportChannel => (
                                <option key={reportChannel.id}>{reportChannel.subdomain}</option>
                            ))}
                        </select>

                        <div>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={reportSettings.allowAnonymousReports}
                                        onChange={onSaveReportSettingsAsync}
                                        name="allowAnonymousReports"
                                        color="primary"
                                        disabled={isSaving}
                                    />
                                }
                                label={localization[language].app.allowAnonymousReports}
                            />
                        </div>
                        <div>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={reportSettings.icelandAuthEnabled}
                                        onChange={onSaveReportSettingsAsync}
                                        name="icelandAuthEnabled"
                                        color="primary"
                                        disabled={isSaving}
                                    />
                                }
                                label={localization[language].icelandauth.enabled}
                            />
                        </div>
                        <div>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={reportSettings.icelandAuthRequired}
                                        onChange={onSaveReportSettingsAsync}
                                        name="icelandAuthRequired"
                                        color="primary"
                                        disabled={isSaving || !reportSettings.icelandAuthEnabled}
                                    />
                                }
                                label={localization[language].icelandauth.mandatorySetting}
                            />
                        </div>
                        <div className={style.notifier}>
                            <p>{localization[language].notifier.notifierSettings}</p>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={reportSettings.askForPersonalIdNumber}
                                        onChange={onSaveReportSettingsAsync}
                                        name="askForPersonalIdNumber"
                                        color="primary"
                                        disabled={isSaving}
                                    />
                                }
                                label={localization[language].app.askForPersonalIdNumber}
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={reportSettings.askForEmail}
                                        onChange={onSaveReportSettingsAsync}
                                        name="askForEmail"
                                        color="primary"
                                        disabled={isSaving}
                                    />
                                }
                                label={localization[language].app.askForEmail}
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={reportSettings.askForPhoneNumber}
                                        onChange={onSaveReportSettingsAsync}
                                        name="askForPhoneNumber"
                                        color="primary"
                                        disabled={isSaving}
                                    />
                                }
                                label={localization[language].app.askForPhoneNumber}
                            />
                        </div>
                        <div className={style.followUp}>
                            <p>{localization[language].form.labelFollowUp}</p>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={reportSettings.allowFollowUpAttachments}
                                        onChange={onSaveReportSettingsAsync}
                                        name="AllowFollowUpAttachments"
                                        color="primary"
                                        disabled={isSaving}
                                    />
                                }
                                label={localization[language].app.allowFollowUpAttachments}
                            />
                        </div>
                    </CardContent>
                )}
            </Card> */}

            <Card className={style.item} variant='outlined'>
                <CardContent>
                    <h3>{localization[language].app.reportManagement}</h3>
                    <ReportLabels
                        organisation={organisation}
                        content={content}
                        setContent={setContent}
                        language={language}
                    />

                    <ReportDefaultMessages
                        organisation={organisation}
                        content={content}
                        setContent={setContent}
                        language={language}
                    />
                </CardContent>
            </Card>

            <Card className={style.item} variant='outlined'>
                <CardContent>
                    <label className={style.label} htmlFor='name'>
                        {localization[language].create.labelName}
                    </label>
                    {!isEditingName && (
                        <>
                            <div className={style.currentSetting}>
                                {organisation.name}
                            </div>
                            {/* <Button
                                onClick={() => setIsEditingName(true)}
                                variant="outlined"
                                size="small"
                            >
                                {localization[language].app.labelEdit}
                            </Button> */}
                        </>
                    )}
                    {isEditingName && (
                        <>
                            <input
                                ref={refName}
                                type='text'
                                name='name'
                                id='name'
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                readOnly={isSavingName}
                            />
                            <div className={style.actions}>
                                <Button
                                    onClick={handleSaveName}
                                    variant='outlined'
                                    size='small'
                                    disableElevation
                                    style={{
                                        outlineColor: 'rgba(66, 180, 230, .5)',
                                    }}
                                    disabled={isSavingName || !isValidName}
                                >
                                    {localization[language].app.labelSave}
                                </Button>
                            </div>
                        </>
                    )}
                </CardContent>
                <CardActions>
                    {!isEditingName ? (
                        <Button
                            variant='text'
                            color='primary'
                            onClick={() => setIsEditingName(true)}
                        >
                            {localization[language].app.labelEdit}
                        </Button>
                    ) : (
                        <Button
                            onClick={() => setIsEditingName(false)}
                            variant='text'
                            color='primary'
                            disabled={isSavingName}
                        >
                            {localization[language].app.labelCancel}
                        </Button>
                    )}
                </CardActions>
            </Card>

            <Card className={style.item} variant='outlined'>
                <CardContent>
                    <label className={style.label} htmlFor='logo'>
                        {localization[language].app.labelLogo}
                    </label>
                    <input
                        ref={refFileInput}
                        className={style.fileInput}
                        type='file'
                        name='logo'
                        id='logo'
                        accept='image/*'
                        onChange={onFileLogoChange}
                    />

                    {organisation.logo != null &&
                    organisation.logo.length > 0 ? (
                        <img
                            className={style.imgLogo}
                            src={organisation.logo}
                            alt={localization[language].app.labelLogo}
                        />
                    ) : (
                        <p className={style.infoNoLogo}>
                            {localization[language].app.labelNoLogo}
                        </p>
                    )}
                </CardContent>
                <CardActions>
                    {organisation.logo != null &&
                    organisation.logo.length > 0 ? (
                        <Button
                            onClick={handleDeleteLogo}
                            variant='text'
                            color='primary'
                            disabled={isDeletingLogo}
                        >
                            {isDeletingLogo
                                ? localization[language].app.labelDeleting
                                : localization[language].app.labelDelete}
                        </Button>
                    ) : (
                        <Button
                            onClick={browseFiles}
                            variant='text'
                            color='primary'
                            disabled={isUploadingLogo}
                        >
                            {isUploadingLogo
                                ? `${
                                      localization[language].app
                                          .labelIsUploadingFile
                                  } (${Math.floor(
                                      (remainingUpload / totalUpload) * 100
                                  )}%)`
                                : localization[language].app.labelUploadLogo}
                        </Button>
                    )}
                </CardActions>
            </Card>
            {/* <Card className={style.item} variant="outlined">
                <CardContent>
                    <label
                        className={style.label}
                        htmlFor="subdomain"
                    >
                        {localization[language].create.labelSubdomain}
                    </label>
                    {!isEditingSubdomain && (
                        <div className={style.currentSetting}>
                            <span style={{ fontStyle: 'italic' }}>{organisation.subdomain}</span>.tilkynna.is
                        </div>
                    )}
                    {isEditingSubdomain && (
                        <>
                            <input
                                ref={refSubdomain}
                                type="text"
                                name="subdomain"
                                id="subdomain"
                                value={subdomain}
                                onChange={(e) => setSubdomain(e.target.value)}
                                readOnly={isSavingSubdomain}
                            />
                            <div className={style.actions}>
                                <Button
                                    disabled={isSavingSubdomain || subdomain.length < 1}
                                    onClick={handleSaveSubdomain}
                                    variant="outlined"
                                    size="small"
                                    disableElevation
                                    style={{ outlineColor: 'rgba(66, 180, 230, .5)' }}
                                >
                                    {localization[language].app.labelSave}
                                </Button>
                            </div>
                        </>
                    )}
                </CardContent>
                <CardActions>
                    {isEditingSubdomain
                        ? (
                            <Button
                                onClick={() => setIsEditingSubdomain(false)}
                                variant="text"
                                color="primary"
                            >
                                {localization[language].app.labelCancel}
                            </Button>
                        )
                        : (
                            <Button
                                onClick={() => setIsEditingSubdomain(true)}
                                variant="text"
                                color="primary"
                            >
                                {localization[language].app.labelEdit}
                            </Button>
                        )}
                </CardActions>
            </Card> */}

            {/* <Card className={style.item} variant="outlined">
                <CardContent>
                    <label
                        className={style.label}
                        htmlFor="password"
                    >
                        {localization[language].app.labelOptionalPassword}
                    </label>
                    {!isEditingPassword && (
                        <>
                            <div className={style.currentSetting}>
                                {organisation.password}
                            </div>
                            <div className={style.actions}>
                                <Button
                                    onClick={() => setIsEditingPassword(true)}
                                    variant="outlined"
                                    size="small"
                                    disabled={isSavingPassword}
                                >
                                    {hasPassword ? localization[language].app.labelEdit : localization[language].app.labelAddPassword}
                                </Button>
                                {hasPassword && (
                                    <Button
                                        onClick={handleDeletePassword}
                                        variant="outlined"
                                        size="small"
                                        disabled={isSavingPassword}
                                    >
                                        {localization[language].app.labelDelete}
                                    </Button>
                                )}
                            </div>
                        </>
                    )}
                    {isEditingPassword && (
                        <>
                            <input
                                ref={refPassword}
                                type="text"
                                name="password"
                                id="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                readOnly={isSavingPassword}
                            />
                            <div className={style.actions}>
                                <Button
                                    onClick={() => handleSavePassword(password)}
                                    variant="outlined"
                                    size="small"
                                    disableElevation
                                    style={{ outlineColor: 'rgba(66, 180, 230, .5)' }}
                                    disabled={isSavingPassword}
                                >
                                    {localization[language].app.labelSave}
                                </Button>
                                <Button
                                    onClick={() => setIsEditingPassword(false)}
                                    variant="outlined"
                                    size="small"
                                    disabled={isSavingPassword}
                                >
                                    {localization[language].app.labelCancel}
                                </Button>
                            </div>
                        </>
                    )}
                </CardContent>
            </Card> */}
        </div>
    );
};

export default Settings;
