import React from 'react';
import axios from 'axios';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    CircularProgress,
} from '@mui/material';
import localization from '../../../localization';
import config from '../../../config';
import ActivityRecord from '../ActivityRecord';

interface Props {
    accessToken: string;
    organisation: any;
    report: any;
    language: string;
    records: any;
    setRecords: any;
}

const ReportActivity = ({
    accessToken,
    organisation,
    report,
    language,
    records,
    setRecords,
}: Props) => {
    const [hasFetchedInitial, setHasFetchedInitial] = React.useState(false);
    const [isFetching, setIsFetching] = React.useState(false);

    const getRecordsAsync = async () => {
        setIsFetching(true);
        try {
            const response = await axios.get(
                `${config.apiBaseUrl}/organisation/${organisation.id}/report/${report.id}/activity`,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            );
            setRecords(response.data.records);
        } catch (err) {
            console.log(err);
        }
        setIsFetching(false);
    };

    React.useEffect(() => {
        if (!hasFetchedInitial && organisation != null && report != null) {
            getRecordsAsync();
            setHasFetchedInitial(true);
        }
    }, [organisation, accessToken, hasFetchedInitial, report]);

    return (
        <>
            <h2>{localization[language].app.labelActivity}</h2>
            <TableContainer component={Paper} variant='outlined'>
                <Table aria-label='table'>
                    <TableHead>
                        <TableRow
                            style={{
                                backgroundColor: '#eeeeee',
                            }}
                        >
                            <TableCell align='left'>
                                {localization[language].app.labelUser}
                            </TableCell>
                            <TableCell align='left'>
                                {localization[language].app.labelAction}
                            </TableCell>
                            <TableCell align='left'>
                                {localization[language].app.labelInformation}
                            </TableCell>
                            <TableCell align='left'>
                                {localization[language].app.labelReport}
                            </TableCell>
                            <TableCell align='left'>
                                {localization[language].app.labelDate}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {records.map((r: any) => (
                            <ActivityRecord
                                key={r.id}
                                record={r}
                                language={language}
                            />
                        ))}
                        {(organisation == null || isFetching) && (
                            <TableRow>
                                <TableCell>
                                    <CircularProgress />
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};

export default React.memo(ReportActivity);
