import React from 'react';
import axios from 'axios';

import {
    Button,
    Chip,
    IconButton,
    Menu,
    MenuItem,
    Select,
    Stack,
    Tooltip,
} from '@mui/material';
import config from '../../../config';
import localization from '../../../localization';
import * as style from './index.module.scss';

import { Add, Edit, HighlightOffTwoTone } from '@mui/icons-material';
import { navigate } from 'gatsby-link';
import { Link } from 'gatsby';
import SimpleDate from '../../SimpleDate';

interface Props {
    isFetchingReport: boolean;
    content: any;
    report: any;
    setReport: any;
    reports: any;
    setReports: any;
    organisation: any;
    accessToken: string;
    language: string;
    setRefreshClosedReports: any;
    setRefreshReports: any;
    setRefreshRecords: any;
    setIsCloseDialogOpen: any;
    setIsErasePersonalDataDialogOpen: any;
    users: any;
    canManageReports: any;
    organisationId: string;
}

const ReportActions = ({
    isFetchingReport,
    content,
    report,
    setReport,
    reports,
    setReports,
    organisation,
    accessToken,
    language,
    setRefreshClosedReports,
    setRefreshReports,
    setRefreshRecords,
    setIsCloseDialogOpen,
    setIsErasePersonalDataDialogOpen,
    users,
    canManageReports,
    organisationId,
}: Props) => {
    const [isChangingStatus, setIsChangingStatus] = React.useState(false);
    const [isEditingName, setIsEditingName] = React.useState(false);
    const [isSavingName, setIsSavingName] = React.useState(false);
    const [reportName, setReportName] = React.useState<string>(
        report.name || ''
    );

    const [isStatusOpen, setIsStatusOpen] = React.useState(false);
    const [status, setStatus] = React.useState(report.status);

    const [isUserListOpen, setIsUserListOpen] = React.useState(false);
    const [anchorUserMenuEl, setAnchorUserMenuEl] = React.useState<any>(null);
    const [assignedUsers, setAssignedUsers] = React.useState<any>([]);
    const [unassignedUsers, setUnassignedUsers] = React.useState<any>([]);
    const [isChangingAssignedUser, setIsChangingAssignedUser] =
        React.useState(false);

    const [isLabelListOpen, setIsLabelListOpen] = React.useState(false);
    const [assignedLabels, setAssignedLabels] = React.useState<any>([]);
    const [unassignedLabels, setUnassignedLabels] = React.useState<any>([]);
    const [anchorLabelMenuEl, setAnchorLabelMenuEl] = React.useState(null);
    const [isChangingAssignedLabel, setIsChangingAssignedLabel] =
        React.useState(false);

    const canSaveReportName = (report.name || '') !== reportName;

    const refName = React.useRef();

    React.useEffect(() => {
        // console.log(report.assignedUserIds, users)
        if (users != null) {
            let tempAssignedUsers: any = [];
            let tempUnassignedUsers: any = [];
            let assignedDict: any = {};

            if (report.assignedUserIds != null) {
                for (let i = 0; i < report.assignedUserIds.length; i++) {
                    assignedDict[report.assignedUserIds[i]] = true;
                }
            }

            for (let i = 0; i < users.length; i++) {
                if (!users[i].isPendingInvitation) {
                    if (assignedDict[users[i].id] === true) {
                        tempAssignedUsers.push(users[i]);
                    } else {
                        tempUnassignedUsers.push(users[i]);
                    }
                }
            }

            setAssignedUsers(tempAssignedUsers);
            setUnassignedUsers(tempUnassignedUsers);
        }
    }, [report.assignedUserIds, users]);

    React.useEffect(() => {
        if (content.reportLabels == null || report == null) {
            return;
        }
        let tempAssignedLabels = [];
        let tempUnassignedLabels = [];
        let assignedDict = {};

        if (report.assignedLabels != null) {
            for (let i = 0; i < report.assignedLabels.length; i++) {
                assignedDict[report.assignedLabels[i]] = true;
            }
        }

        for (let i = 0; i < content.reportLabels.length; i++) {
            if (assignedDict[content.reportLabels[i].id] === true) {
                tempAssignedLabels.push(content.reportLabels[i]);
            } else {
                tempUnassignedLabels.push(content.reportLabels[i]);
            }
        }

        setAssignedLabels(tempAssignedLabels);
        setUnassignedLabels(tempUnassignedLabels);
    }, [report.assignedLabels, content.reportLabels]);

    const saveNameAsync = async () => {
        setIsSavingName(true);
        try {
            let res = await axios.post(
                `${config.apiBaseUrl}/organisation/${organisation.id}/report/${report.id}/name`,
                {
                    name: reportName,
                },
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            );

            setReport(res.data);

            const updatedReports = [...reports];

            for (let i = 0; i < updatedReports.length; i++) {
                if (updatedReports[i].id === report.id) {
                    updatedReports[i] = {
                        ...updatedReports[i],
                        ...res.data,
                    };
                    break;
                }
            }

            setReports(updatedReports);
        } catch (err) {
            console.log(err);
        }
        setIsEditingName(false);
        setIsSavingName(false);
    };

    const changeStatusAsync = async (newStatus: string) => {
        setStatus(newStatus);
        if (newStatus.startsWith('closed_')) {
            setIsCloseDialogOpen(true);
        }
        setIsChangingStatus(true);
        try {
            const response = await axios.put(
                `${config.apiBaseUrl}/organisation/${organisation.id}/report/${report.id}/status/${newStatus}`,
                null,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            );

            setReport({
                ...response.data,
            });

            const updatedReports = [...reports];

            for (let i = 0; i < updatedReports.length; i++) {
                if (updatedReports[i].id === report.id) {
                    updatedReports[i] = {
                        ...updatedReports[i],
                        ...response.data,
                    };
                    break;
                }
            }

            setReports(updatedReports);
            setRefreshClosedReports(true);
            setRefreshRecords(true);
        } catch (err) {
            if (err && err.response && err.response.status) {
            } else {
                console.error(err);
            }
        }

        setIsChangingStatus(false);
        setRefreshReports(true);
        // setActiveCasesTab(0)
        // navigate('/app/reports')
    };

    const setAssignedUserAsync = async (
        assignedUserId: string,
        isRemoving: boolean
    ) => {
        if (isUserListOpen) {
            setIsUserListOpen(false);
        }
        setIsChangingAssignedUser(true);
        try {
            let response;
            if (!isRemoving) {
                response = await axios.put(
                    `${config.apiBaseUrl}/organisation/${organisation.id}/report/${report.id}/assigneduser/${assignedUserId}`,
                    null,
                    {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                    }
                );
            } else {
                response = await axios.delete(
                    `${config.apiBaseUrl}/organisation/${organisation.id}/report/${report.id}/assigneduser/${assignedUserId}`,
                    {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                    }
                );
            }
            setReport({
                ...response.data,
            });

            setReports([
                response.data,
                ...reports.filter((n) => n.id !== report.id),
            ]);
        } catch (err) {
            if (err && err.response && err.response.status) {
            } else {
                console.error(err);
            }
        }

        setIsChangingAssignedUser(false);
        setRefreshReports(true);
    };

    const setAssignedLabelAsync = async (
        assignedLabelId: String,
        isRemoving: boolean
    ) => {
        if (isLabelListOpen) {
            setIsLabelListOpen(false);
        }
        setIsChangingAssignedLabel(true);
        try {
            let response;
            if (!isRemoving) {
                response = await axios.put(
                    `${config.apiBaseUrl}/organisation/${organisation.id}/report/${report.id}/labels/${assignedLabelId}`,
                    null,
                    {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                    }
                );
            } else {
                response = await axios.delete(
                    `${config.apiBaseUrl}/organisation/${organisation.id}/report/${report.id}/labels/${assignedLabelId}`,
                    {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                    }
                );
            }
            setReport({
                ...response.data,
            });

            setReports([
                response.data,
                ...reports.filter((n) => n.id !== report.id),
            ]);
        } catch (err) {
            if (err && err.response && err.response.status) {
            } else {
                console.error(err);
            }
        }

        setIsChangingAssignedLabel(false);
        setRefreshReports(true);
    };

    const handleOpenUserMenu = (e) => {
        setAnchorUserMenuEl(e.currentTarget);
        setIsUserListOpen(true);
    };

    const handleOpenLabelMenu = (e) => {
        setAnchorLabelMenuEl(e.currentTarget);
        setIsLabelListOpen(true);
    };

    const onChangeName = () => {
        setIsEditingName(true);
    };

    const getReportChannelById = (reportAreaId: string) => {
        if (organisation == null || organisation.reportChannels == null) {
            return null;
        }

        for (let i = 0; i < organisation.reportChannels.length; i++) {
            if (organisation.reportChannels[i].id === reportAreaId) {
                return organisation.reportChannels[i];
            }
        }

        return null;
    };

    React.useEffect(() => {
        if (isEditingName && refName.current) {
            refName.current.focus();
        }
    }, [isEditingName]);

    if (isFetchingReport) {
        return (
            <div className={style.reportActions}>
                <h2>{localization[language].app.management}</h2>
                <div className={style.reportName}></div>
            </div>
        );
    }

    return (
        <div className={style.reportActions}>
            <h2>{localization[language].app.management}</h2>
            <div className={style.reportName}>
                <label htmlFor='reportName'>
                    {localization[language].app.caseName}
                </label>
                {isEditingName ? (
                    <>
                        <input
                            ref={refName}
                            type='text'
                            name='reportName'
                            id='reportName'
                            maxLength={5000}
                            value={reportName}
                            onChange={(e) => setReportName(e.target.value)}
                            placeholder={
                                localization[language].app.enterOptionalCaseName
                            }
                            disabled={isSavingName}
                        />
                        <div className={style.reportNameActions}>
                            <Button
                                size='small'
                                variant='contained'
                                onClick={saveNameAsync}
                                disabled={isSavingName || !canSaveReportName}
                            >
                                {localization[language].app.labelSave}
                            </Button>
                            <Button
                                size='small'
                                variant='outlined'
                                onClick={() => setIsEditingName(false)}
                                disabled={isSavingName}
                            >
                                {localization[language].app.labelCancel}
                            </Button>
                        </div>
                    </>
                ) : (
                    <>
                        <p style={{ display: 'flex', alignItems: 'center' }}>
                            {report.name || (
                                <i>{localization[language].app.noCaseName}</i>
                            )}
                            <Tooltip
                                title={localization[language].app.labelEdit}
                            >
                                <IconButton
                                    color='primary'
                                    className={style.btnEdit}
                                    size='small'
                                    onClick={onChangeName}
                                >
                                    <Edit fontSize='small' />
                                </IconButton>
                            </Tooltip>
                        </p>
                    </>
                )}
            </div>
            <div className={style.reportStatus}>
                <label>{localization[language].app.labelStatus}</label>
                <Select
                    labelId='status'
                    id='status'
                    open={isStatusOpen}
                    variant='standard'
                    onClose={() => setIsStatusOpen(false)}
                    onOpen={() => setIsStatusOpen(true)}
                    value={status}
                    onChange={(e) => changeStatusAsync(e.target.value)}
                    style={{ minWidth: 140 }}
                    disabled={isChangingStatus || !canManageReports}
                >
                    <MenuItem value='open'>
                        {localization[language].reportStatus.open}
                    </MenuItem>
                    <MenuItem value='open_inprogress'>
                        {localization[language].reportStatus.open_inprogress}
                    </MenuItem>
                    <MenuItem value='pending_information'>
                        {
                            localization[language].reportStatus
                                .pending_information
                        }
                    </MenuItem>
                    <MenuItem value='pending_comment'>
                        {localization[language].reportStatus.pending_comment}
                    </MenuItem>
                    <MenuItem value='pending_reply_from_notifier'>
                        {
                            localization[language].reportStatus
                                .pending_reply_from_notifier
                        }
                    </MenuItem>
                    <MenuItem value='closed_insufficient_info'>
                        {
                            localization[language].reportStatus
                                .closed_insufficient_info
                        }
                    </MenuItem>
                    <MenuItem value='closed_invalid_report'>
                        {
                            localization[language].reportStatus
                                .closed_invalid_report
                        }
                    </MenuItem>
                    <MenuItem value='closed_spam'>
                        {localization[language].reportStatus.closed_spam}
                    </MenuItem>
                    <MenuItem value='closed_resolved'>
                        {localization[language].reportStatus.closed_resolved}
                    </MenuItem>
                </Select>
            </div>
            <div className={style.assignedUsers}>
                <label>{localization[language].app.assignedUsers}</label>
                <Stack direction='row' spacing={1}>
                    {assignedUsers.map((user) => (
                        <Chip
                            key={user.id}
                            variant='outlined'
                            label={user.name}
                            onDelete={
                                canManageReports
                                    ? () => setAssignedUserAsync(user.id, true)
                                    : null
                            }
                            disabled={isChangingAssignedUser}
                        />
                    ))}
                    <Chip
                        variant='outlined'
                        color='primary'
                        className={style.assignedUser}
                        label={localization[language].app.add}
                        onClick={handleOpenUserMenu}
                        icon={<Add fontSize='small' />}
                        disabled={isChangingAssignedUser || !canManageReports}
                    />
                </Stack>
                <Menu
                    onClose={() => setIsUserListOpen(false)}
                    open={isUserListOpen}
                    anchorEl={anchorUserMenuEl}
                    keepMounted
                >
                    {unassignedUsers.map((user) => (
                        <MenuItem
                            key={user.id}
                            value={user.id}
                            onClick={() => setAssignedUserAsync(user.id, false)}
                            disabled={
                                isChangingAssignedUser ||
                                user.role === 'read' ||
                                user.role === 'statistics'
                            }
                        >
                            {user.name}{' '}
                            <span style={{ fontSize: '0.8em', paddingLeft: 5 }}>
                                ({localization[language].roles[user.role]})
                            </span>
                        </MenuItem>
                    ))}
                </Menu>
            </div>
            <div className={style.assignedLabels}>
                <label>{localization[language].app.labels}</label>
                <Stack direction='row' spacing={1}>
                    {assignedLabels.map((label) => (
                        <Chip
                            key={label.id}
                            label={label.title.is || label.title.en}
                            onDelete={
                                canManageReports
                                    ? () =>
                                          setAssignedLabelAsync(label.id, true)
                                    : null
                            }
                            disabled={isChangingAssignedLabel}
                            style={{
                                backgroundColor: label.color,
                                color: '#ffffff',
                            }}
                            deleteIcon={
                                canManageReports ? (
                                    <HighlightOffTwoTone
                                        style={{ color: '#ffffff' }}
                                    />
                                ) : null
                            }
                        />
                    ))}
                    <Chip
                        className={style.assignedLabel}
                        variant='outlined'
                        color='primary'
                        label={localization[language].app.add}
                        disabled={
                            isChangingAssignedLabel ||
                            !canManageReports ||
                            unassignedLabels.length === 0
                        }
                        onClick={handleOpenLabelMenu}
                        icon={<Add fontSize='small' />}
                    />
                </Stack>
                <Menu
                    onClose={() => setIsLabelListOpen(false)}
                    open={isLabelListOpen}
                    anchorEl={anchorLabelMenuEl}
                    keepMounted
                >
                    {unassignedLabels.map((label) => (
                        <MenuItem
                            key={label.id}
                            value={label.id}
                            dense
                            onClick={() =>
                                setAssignedLabelAsync(label.id, false)
                            }
                            disabled={isChangingAssignedLabel}
                            style={{ padding: '2px 8px' }}
                        >
                            <span
                                style={{
                                    backgroundColor: label.color,
                                    color: '#ffffff',
                                    fontSize: '1em',
                                    padding: '5px 15px',
                                    minWidth: 170,
                                    borderRadius: 4,
                                }}
                            >
                                {label.title.is || label.title.en}
                            </span>
                        </MenuItem>
                    ))}
                    <MenuItem
                        onClick={() =>
                            navigate(`/app/${organisationId}/settings`)
                        }
                        dense
                    >
                        {localization[language].app.manageLabels}
                    </MenuItem>
                </Menu>
                {content.reportLabels.length === 0 && (
                    <div>
                        <p>{localization[language].app.noLabels}</p>
                        <Link to={`/app/${organisationId}/settings`}>
                            {localization[language].app.manageLabels}
                        </Link>
                    </div>
                )}
            </div>
            {report.reportAreaId != null &&
                organisation.reportChannels != null &&
                getReportChannelById(report.reportAreaId) != null && (
                    <div className={style.reportArea}>
                        <label>{localization[language].app.reportArea}</label>
                        <p>
                            {
                                getReportChannelById(report.reportAreaId)
                                    .subdomain
                            }
                        </p>
                    </div>
                )}
            <div className={style.personalInformation}>
                <label>{localization[language].app.personalInformation}</label>
                {report.lastDeletedPersonalData != null && (
                    <p>
                        {localization[language].app.lastDeletedPersonalData}{' '}
                        <SimpleDate
                            dateString={report.lastDeletedPersonalData}
                        />
                    </p>
                )}

                <Button
                    variant='outlined'
                    size='small'
                    onClick={() => setIsErasePersonalDataDialogOpen(true)}
                >
                    {localization[language].app.erasePersonalInformation}
                </Button>
            </div>
        </div>
    );
};

export default React.memo(ReportActions);
