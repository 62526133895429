// extracted by mini-css-extract-plugin
export var btnAttachment = "index-module--btnAttachment--XOUoN";
export var cardHeaderSmall = "index-module--cardHeaderSmall--S076t";
export var choiceEditorPaper = "index-module--choiceEditorPaper--vbvLE";
export var choicePaper = "index-module--choicePaper--Wiop1";
export var choicesPaper = "index-module--choicesPaper--JwFEQ";
export var contentActions = "index-module--contentActions--u1m2y";
export var dangerZone = "index-module--dangerZone--CC7NF";
export var dialog = "index-module--dialog--FLlwB";
export var file = "index-module--file--D4joy";
export var fileList = "index-module--fileList--tDds1";
export var formFieldEditor = "index-module--formFieldEditor--Skkq3";
export var formFieldPreview = "index-module--formFieldPreview--8yRpP";
export var formPage = "index-module--formPage--QJpKp";
export var fullSize = "index-module--fullSize--8kWYw";
export var isDragging = "index-module--isDragging--M66ji";
export var isSelected = "index-module--isSelected--vXVbj";
export var item = "index-module--item--NM-d6";
export var itemList = "index-module--itemList--XA3A4";
export var label = "index-module--label--hFFTS";
export var labelSmall = "index-module--labelSmall--E2nbH";
export var preview = "index-module--preview--1MTAb";
export var titlePaper = "index-module--titlePaper--L8OUD";
export var titles = "index-module--titles--Oit0y";