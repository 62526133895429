import React from 'react';
import axios from 'axios';
import { Link, navigate } from 'gatsby';
import {
    Button,
    Card,
    CardContent,
    CircularProgress,
    CardActions,
} from '@mui/material';
import config from '../../../config';
import localization from '../../../localization';
import * as style from './index.module.scss';

interface Props {
    language: string;
    organisation: any;
    accessToken: string;
    users: any[];
    setUsers: any;
    organisationId: string;
}

const InviteUser = ({
    language,
    accessToken,
    organisation,
    setUsers,
    users,
    organisationId,
}: Props) => {
    const [email, setEmail] = React.useState('');
    const [role, setRole] = React.useState('admin');
    const refEmailInput = React.useRef(null);
    const [isSent, setIsSent] = React.useState(false);
    const [isSending, setIsSending] = React.useState(false);
    const [hasAlreadyBeenInvited, setHasAlreadyBeenInvited] =
        React.useState(false);

    const isValidEmail = email.length > 3 && email.indexOf('@') > 0;

    React.useEffect(() => {
        if (refEmailInput && refEmailInput.current) {
            refEmailInput.current.focus();
        }
    }, []);

    const inviteAsync = async () => {
        setIsSent(false);
        setIsSending(true);
        try {
            const response = await axios.post(
                `${config.apiBaseUrl}/organisation/${organisationId}/invite`,
                {
                    email,
                    role,
                },
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            );
            setIsSent(true);
            setUsers([...users, response.data]);
        } catch (err: any) {
            if (err && err.response && err.response.status) {
                if (err.response.status === 409) {
                    setHasAlreadyBeenInvited(true);
                    setEmail('');
                }
            }
            console.log(err);
        }
        setIsSending(false);
    };

    const onEmailChange = (e) => {
        setEmail(e.target.value.replace(' ', ''));
        if (hasAlreadyBeenInvited) {
            setHasAlreadyBeenInvited(false);
        }
    };

    if (isSent) {
        return (
            <div className={style.inviteNewUserPage}>
                <h2>
                    <Link to={`/app/${organisationId}/users`}>
                        {localization[language].app.labelManageUsers}
                    </Link>{' '}
                    / {localization[language].invite.labelSent}
                </h2>
                <Card variant='outlined'>
                    <CardContent>
                        <h2>{localization[language].invite.labelSent}</h2>
                    </CardContent>
                    <CardActions>
                        <Button
                            size='large'
                            onClick={() =>
                                navigate(`/app/${organisationId}/users`)
                            }
                        >
                            {localization[language].form.labelBack}
                        </Button>
                    </CardActions>
                </Card>
            </div>
        );
    }

    return (
        <div className={style.inviteNewUserPage}>
            <h2>
                <Link to={`/app/${organisationId}/users`}>
                    {localization[language].app.labelManageUsers}
                </Link>{' '}
                / {localization[language].invite.title}
            </h2>
            <Card variant='outlined' className={style.inviteCard}>
                <CardContent>
                    <div>
                        <label htmlFor='email' className={style.label}>
                            {localization[language].form.labelEmail}
                        </label>
                        <input
                            ref={refEmailInput}
                            name='email'
                            id='email'
                            type='email'
                            value={email}
                            onChange={onEmailChange}
                            autoComplete='email'
                        />
                        <label htmlFor='role' className={style.label}>
                            {localization[language].form.labelRole}
                        </label>
                        <select
                            name='role'
                            id='role'
                            onChange={(e) => setRole(e.target.value)}
                            value={role}
                        >
                            <option value='admin'>
                                {localization[language].roles.admin}
                            </option>
                            <option value='manage'>
                                {localization[language].roles.manage}
                            </option>
                            <option value='read'>
                                {localization[language].roles.read}
                            </option>
                            <option value='analytics'>
                                {localization[language].roles.analytics}
                            </option>
                        </select>
                    </div>
                    {hasAlreadyBeenInvited && (
                        <div className={style.error}>
                            <p>
                                {localization[language].app.emailAlreadyInvited}
                            </p>
                        </div>
                    )}
                </CardContent>
                {organisation == null && (
                    <CardContent>
                        <CircularProgress />
                    </CardContent>
                )}
                <CardActions>
                    <Button
                        onClick={inviteAsync}
                        disabled={isSending || isSent || !isValidEmail}
                    >
                        {isSending
                            ? localization[language].invite.labelSending
                            : localization[language].invite.labelSendInvite}
                    </Button>
                    <Button
                        style={{ marginLeft: 'auto' }}
                        onClick={() => navigate(`/app/${organisationId}/users`)}
                        disabled={isSending}
                    >
                        {localization[language].form.labelCancel}
                    </Button>
                </CardActions>
            </Card>
        </div>
    );
};

export default React.memo(InviteUser);
