import React from 'react';
import App from '../components/app';
import { ThemeProvider, createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            dark: '#004c8b',
            main: '#0277bc',
            light: '#58a5ef',
        },
        secondary: {
            dark: 'rgb(235, 90, 70)',
            main: 'rgb(235, 90, 70)',
            light: 'rgb(235, 90, 70)',
        },
    },
});

const AppPage = ({ location }) => {
    return (
        <ThemeProvider theme={theme}>
            <App location={location} />
        </ThemeProvider>
    );
};

export default AppPage;
