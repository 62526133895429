import React from 'react';
import axios from 'axios';
import classNames from 'classnames';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {
    Button,
    Card,
    CardContent,
    CircularProgress,
    FormHelperText,
} from '@mui/material';
import localization from '../../../localization';
import * as style from './index.module.scss';
import config from '../../../config';
import TilkynnaCheckbox from '../../Checkbox';

import Radio from '../../Radio';
import { navigate } from 'gatsby-link';
import { Link } from 'gatsby';
import { useAppSelector } from '../../../hooks';
import { Content } from '../../../interfaces/Content';

interface Props {
    language: string;
    content: Content;
    setContent: any;
    selectedGroup: any;
    setSelectedGroup: any;
    groupId: string;
    organisationId: string;
}

const FormGroupFields = ({
    language,
    content,
    setContent,
    selectedGroup,
    setSelectedGroup,
    groupId,
    organisationId,
}: Props) => {
    const accessToken = useAppSelector(
        (state) => state.global.auth.accessToken
    );

    const languages = [
        { short: 'is', title: 'Íslenska' },
        { short: 'en', title: 'English' },
    ];
    const [isSavingContent, setIsSavingContent] = React.useState(false);

    // const [isCreatingField, setIsCreatingField] = React.useState(false)
    // const [selectedField, setSelectedField] = React.useState(null)

    const refIsRemoved = React.useRef(false);

    React.useEffect(() => {
        return () => {
            refIsRemoved.current = true;
        };
    }, []);

    React.useEffect(() => {
        if (
            selectedGroup === null &&
            groupId &&
            content != null &&
            content.groups != null &&
            !content.isFetching
        ) {
            let found = false;
            for (let i = 0; i < content.groups.length; i++) {
                if (content.groups[i].id === groupId) {
                    setSelectedGroup(content.groups[i]);
                    found = true;
                    break;
                }
            }

            if (!found) {
                navigate(`/app/${organisationId}/form/`);
            }
        }
    }, [selectedGroup, groupId, content, organisationId]);

    const onDragEndHandler = async (res) => {
        if (
            !res ||
            !res.destination ||
            res.destination.index === res.source.index
        ) {
            return;
        }

        let updatedFields = [...selectedGroup.formFields];

        const [removed] = updatedFields.splice(res.source.index, 1);
        updatedFields.splice(res.destination.index, 0, removed);

        const updatedSelectedGroup = {
            ...selectedGroup,
            formFields: updatedFields,
        };

        setSelectedGroup(updatedSelectedGroup);

        const updatedGroups = [...content.groups];

        for (let i = 0; i < updatedGroups.length; i++) {
            if (updatedGroups[i].id === selectedGroup.id) {
                updatedGroups[i] = {
                    ...updatedSelectedGroup,
                };
                break;
            }
        }

        setContent({
            ...content,
            groups: updatedGroups,
        });

        try {
            setIsSavingContent(true);
            await axios.post(
                `${config.apiBaseUrl}/organisation/${organisationId}/content/group/${selectedGroup.id}/fields/changeorder/${res.source.index}/${res.destination.index}`,
                null,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            );
        } catch (err) {
            console.log(err);
        }
        setIsSavingContent(false);
    };

    return (
        <Card>
            <CardContent>
                <h3>
                    <Link to={`/app/${organisationId}/form`}>
                        {localization[language].formEditor.reportForm}
                    </Link>{' '}
                    /{' '}
                    {selectedGroup != null
                        ? selectedGroup.title.is || selectedGroup.title.en
                        : null}
                </h3>
                <Card className={style.item} variant='outlined'>
                    <CardContent>
                        <h3>{localization[language].formEditor.formFields}</h3>

                        {selectedGroup != null &&
                            selectedGroup.formFields != null &&
                            selectedGroup.formFields.length > 0 && (
                                <DragDropContext onDragEnd={onDragEndHandler}>
                                    <Droppable droppableId='droppableFormField'>
                                        {(provided, snapshot) => (
                                            <div
                                                className={style.itemList}
                                                {...provided.droppableProps}
                                                ref={provided.innerRef}
                                            >
                                                <FormHelperText
                                                    style={{ marginBottom: 10 }}
                                                >
                                                    {
                                                        localization[language]
                                                            .formEditor
                                                            .formDragAndDropHint
                                                    }
                                                </FormHelperText>
                                                {selectedGroup.formFields.map(
                                                    (formField, index) => (
                                                        <Draggable
                                                            key={formField.id}
                                                            draggableId={
                                                                formField.id
                                                            }
                                                            index={index}
                                                            isDragDisabled={
                                                                isSavingContent
                                                            }
                                                        >
                                                            {(
                                                                provided,
                                                                snapshot
                                                            ) => (
                                                                <div
                                                                    className={classNames(
                                                                        style.item,
                                                                        snapshot.isDragging &&
                                                                            style.isDragging
                                                                    )}
                                                                    ref={
                                                                        provided.innerRef
                                                                    }
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                    style={{
                                                                        ...provided
                                                                            .draggableProps
                                                                            .style,
                                                                    }}
                                                                >
                                                                    {languages.map(
                                                                        (
                                                                            l,
                                                                            i
                                                                        ) => (
                                                                            <div
                                                                                className={
                                                                                    style.titles
                                                                                }
                                                                                key={
                                                                                    l.short
                                                                                }
                                                                            >
                                                                                <div>
                                                                                    {
                                                                                        l.short
                                                                                    }
                                                                                </div>
                                                                                {formField
                                                                                    .title[
                                                                                    l
                                                                                        .short
                                                                                ] !=
                                                                                    null &&
                                                                                    formField
                                                                                        .title[
                                                                                        l
                                                                                            .short
                                                                                    ] !=
                                                                                        '' && (
                                                                                        <h4>
                                                                                            {
                                                                                                formField
                                                                                                    .title[
                                                                                                    l
                                                                                                        .short
                                                                                                ]
                                                                                            }{' '}
                                                                                            {formField.required && (
                                                                                                <span>
                                                                                                    *
                                                                                                </span>
                                                                                            )}
                                                                                        </h4>
                                                                                    )}
                                                                                <h5>
                                                                                    {formField.tooltip !=
                                                                                        null &&
                                                                                        formField
                                                                                            .tooltip[
                                                                                            l
                                                                                                .short
                                                                                        ]}
                                                                                </h5>
                                                                            </div>
                                                                        )
                                                                    )}

                                                                    {formField.fieldType ===
                                                                        'radio' && (
                                                                        <div
                                                                            className={
                                                                                style.formFieldPreview
                                                                            }
                                                                        >
                                                                            {formField.choices.map(
                                                                                (
                                                                                    choice,
                                                                                    choiceIndex
                                                                                ) => (
                                                                                    <div
                                                                                        key={
                                                                                            choice.id
                                                                                        }
                                                                                    >
                                                                                        <Radio
                                                                                            id='preview'
                                                                                            name={
                                                                                                choice.id
                                                                                            }
                                                                                            isChecked={
                                                                                                choice.isSelected
                                                                                            }
                                                                                            value={`${choiceIndex}-${choice.id}`}
                                                                                            isReadOnly={
                                                                                                true
                                                                                            }
                                                                                        >
                                                                                            {
                                                                                                choice
                                                                                                    .title[
                                                                                                    language
                                                                                                ]
                                                                                            }
                                                                                        </Radio>
                                                                                    </div>
                                                                                )
                                                                            )}
                                                                        </div>
                                                                    )}
                                                                    {formField.fieldType ===
                                                                        'text' && (
                                                                        <div
                                                                            className={
                                                                                style.formFieldPreview
                                                                            }
                                                                        >
                                                                            <input
                                                                                type='text'
                                                                                readOnly
                                                                            />
                                                                        </div>
                                                                    )}
                                                                    {formField.fieldType ===
                                                                        'textarea' && (
                                                                        <div
                                                                            className={
                                                                                style.formFieldPreview
                                                                            }
                                                                        >
                                                                            <textarea
                                                                                readOnly
                                                                            />
                                                                        </div>
                                                                    )}
                                                                    {formField.fieldType ===
                                                                        'dropdown' && (
                                                                        <div
                                                                            className={
                                                                                style.formFieldPreview
                                                                            }
                                                                        >
                                                                            <select
                                                                                value='0'
                                                                                readOnly
                                                                            >
                                                                                {formField.choices.map(
                                                                                    (
                                                                                        choice,
                                                                                        choiceIndex
                                                                                    ) => (
                                                                                        <option
                                                                                            value={`${choiceIndex}`}
                                                                                            key={
                                                                                                choice.id
                                                                                            }
                                                                                        >
                                                                                            {
                                                                                                choice
                                                                                                    .title[
                                                                                                    language
                                                                                                ]
                                                                                            }
                                                                                        </option>
                                                                                    )
                                                                                )}
                                                                            </select>
                                                                        </div>
                                                                    )}
                                                                    {formField.fieldType ===
                                                                        'checkboxes' && (
                                                                        <div
                                                                            className={
                                                                                style.formFieldPreview
                                                                            }
                                                                        >
                                                                            {formField.choices.map(
                                                                                (
                                                                                    choice,
                                                                                    choiceIndex
                                                                                ) => (
                                                                                    <div
                                                                                        key={
                                                                                            choice.id
                                                                                        }
                                                                                    >
                                                                                        <TilkynnaCheckbox
                                                                                            isChecked={
                                                                                                choice.isSelected
                                                                                            }
                                                                                        >
                                                                                            {
                                                                                                choice
                                                                                                    .title[
                                                                                                    language
                                                                                                ]
                                                                                            }
                                                                                        </TilkynnaCheckbox>
                                                                                    </div>
                                                                                )
                                                                            )}
                                                                        </div>
                                                                    )}

                                                                    <div
                                                                        className={
                                                                            style.formFieldItemActions
                                                                        }
                                                                    >
                                                                        <Button
                                                                            variant='outlined'
                                                                            size='small'
                                                                            onClick={() =>
                                                                                navigate(
                                                                                    `${formField.id}`
                                                                                )
                                                                            }
                                                                        >
                                                                            {
                                                                                localization[
                                                                                    language
                                                                                ]
                                                                                    .app
                                                                                    .labelEdit
                                                                            }
                                                                        </Button>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </Draggable>
                                                    )
                                                )}
                                                {provided.placeholder}
                                            </div>
                                        )}
                                    </Droppable>
                                </DragDropContext>
                            )}

                        <Button
                            variant='contained'
                            onClick={() =>
                                navigate(
                                    `/app/${organisationId}/form/${groupId}/create-field`
                                )
                            }
                            color='primary'
                            disabled={accessToken == null}
                        >
                            {localization[language].formEditor.createNewField}
                        </Button>
                    </CardContent>
                    {accessToken === null && (
                        <CardContent>
                            <CircularProgress />
                        </CardContent>
                    )}
                </Card>
            </CardContent>
        </Card>
    );
};

export default React.memo(FormGroupFields);
