// extracted by mini-css-extract-plugin
export var btnAttachment = "index-module--btnAttachment--cENYU";
export var contentActions = "index-module--contentActions--p6Mo7";
export var contentCard = "index-module--contentCard--K0wgA";
export var contentPage = "index-module--contentPage--mjIBr";
export var dangerZone = "index-module--dangerZone--BN5C6";
export var file = "index-module--file--2Qarg";
export var fileList = "index-module--fileList--9lEhW";
export var fullSize = "index-module--fullSize--otd5h";
export var info = "index-module--info--Z3Dsb";
export var isDragging = "index-module--isDragging--yGsRn";
export var item = "index-module--item--ZwuKA";
export var label = "index-module--label--lQkAO";
export var organisationText = "index-module--organisationText--Sboxe";
export var pageItem = "index-module--pageItem--7q3pm";
export var pageList = "index-module--pageList--UJc9I";
export var preview = "index-module--preview--yO9II";
export var settingsCard = "index-module--settingsCard--S924Q";