import React from 'react';
import axios from 'axios';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button,
    Chip,
    CircularProgress,
} from '@mui/material';
import localization from '../../../localization';
import config from '../../../config';
import SimpleDate from '../../SimpleDate';
import ReportInvite from './ReportInvite';
import * as style from './index.module.scss';

interface Props {
    accessToken: string;
    organisationId: string;
    report: any;
    language: string;
    links: any;
    setLinks: any;
    canManageReports: any;
}

const ReportSharing = ({
    accessToken,
    organisationId,
    report,
    language,
    links,
    setLinks,
    canManageReports,
}: Props) => {
    const [hasFetchedInitial, setHasFetchedInitial] = React.useState(false);
    const [isFetching, setIsFetching] = React.useState(false);
    const [isDeleting, setIsDeleting] = React.useState(false);

    const getLinksAsync = async () => {
        setIsFetching(true);
        try {
            const response = await axios.get(
                `${config.apiBaseUrl}/organisation/${organisationId}/report/${report.id}/links`,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            );
            setLinks(response.data.links);
        } catch (err) {
            console.log(err);
        }
        setIsFetching(false);
    };

    React.useEffect(() => {
        if (!hasFetchedInitial && report != null && canManageReports) {
            setHasFetchedInitial(true);
            getLinksAsync();
        }
    }, [hasFetchedInitial, report, canManageReports]);

    const deleteLinkAsync = async (link: any) => {
        setIsDeleting(true);
        try {
            const response = await axios.delete(
                `${config.apiBaseUrl}/organisation/${organisationId}/report/${link.reportId}/link/${link.id}`,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            );

            // console.log(response.data.links)
            setLinks(response.data.links);
        } catch (err) {}
        setIsDeleting(false);
    };

    return (
        <div className={style.linksPage}>
            <h2>{localization[language].app.labelSharing}</h2>
            {canManageReports && (
                <ReportInvite
                    language={language}
                    report={report}
                    organisationId={organisationId}
                    accessToken={accessToken}
                    title={localization[language].app.inviteUserToParticipate}
                    info={localization[language].app.inviteInfo}
                    setLinks={setLinks}
                    links={links}
                />
            )}
            <TableContainer
                component={Paper}
                variant='outlined'
                className={style.reportSharingCard}
            >
                <Table aria-label='table'>
                    <TableHead>
                        <TableRow>
                            <TableCell align='left'>
                                {localization[language].app.labelActions}
                            </TableCell>
                            <TableCell align='left'>
                                {
                                    localization[language].app
                                        .labelRecipientFullName
                                }
                            </TableCell>
                            <TableCell align='left'>
                                {localization[language].app.labelRole}
                            </TableCell>
                            <TableCell align='left'>
                                {localization[language].app.labelCreatedBy}
                            </TableCell>
                            <TableCell align='left'>
                                {localization[language].app.labelLastUsed}
                            </TableCell>
                            <TableCell align='left'>
                                {localization[language].app.labelCreated}
                            </TableCell>
                            <TableCell align='left'>
                                {localization[language].app.labelActive}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {links.map((link: any) => (
                            <TableRow key={link.id}>
                                <TableCell align='left'>
                                    <Button
                                        size='small'
                                        onClick={() => deleteLinkAsync(link)}
                                        disabled={isDeleting}
                                    >
                                        {localization[language].app.labelDelete}
                                    </Button>
                                </TableCell>
                                <TableCell align='left'>{link.name}</TableCell>
                                <TableCell align='left' className={style.roles}>
                                    {link.canWriteComment && (
                                        <div className={style.role}>
                                            <Chip
                                                variant='outlined'
                                                size='small'
                                                label={
                                                    localization[language]
                                                        .report.writeComment
                                                }
                                            />
                                        </div>
                                    )}
                                    {link.canReadComments && (
                                        <div className={style.role}>
                                            <Chip
                                                variant='outlined'
                                                size='small'
                                                label={
                                                    localization[language]
                                                        .report.readComments
                                                }
                                            />
                                        </div>
                                    )}
                                    {link.canReadReport && (
                                        <div className={style.role}>
                                            <Chip
                                                variant='outlined'
                                                size='small'
                                                label={
                                                    localization[language]
                                                        .report.readReport
                                                }
                                            />
                                        </div>
                                    )}
                                </TableCell>
                                <TableCell align='left'>
                                    {link.fromName}
                                </TableCell>
                                <TableCell align='left'>
                                    <SimpleDate
                                        dateString={link.lastUsed}
                                        nullValue={
                                            localization[language].app
                                                .labelNotUsed
                                        }
                                    />
                                </TableCell>
                                <TableCell align='left'>
                                    <SimpleDate dateString={link.created} />
                                </TableCell>
                                <TableCell align='left'>
                                    {link.isExpired ||
                                    (link.isOneTimeLink &&
                                        link.lastUsed != null)
                                        ? localization[language].form.labelNo
                                        : localization[language].form.labelYes}
                                </TableCell>
                            </TableRow>
                        ))}
                        {(accessToken == null || isFetching) && (
                            <TableRow>
                                <TableCell>
                                    <CircularProgress />
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

export default React.memo(ReportSharing);
