import React from 'react';
import { Router } from '@reach/router';

import localization from '../../../localization';

import Files from './Files';
import SEO from '../../seo';
import Pages from './Pages';
import Page from './Page';
import { useAppSelector } from '../../../hooks';
import * as style from './index.module.scss';

interface Props {
    language: string;
    organisation: any;
    content: any;
    setContent: any;
    organisationId: string;
}

const Content = ({
    language,
    organisation,
    content,
    setContent,
    organisationId,
}: Props) => {
    const accessToken = useAppSelector(
        (state) => state.global.auth.accessToken
    );

    return (
        <div className={style.contentPage}>
            <SEO title={localization[language].app.content} />
            <h2>{localization[language].app.content}</h2>

            <Router>
                <Pages
                    default
                    organisationId={organisationId}
                    organisation={organisation}
                    content={content}
                    setContent={setContent}
                    acceessToken={accessToken}
                    language={language}
                />
                <Page
                    path='/pages/:pageId/*'
                    content={content}
                    setContent={setContent}
                    organisation={organisation}
                    organisationId={organisationId}
                    language={language}
                />
                <Page
                    path='/create-page'
                    content={content}
                    setContent={setContent}
                    organisation={organisation}
                    organisationId={organisationId}
                    language={language}
                    isCreatingPage={true}
                />
            </Router>

            <Files
                accessToken={accessToken}
                language={language}
                organisation={organisation}
                setContent={setContent}
                content={content}
            />
        </div>
    );
};

export default Content;
