import React from 'react';
import { format, subMonths, differenceInBusinessDays } from 'date-fns';
import {
    Bar,
    BarChart,
    Cell,
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    PieChart,
    Pie,
} from 'recharts';
import { Card, CardContent, Grid, CircularProgress } from '@mui/material';

import localization from '../../../localization';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/pro-solid-svg-icons';
import {
    faBusinessTime,
    faChartLine,
    faChartLineDown,
    faCommentsAlt,
    faEnvelope,
    faEnvelopeOpenText,
    faPauseCircle,
} from '@fortawesome/pro-light-svg-icons';

import * as style from './index.module.scss';
import { Content } from '../../../interfaces/Content';

const CustomTooltip = ({ active, payload, label }) => {
    // console.log(label, payload)
    if (active && payload && payload.length) {
        return (
            <div className={style.customTooltip}>
                <p className='label'>{`${payload[0].payload.name}: ${payload[0].value}`}</p>
            </div>
        );
    }

    return null;
};

const randomColors = ['#64b5f6', '#e57373', '#ffb74d', '#81c784'];

interface Props {
    users: any;
    analytics: any;
    language: string;
    content: Content;
    numUnreadMessages: number;
    numUnreadComments: number;
    numOpenReports: number;
    numNewReports: number;
    numPendingReports: number;
}

const Dashboard = ({
    users,
    analytics,
    language,
    content,
    numUnreadMessages,
    numUnreadComments,
    numOpenReports,
    numNewReports,
    numPendingReports,
}: Props) => {
    const [graphData, setGraphData] = React.useState<any>([]);
    const [graphWidth, setGraphWidth] = React.useState(100);
    const [totalLabels, setTotalLabels] = React.useState<any>([]);
    const [totalAssignedUsers, setTotalAssignedUsers] = React.useState<any>([]);
    const [totalClosedReasons, setTotalClosedReasons] = React.useState<any>([]);
    const [totalClosed, setTotalClosed] = React.useState(0);
    const [avgDays, setAvgDays] = React.useState(0);
    const [year, setYear] = React.useState();
    // const [fromTime, setFromTime] = React.useState(new Date())

    const graphContainerRef = React.useRef();

    const resizeListener = React.useCallback(() => {
        // change width from the state object
        if (graphContainerRef.current != null) {
            setGraphWidth(graphContainerRef.current.clientWidth - 35);
        }
        // console.log(`Width is ${(graphContainerRef.current.clientWidth - 35)}`)
    }, [graphContainerRef.current]);

    React.useEffect(() => {
        if (analytics != null && content != null && users != null) {
            // setRecords(analytics.records)

            if (analytics.records != null) {
                processData(analytics.records);
            }
        }
    }, [analytics, content, users, language]);

    const processData = (records: any[]) => {
        const dict: any = {};
        let numTotalClosed = 0;
        let numTotalNotClosed = 0;
        let sumDaysToClosed = 0;
        const labelsDict: any = {};
        const reportLabelsDict: any = {};
        const assignedUsersDict: any = {};
        const closedReasonsDict: any = {
            closed_insufficient_info: 0,
            closed_spam: 0,
            closed_invalid_report: 0,
            closed_resolved: 0,
        };

        for (let i = 0; i < content.reportLabels.length; i++) {
            labelsDict[content.reportLabels[i].id] = 0;
            reportLabelsDict[content.reportLabels[i].id] = {
                ...content.reportLabels[i],
            };
        }

        for (let i = 0; i < users.length; i++) {
            if (
                (users[i].role === 'admin' || users[i].role === 'manage') &&
                !users[i].isPendingInvitation
            ) {
                assignedUsersDict[users[i].id] = { ...users[i], num: 0 };
            }
        }

        assignedUsersDict['unassigned'] = {
            id: 'unassigned',
            num: 0,
            name: localization[language].app.numUnassignedUsers,
            email: '',
        };

        for (let i = 0; i < records.length; i++) {
            const dtCreated = new Date(records[i].received);
            let dtClosed = null;

            if (records[i].closed != null) {
                dtClosed = new Date(records[i].closed);

                numTotalClosed++;
                sumDaysToClosed += differenceInBusinessDays(
                    dtClosed,
                    dtCreated
                );

                if (records[i].statusClosed != null) {
                    if (closedReasonsDict[records[i].statusClosed] == null) {
                        closedReasonsDict[records[i].statusClosed] = 0;
                    }
                    closedReasonsDict[records[i].statusClosed]++;
                }
            } else {
                numTotalNotClosed++;
                sumDaysToClosed += differenceInBusinessDays(
                    new Date(),
                    dtCreated
                );
            }

            if (
                records[i].assignedLabelIds != null &&
                records[i].assignedLabelIds.length > 0
            ) {
                for (let x = 0; x < records[i].assignedLabelIds.length; x++) {
                    if (labelsDict[records[i].assignedLabelIds[x]] != null) {
                        labelsDict[records[i].assignedLabelIds[x]]++;
                    }
                }
            }

            if (
                records[i].assignedUserIds != null &&
                records[i].assignedUserIds.length > 0
            ) {
                for (let x = 0; x < records[i].assignedUserIds.length; x++) {
                    if (
                        assignedUsersDict[records[i].assignedUserIds[x]] != null
                    ) {
                        assignedUsersDict[records[i].assignedUserIds[x]].num++;
                    } else {
                        assignedUsersDict['unassigned'].num++;
                    }
                }
            } else {
                assignedUsersDict['unassigned'].num++;
            }

            let dateKeyCreated = `${dtCreated.getUTCFullYear()}-${
                dtCreated.getUTCMonth() + 1
            }`;
            let dateKeyClosed = `${dtCreated.getUTCFullYear()}-${
                dtCreated.getUTCMonth() + 1
            }`;

            if (dict[dateKeyCreated] == null) {
                dict[dateKeyCreated] = {
                    numCreated: 0,
                    numClosed: 0,
                };
            }

            if (dateKeyClosed != null && dict[dateKeyClosed] == null) {
                dict[dateKeyClosed] = {
                    numCreated: 0,
                    numClosed: 0,
                };
            }

            dict[dateKeyCreated].numCreated++;

            if (dtClosed != null) {
                dict[dateKeyClosed].numClosed++;
            }
        }

        let data = [];
        for (let i = 5; i >= 0; i--) {
            let dt = subMonths(new Date(), i);
            let key = `${dt.getUTCFullYear()}-${dt.getUTCMonth() + 1}`;
            data.push({
                name: format(dt, 'MMM yyyy'),
                numCreated: dict[key] != null ? dict[key].numCreated : 0,
                // numOpened: dict[key] != null ? dict[key].numOpened : 0,
                numClosed: dict[key] != null ? dict[key].numClosed : 0,
            });
        }

        setGraphData(data);
        const processedLabels: any = [];
        const processedClosedReasons: any = [];
        const processedAssignedUsers: any = [];

        if (content.reportLabels != null) {
            // for (let i = 0; i < content.reportLabels.length; i++) {
            //     // console.log(content.reportLabels[i].id)
            //     reportLabelsDict[content.reportLabels[i].id] = content.reportLabels[i]
            // }

            Object.keys(labelsDict).forEach((labelId) => {
                let nameShort =
                    reportLabelsDict[labelId].title[language] ||
                    reportLabelsDict[labelId].title.en ||
                    reportLabelsDict[labelId].title.is ||
                    '';

                if (nameShort.length > 6) {
                    nameShort = nameShort.substr(0, 6) + '..';
                }
                processedLabels.push({
                    name:
                        reportLabelsDict[labelId].title[language] ||
                        reportLabelsDict[labelId].title.en ||
                        reportLabelsDict[labelId].title.is ||
                        '',
                    nameShort,
                    id: labelId,
                    color: reportLabelsDict[labelId].color || '#333333',
                    num: labelsDict[labelId],
                });
            });

            Object.keys(assignedUsersDict).forEach((userId) => {
                let nameShort = assignedUsersDict[userId].name || '';
                if (nameShort.length > 0) {
                    nameShort = nameShort
                        .split(' ')
                        .map((i) => (i.length > 0 ? i[0].toUpperCase() : ''))
                        .join('');
                }
                processedAssignedUsers.push({
                    name: assignedUsersDict[userId].name || '',
                    email: assignedUsersDict[userId].email || '',
                    nameShort,
                    id: assignedUsersDict[userId].id,
                    num: assignedUsersDict[userId].num,
                    color: userId === 'unassigned' ? '#e57373' : '#0277bc',
                });
            });

            Object.keys(closedReasonsDict).forEach((closedReason) => {
                processedClosedReasons.push({
                    name: localization[language].reportClosed[closedReason],
                    id: closedReason,
                    num: closedReasonsDict[closedReason],
                });
            });
        }

        processedLabels.sort((a, b) => b.num - a.num);
        processedAssignedUsers.sort((a, b) => b.num - a.num);

        setTotalLabels(processedLabels);
        setTotalAssignedUsers(processedAssignedUsers);
        setTotalClosedReasons(processedClosedReasons);
        setTotalClosed(numTotalClosed);

        if (numTotalClosed + numTotalNotClosed === 0) {
            setAvgDays(0);
        } else {
            const avgDaysNotRounded =
                sumDaysToClosed / (numTotalClosed + numTotalNotClosed);
            setAvgDays(Math.round(avgDaysNotRounded * 10) / 10);
        }
    };

    React.useEffect(() => {
        // set resize listener
        window.addEventListener('resize', resizeListener);
        resizeListener();
        // clean up function
        return () => {
            // remove resize listener
            window.removeEventListener('resize', resizeListener);
        };
    }, [resizeListener]);

    return (
        <div className={style.dashboardPage}>
            <h2>{localization[language].app.labelDashboard}</h2>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <Card
                                style={{ minHeight: 244 }}
                                className={style.graphNumbers}
                                variant='outlined'
                            >
                                <CardContent>
                                    <h3>
                                        {
                                            localization[language].app
                                                .currentSituation
                                        }
                                    </h3>

                                    <p>
                                        <FontAwesomeIcon
                                            icon={faEnvelope}
                                            fixedWidth
                                        />
                                        {
                                            localization[language].app
                                                .labelNumNewReports
                                        }
                                        <span>
                                            {analytics == null
                                                ? '...'
                                                : numNewReports}
                                        </span>
                                    </p>
                                    <p>
                                        <FontAwesomeIcon
                                            icon={faEnvelopeOpenText}
                                            fixedWidth
                                        />
                                        {
                                            localization[language].app
                                                .labelNumOpenReports
                                        }
                                        <span>
                                            {' '}
                                            {analytics == null
                                                ? '...'
                                                : numOpenReports}
                                        </span>
                                    </p>
                                    <p>
                                        <FontAwesomeIcon
                                            icon={faPauseCircle}
                                            fixedWidth
                                        />
                                        {
                                            localization[language].app
                                                .labelNumPendingReports
                                        }
                                        <span>
                                            {' '}
                                            {analytics == null
                                                ? '...'
                                                : numPendingReports}
                                        </span>
                                    </p>
                                    <p>
                                        <FontAwesomeIcon
                                            icon={faCommentsAlt}
                                            fixedWidth
                                        />
                                        {
                                            localization[language].app
                                                .labelNumUnreadMessages
                                        }
                                        <span>
                                            {' '}
                                            {analytics == null
                                                ? '...'
                                                : numUnreadMessages}
                                        </span>
                                    </p>
                                    <p>
                                        <FontAwesomeIcon
                                            icon={faCommentsAlt}
                                            fixedWidth
                                        />
                                        {
                                            localization[language].app
                                                .labelNumUnreadComments
                                        }
                                        <span>
                                            {' '}
                                            {analytics == null
                                                ? '...'
                                                : numUnreadComments}
                                        </span>
                                    </p>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Card
                                style={{ minHeight: 244 }}
                                className={style.graphNumbers}
                                variant='outlined'
                            >
                                <CardContent>
                                    <h3>
                                        {
                                            localization[language].app
                                                .labelPrimaryNumbers
                                        }{' '}
                                        {year}
                                    </h3>

                                    {analytics == null ? (
                                        <CircularProgress />
                                    ) : (
                                        <>
                                            <p>
                                                <FontAwesomeIcon
                                                    icon={faChartLine}
                                                    fixedWidth
                                                />
                                                {
                                                    localization[language].app
                                                        .totalNumberOfReports
                                                }
                                                <span>
                                                    {analytics.records.length}
                                                </span>
                                            </p>
                                            <p>
                                                <FontAwesomeIcon
                                                    icon={faChartLineDown}
                                                    fixedWidth
                                                />
                                                {
                                                    localization[language].app
                                                        .totalClosedReports
                                                }
                                                <span>{totalClosed}</span>
                                            </p>
                                            <p>
                                                <FontAwesomeIcon
                                                    icon={faBusinessTime}
                                                    fixedWidth
                                                />
                                                {
                                                    localization[language].app
                                                        .handlingTime
                                                }
                                                <span>{avgDays}</span>
                                            </p>
                                        </>
                                    )}
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Card style={{ minHeight: 420 }} variant='outlined'>
                        <CardContent ref={graphContainerRef}>
                            <h3>
                                {
                                    localization[language].app
                                        .labelGraphCreatedOpenedClosed
                                }
                            </h3>
                            <div style={{ height: 310 }}>
                                {analytics == null ? (
                                    <CircularProgress />
                                ) : (
                                    <LineChart
                                        style={{ position: 'absolute' }}
                                        width={graphWidth}
                                        height={300}
                                        data={graphData}
                                        margin={{
                                            top: 0,
                                            right: 25,
                                            left: 10,
                                            bottom: 0,
                                        }}
                                    >
                                        <XAxis
                                            dataKey='name'
                                            width={graphWidth}
                                        />
                                        <YAxis
                                            name='Number of Reports'
                                            width={20}
                                            allowDecimals={false}
                                        />
                                        <Tooltip />
                                        {/* <Legend /> */}
                                        <CartesianGrid
                                            stroke='#eee'
                                            strokeDasharray='5 5'
                                        />
                                        <Line
                                            type='monotone'
                                            dataKey='numCreated'
                                            stroke='#0277bc'
                                            name={
                                                localization[language].app
                                                    .numReceived
                                            }
                                        />
                                        {/* <Line type="monotone" dataKey="numOpened" stroke="#ca8282" name={localization[language].app.graphOpened} /> */}
                                        <Line
                                            type='monotone'
                                            dataKey='numClosed'
                                            stroke='#82ca9d'
                                            name={
                                                localization[language].app
                                                    .graphClosed
                                            }
                                        />
                                    </LineChart>
                                )}
                            </div>
                            <p style={{ margin: 0, textAlign: 'center' }}>
                                <span
                                    style={{
                                        color: '#0277bc',
                                        paddingRight: 5,
                                    }}
                                >
                                    <FontAwesomeIcon
                                        icon={faCircle}
                                        fixedWidth
                                        style={{ transform: 'scale(.5)' }}
                                    />{' '}
                                    {localization[language].app.numReceived}
                                </span>
                                <span
                                    style={{ color: '#82ca9d', paddingLeft: 5 }}
                                >
                                    <FontAwesomeIcon
                                        icon={faCircle}
                                        fixedWidth
                                        style={{ transform: 'scale(.5)' }}
                                    />{' '}
                                    {localization[language].app.graphClosed}
                                </span>
                            </p>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Card style={{ minHeight: 420 }} variant='outlined'>
                        <CardContent>
                            <h3>{localization[language].app.numLabelsUsed}</h3>
                            {totalLabels.length === 0 && (
                                <p>{localization[language].app.noLabels}</p>
                            )}
                            <div
                                style={{
                                    height:
                                        totalLabels.length === 0 ? 270 : 310,
                                }}
                            >
                                {content == null ||
                                content.reportLabels == null ? (
                                    <CircularProgress />
                                ) : (
                                    <BarChart
                                        data={totalLabels}
                                        margin={{
                                            top: 4,
                                            right: 0,
                                            left: 0,
                                            bottom: 4,
                                        }}
                                        width={graphWidth}
                                        height={
                                            totalLabels.length === 0 ? 250 : 300
                                        }
                                    >
                                        <CartesianGrid strokeDasharray='3 3' />
                                        <XAxis
                                            hide={totalLabels.length === 0}
                                            fontSize={12}
                                            dx={0}
                                            dy={5}
                                            dataKey='nameShort'
                                            minTickGap={-100}
                                        />
                                        <YAxis allowDecimals={false} />
                                        <Tooltip content={<CustomTooltip />} />
                                        <Bar dataKey='num'>
                                            {totalLabels.map((reportLabel) => (
                                                <Cell
                                                    key={reportLabel.id}
                                                    fill={reportLabel.color}
                                                />
                                            ))}
                                        </Bar>
                                    </BarChart>
                                )}
                            </div>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Card variant='outlined'>
                        <CardContent>
                            <h3>
                                {localization[language].app.numAssignedUsers}
                            </h3>
                            <div style={{ height: 310 }}>
                                {content == null ||
                                content.reportLabels == null ? (
                                    <CircularProgress />
                                ) : (
                                    <BarChart
                                        data={totalAssignedUsers}
                                        margin={{
                                            top: 4,
                                            right: 0,
                                            left: 0,
                                            bottom: 4,
                                        }}
                                        width={graphWidth}
                                        height={300}
                                    >
                                        <CartesianGrid strokeDasharray='3 3' />
                                        <XAxis
                                            hide={
                                                totalAssignedUsers.length === 0
                                            }
                                            fontSize={12}
                                            dx={0}
                                            dy={5}
                                            dataKey='nameShort'
                                            minTickGap={-100}
                                        />
                                        <YAxis allowDecimals={false} />
                                        <Tooltip content={<CustomTooltip />} />
                                        <Bar dataKey='num'>
                                            {totalAssignedUsers.map((user) => (
                                                <Cell
                                                    key={user.id}
                                                    fill={user.color}
                                                    // fill={user.color}
                                                />
                                            ))}
                                        </Bar>
                                    </BarChart>
                                )}
                            </div>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Card variant='outlined'>
                        <CardContent>
                            <h3>
                                {localization[language].app.reportsClosedBy}
                            </h3>
                            <div style={{ height: 262 }}>
                                {content == null ||
                                content.reportLabels == null ? (
                                    <CircularProgress />
                                ) : (
                                    <PieChart width={graphWidth} height={250}>
                                        <Pie
                                            dataKey='num'
                                            isAnimationActive={false}
                                            data={totalClosedReasons}
                                            cx='50%'
                                            cy='50%'
                                            outerRadius={80}
                                            fill='#8884d8'
                                            label
                                            labelLine={false}
                                            innerRadius={60}
                                        >
                                            {totalClosedReasons.map(
                                                (reason, index) => (
                                                    <Cell
                                                        key={reason.id}
                                                        fill={
                                                            randomColors[index]
                                                        }
                                                    />
                                                )
                                            )}
                                        </Pie>
                                        <Tooltip />
                                    </PieChart>
                                )}
                            </div>
                            <p
                                style={{
                                    textAlign: 'center',
                                    fontSize: '.9rem',
                                }}
                            >
                                {totalClosedReasons.map(
                                    (reason: any, index: number) => (
                                        <span
                                            key={reason.id}
                                            style={{
                                                color: randomColors[index],
                                                paddingRight: 5,
                                            }}
                                        >
                                            <FontAwesomeIcon
                                                icon={faCircle}
                                                fixedWidth
                                                style={{
                                                    transform: 'scale(.5)',
                                                }}
                                            />{' '}
                                            {
                                                localization[language]
                                                    .reportClosed[reason.id]
                                            }
                                            : {reason.num}
                                        </span>
                                    )
                                )}
                            </p>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
};

export default React.memo(Dashboard);
