import React from 'react';
import axios from 'axios';
import classNames from 'classnames';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {
    Button,
    Card,
    CardContent,
    CircularProgress,
    FormHelperText,
} from '@mui/material';
import localization from '../../../localization';
import config from '../../../config';
import * as style from './index.module.scss';

import { navigate } from 'gatsby-link';

interface Props {
    language: string;
    organisationId: string;
    content: any;
    setContent: any;
    accessToken: string;
}

const Pages = ({
    language,
    organisationId,
    content,
    setContent,
    accessToken,
}: Props) => {
    const languages = [
        { short: 'is', title: 'Íslenska' },
        { short: 'en', title: 'English' },
    ];
    const [isSavingContent, setIsSavingContent] = React.useState(false);
    const refIsRemoved = React.useRef(false);

    // const [isCreatingNewPage, setIsCreatingNewPage] = React.useState(false)
    // const [selectedPage, setSelectedPage] = React.useState(null)

    React.useEffect(() => {
        return () => {
            refIsRemoved.current = true;
        };
    }, []);

    const onDragEndHandler = async (res) => {
        if (
            !res ||
            !res.destination ||
            res.destination.index === res.source.index
        ) {
            return;
        }
        let updatedPages = [...content.pages];

        const [removed] = updatedPages.splice(res.source.index, 1);
        updatedPages.splice(res.destination.index, 0, removed);

        setContent({
            ...content,
            pages: updatedPages,
        });

        try {
            setIsSavingContent(true);
            await axios.post(
                `${config.apiBaseUrl}/organisation/${organisationId}/content/changeorder/${res.source.index}/${res.destination.index}`,
                null,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            );
        } catch (err) {
            console.log(err);
        }
        setIsSavingContent(false);
    };

    return (
        <Card className={style.item} variant='outlined'>
            <CardContent>
                <h3>{localization[language].content.pages}</h3>

                {content.pages.length > 0 && (
                    <>
                        <DragDropContext onDragEnd={onDragEndHandler}>
                            <Droppable droppableId='droppable'>
                                {(provided, snapshot) => (
                                    <div
                                        className={style.pageList}
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                    >
                                        <FormHelperText
                                            style={{ marginBottom: 10 }}
                                        >
                                            {
                                                localization[language].content
                                                    .dragAndDropHint
                                            }
                                        </FormHelperText>
                                        {content.pages.map(
                                            (page: any, index: number) => (
                                                <Draggable
                                                    key={page.id}
                                                    draggableId={page.id}
                                                    index={index}
                                                    isDragDisabled={
                                                        isSavingContent
                                                    }
                                                >
                                                    {(provided, snapshot) => (
                                                        <div
                                                            className={classNames(
                                                                style.pageItem,
                                                                snapshot.isDragging &&
                                                                    style.isDragging
                                                            )}
                                                            ref={
                                                                provided.innerRef
                                                            }
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            style={{
                                                                ...provided
                                                                    .draggableProps
                                                                    .style,
                                                            }}
                                                        >
                                                            {languages.map(
                                                                (l, i) => (
                                                                    <h3
                                                                        key={
                                                                            l.short
                                                                        }
                                                                    >
                                                                        <strong>
                                                                            {
                                                                                l.short
                                                                            }
                                                                        </strong>{' '}
                                                                        {
                                                                            page
                                                                                .title[
                                                                                l
                                                                                    .short
                                                                            ]
                                                                        }
                                                                    </h3>
                                                                )
                                                            )}

                                                            {page.isPrivacyPolicy && (
                                                                <p
                                                                    className={
                                                                        style.info
                                                                    }
                                                                >
                                                                    <i>
                                                                        {
                                                                            localization[
                                                                                language
                                                                            ]
                                                                                .content
                                                                                .isPrivacyPolicy
                                                                        }
                                                                    </i>
                                                                </p>
                                                            )}
                                                            {page.hidden && (
                                                                <p
                                                                    className={
                                                                        style.info
                                                                    }
                                                                >
                                                                    <i>
                                                                        {
                                                                            localization[
                                                                                language
                                                                            ]
                                                                                .content
                                                                                .pageIsHidden
                                                                        }
                                                                    </i>
                                                                </p>
                                                            )}

                                                            <div
                                                                className={
                                                                    style.pageItemActions
                                                                }
                                                            >
                                                                <Button
                                                                    variant='outlined'
                                                                    size='small'
                                                                    onClick={() =>
                                                                        navigate(
                                                                            `pages/${content.pages[index].id}`
                                                                        )
                                                                    }
                                                                >
                                                                    {
                                                                        localization[
                                                                            language
                                                                        ].app
                                                                            .labelEdit
                                                                    }
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Draggable>
                                            )
                                        )}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </>
                )}

                <Button
                    variant='outlined'
                    onClick={() => navigate(`create-page`)}
                    color='primary'
                    disabled={accessToken === null}
                >
                    {localization[language].content.createPage}
                </Button>
            </CardContent>
            {accessToken === null && (
                <CardContent>
                    <CircularProgress />
                </CardContent>
            )}
        </Card>
    );
};

export default React.memo(Pages);
