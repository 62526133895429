import React from 'react';
import axios from 'axios';
import classNames from 'classnames';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {
    Button,
    Card,
    FormControlLabel,
    DialogTitle,
    DialogContent,
    DialogActions,
    Dialog,
    CircularProgress,
    FormHelperText,
    Paper,
    Menu,
    MenuItem,
    ListItemIcon,
    CardHeader,
    IconButton,
    Switch,
    CardContent,
    CardActions,
    Grid,
    Checkbox,
} from '@mui/material';
import localization from '../../../localization';

import config from '../../../config';

import {
    ArrowDropDown,
    ArrowDropDownCircle,
    ArrowRight,
    CheckBox,
    CheckBoxOutlineBlank,
    CheckBoxOutlined,
    CloudUpload,
    DeleteForever,
    Edit,
    RadioButtonChecked,
    RadioButtonUnchecked,
    ShortText,
    Subject,
} from '@mui/icons-material';
import { navigate } from 'gatsby-link';
import { Link } from 'gatsby';
import { useAppSelector } from '../../../hooks';
import * as style from './index.module.scss';

const fieldInitialState = {
    groupId: null,
    choices: null,
    fieldType: 'text',
    excludedReportAreaIds: [],
    required: false,
    title: {
        is: ``,
        en: ``,
    },
    tooltip: {
        is: ``,
        en: ``,
    },
};

interface Props {
    language: string;
    organisationId: string;
    organisation: any;
    content: any;
    setContent: any;
    selectedGroup: any;
    setSelectedGroup: any;
    isCreatingField: any;
    fieldId: any;
    groupId: string;
}

const FormFieldEditor = ({
    language,
    organisationId,
    organisation,
    content,
    setContent,
    selectedGroup,
    setSelectedGroup,
    isCreatingField,
    fieldId,
    groupId,
}: Props) => {
    const accessToken = useAppSelector(
        (state) => state.global.auth.accessToken
    );

    const languages = [
        { short: 'is', title: 'Íslenska' },
        { short: 'en', title: 'English' },
    ];
    const [isSavingContent, setIsSavingContent] = React.useState(false);
    const refIsRemoved = React.useRef(false);

    const [showDeleteDialog, setShowDeleteDialog] = React.useState(false);
    const [selectedChoice, setSelectedChoice] = React.useState<any | null>(
        null
    );
    // const [isCreatingField, setIsCreatingField] = React.useState(false)
    const [selectedField, setSelectedField] = React.useState<any | null>(null);

    // Dialogs
    const [showChoiceDialog, setShowChoiceDialog] = React.useState(false);

    // Menu
    const [anchorElement, setAnchorElement] = React.useState<any | null>(null);

    React.useEffect(() => {
        return () => {
            refIsRemoved.current = true;
        };
    }, []);

    React.useLayoutEffect(() => {
        if (isCreatingField) {
            let fieldId = new Date().getTime().toString();
            // setIsCreatingField(true)
            setSelectedField({
                id: fieldId,
                groupId: null,
                choices: null,
                fieldType: 'text',
                excludedReportAreaIds: [],
                required: false,
                title: {
                    is: ``,
                    en: ``,
                },
                tooltip: {
                    is: ``,
                    en: ``,
                },
            });
        }
    }, [isCreatingField]);

    // Fetch the selected group if it's missing
    React.useLayoutEffect(() => {
        if (
            selectedGroup === null &&
            groupId != null &&
            content != null &&
            content.groups != null &&
            !content.isFetching
        ) {
            let found = false;
            for (let i = 0; i < content.groups.length; i++) {
                if (content.groups[i].id === groupId) {
                    setSelectedGroup(content.groups[i]);
                    found = true;
                    break;
                }
            }

            if (!found) {
                navigate(`/app/${organisationId}/form/`);
            }
        }
    }, [selectedGroup, groupId, content, organisationId]);

    React.useLayoutEffect(() => {
        if (
            selectedGroup != null &&
            selectedField === null &&
            groupId != null &&
            fieldId != null &&
            content != null &&
            !content.isFetching
        ) {
            let found = false;
            for (let i = 0; i < selectedGroup.formFields.length; i++) {
                if (selectedGroup.formFields[i].id === fieldId) {
                    const field = { ...selectedGroup.formFields[i] };
                    if (field.tooltip == null) {
                        field.tooltip = { ...fieldInitialState.tooltip };
                    }
                    setSelectedField(field);
                    found = true;
                    break;
                }
            }

            if (!found) {
                navigate(`/app/${organisationId}/form/${groupId}/`);
            }
        }
    }, [
        selectedField,
        selectedGroup,
        groupId,
        fieldId,
        content,
        organisationId,
    ]);

    const handleFieldTypeMenu = (event: any) => {
        setAnchorElement(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorElement(null);
    };

    const onAddNewChoice = () => {
        const choiceId = new Date().getTime().toString();
        setSelectedChoice({
            id: choiceId,
            isNew: true,
            title: {
                is: ``,
                en: ``,
            },
        });

        setShowChoiceDialog(true);
    };

    const onEditChoice = (choice) => {
        setSelectedChoice({
            ...choice,
            isNew: false,
        });
        setShowChoiceDialog(true);
    };

    const onCloseChoiceDialog = () => {
        setSelectedChoice(null);
        setShowChoiceDialog(false);
    };

    const onDeleteChoice = () => {
        if (selectedChoice == null) {
            return;
        }

        const updatedField = {
            ...selectedField,
            choices: selectedField.choices.filter(
                (choice) => choice.id !== selectedChoice.id
            ),
        };

        setSelectedField(updatedField);

        setSelectedChoice(null);
        setShowChoiceDialog(false);
    };

    const onSaveChoice = () => {
        let updatedField;
        if (selectedChoice.isNew) {
            updatedField = {
                ...selectedField,
                choices: [
                    ...selectedField.choices,
                    {
                        ...selectedChoice,
                        isNew: false,
                    },
                ],
            };
        } else {
            let choiceIndex = -1;
            for (let i = 0; i < selectedField.choices.length; i++) {
                if (selectedField.choices[i].id === selectedChoice.id) {
                    choiceIndex = i;
                    break;
                }
            }
            if (choiceIndex === -1) {
                console.log('Choice not found when updating a choice');
            } else {
                let updatedChoices = [...selectedField.choices];
                updatedChoices[choiceIndex] = {
                    ...selectedChoice,
                };

                // updatedChoices.sort((a, b) => parseInt(a.index) - parseInt(b.index))

                updatedField = {
                    ...selectedField,
                    choices: updatedChoices,
                };
            }
        }

        // Deselect other choices if the field type is radio
        if (
            selectedChoice.isSelected &&
            (selectedField.fieldType === 'radio' ||
                selectedField.fieldType === 'dropdown')
        ) {
            for (let i = 0; i < updatedField.choices.length; i++) {
                if (
                    updatedField.choices[i].id !== selectedChoice.id &&
                    updatedField.choices[i].isSelected
                ) {
                    updatedField.choices[i] = {
                        ...updatedField.choices[i],
                        isSelected: false,
                    };
                }
            }
        }

        setSelectedField(updatedField);

        setSelectedChoice(null);
        setShowChoiceDialog(false);
    };

    const onSaveAsync = async () => {
        if (isCreatingField) {
            let updatedFields = [
                ...selectedGroup.formFields,
                { ...selectedField },
            ];
            const updatedSelectedGroup = {
                ...selectedGroup,
                formFields: updatedFields,
            };

            setSelectedGroup(updatedSelectedGroup);

            const updatedGroups = [...content.groups];

            for (let i = 0; i < updatedGroups.length; i++) {
                if (updatedGroups[i].id === selectedGroup.id) {
                    updatedGroups[i] = {
                        ...updatedSelectedGroup,
                    };
                    break;
                }
            }

            setContent({
                ...content,
                groups: updatedGroups,
            });
        } else {
            let updatedFields = [...selectedGroup.formFields];

            let fieldIndex = -1;
            for (let i = 0; i < updatedFields.length; i++) {
                if (updatedFields[i].id === selectedField.id) {
                    fieldIndex = i;
                    break;
                }
            }

            if (fieldIndex === -1) {
                console.log('Field not found when updating it');
            } else {
                updatedFields[fieldIndex] = {
                    ...selectedField,
                };

                const updatedSelectedGroup = {
                    ...selectedGroup,
                    formFields: updatedFields,
                };

                setSelectedGroup(updatedSelectedGroup);

                const updatedGroups = [...content.groups];

                for (let i = 0; i < updatedGroups.length; i++) {
                    if (updatedGroups[i].id === selectedGroup.id) {
                        updatedGroups[i] = {
                            ...updatedSelectedGroup,
                        };
                        break;
                    }
                }

                setContent({
                    ...content,
                    groups: updatedGroups,
                });
            }
        }

        try {
            setIsSavingContent(true);
            let res = await axios.post(
                `${config.apiBaseUrl}/organisation/${organisationId}/content/group/${selectedGroup.id}/fields/${selectedField.id}`,
                {
                    ...selectedField,
                },
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            );
        } catch (err) {
            console.log(err);
        }

        navigate(`/app/${organisationId}/form/${groupId}`);
        // setIsSavingContent(false)
        // setSelectedField(null)
        // setIsCreatingField(false)
    };

    const onDeleteAsync = async () => {
        try {
            setIsSavingContent(true);
            await axios.delete(
                `${config.apiBaseUrl}/organisation/${organisationId}/content/group/${selectedGroup.id}/fields/${selectedField.id}`,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            );

            const updatedSelectedGroup = {
                ...selectedGroup,
                formFields: [
                    ...selectedGroup.formFields.filter(
                        (f) => f.id !== selectedField.id
                    ),
                ],
            };

            setSelectedGroup(updatedSelectedGroup);

            const updatedGroups = [...content.groups];

            for (let i = 0; i < updatedGroups.length; i++) {
                if (updatedGroups[i].id === selectedGroup.id) {
                    updatedGroups[i] = {
                        ...updatedSelectedGroup,
                    };
                    break;
                }
            }

            setContent({
                ...content,
                groups: updatedGroups,
            });
        } catch (err) {
            console.log(err);
        }

        navigate(`/app/${organisationId}/form/${groupId}`);
        // setShowDeleteDialog(false)
        // setIsSavingContent(false)
        // setSelectedField(null)
        // setIsCreatingField(false)
    };

    const onChoiceTitleChange = (title, language) => {
        setSelectedChoice({
            ...selectedChoice,
            title: {
                ...selectedChoice.title,
                [language]: title,
            },
        });
    };

    const toggleChoiceAsSelected = () => {
        setSelectedChoice({
            ...selectedChoice,
            isSelected: !selectedChoice.isSelected,
        });
    };

    const onTitleChange = (title: string, language: string) => {
        setSelectedField({
            ...selectedField,
            title: {
                ...selectedField.title,
                [language]: title,
            },
        });
    };

    const toggleFieldVisibilityForReportArea = (e: any) => {
        if (selectedField == null || e.target.name == null) {
            return;
        }

        const reportAreaId = e.target.name.toString();

        let updatedSelectedField = {
            ...selectedField,
        };

        if (updatedSelectedField.excludedReportAreaIds == null) {
            updatedSelectedField = {
                ...updatedSelectedField,
                excludedReportAreaIds: [],
            };
        }

        if (!e.target.checked) {
            updatedSelectedField = {
                ...updatedSelectedField,
                excludedReportAreaIds: [
                    ...updatedSelectedField.excludedReportAreaIds,
                    reportAreaId,
                ],
            };
        } else {
            updatedSelectedField = {
                ...updatedSelectedField,
                excludedReportAreaIds: [
                    ...updatedSelectedField.excludedReportAreaIds.filter(
                        (excludedReportAreaId: string) =>
                            excludedReportAreaId !== reportAreaId
                    ),
                ],
            };
        }

        setSelectedField(updatedSelectedField);
    };

    const isFieldVisibleForReportArea = (reportAreaId: string) => {
        if (
            selectedField != null &&
            selectedField.excludedReportAreaIds != null
        ) {
            for (
                let i = 0;
                i < selectedField.excludedReportAreaIds.length;
                i++
            ) {
                if (selectedField.excludedReportAreaIds[i] === reportAreaId) {
                    return false;
                }
            }
        }

        return true;
    };

    const onTooltipChange = (tooltip: any, language: string) => {
        setSelectedField({
            ...selectedField,
            tooltip: {
                ...selectedField.tooltip,
                [language]: tooltip,
            },
        });
    };

    const onDragEndHandler = async (res) => {
        if (
            !res ||
            !res.destination ||
            res.destination.index === res.source.index
        ) {
            return;
        }
        let updatedChoices = [...selectedField.choices];

        const [removed] = updatedChoices.splice(res.source.index, 1);
        updatedChoices.splice(res.destination.index, 0, removed);

        setSelectedField({
            ...selectedField,
            choices: updatedChoices,
        });
    };

    const onFieldTypeChanged = (newFieldType) => {
        let choices = selectedField.choices;

        if (
            newFieldType === 'radio' ||
            newFieldType === 'dropdown' ||
            newFieldType === 'checkboxes'
        ) {
            if (choices == null) {
                choices = [];
            }
        } else {
            choices = null;
        }
        setSelectedField({
            ...selectedField,
            fieldType: newFieldType,
            choices,
        });
        setAnchorElement(null);
    };

    const renderSelectedFieldIcon = () => {
        switch (selectedField.fieldType) {
            case 'checkboxes':
                return <CheckBox color='primary' />;
            case 'text':
                return <ShortText color='primary' />;
            case 'textarea':
                return <Subject color='primary' />;
            case 'dropdown':
                return <ArrowDropDownCircle color='primary' />;
            case 'radio':
                return <RadioButtonChecked color='primary' />;
            case 'file':
                return <CloudUpload color='primary' />;
            default:
                return null;
        }
    };

    const renderChoiceIcon = (choice: any, index: number) => {
        switch (selectedField.fieldType) {
            case 'checkboxes':
                return choice.isSelected ? (
                    <CheckBoxOutlined color='primary' />
                ) : (
                    <CheckBoxOutlineBlank color='primary' />
                );
            case 'text':
                return <ShortText color='primary' />;
            case 'textarea':
                return <Subject color='primary' />;
            case 'dropdown':
                return (
                    <ArrowRight
                        color={choice.isSelected ? 'primary' : 'default'}
                    />
                ); //choice.isSelected ? <Avatar color="primary" fontSize style={{backgroundColor: 'rgb(66, 180, 230)'}}>{index}</Avatar> : <Avatar color="primary">{index}</Avatar>
            case 'radio':
                return choice.isSelected ? (
                    <RadioButtonChecked color='primary' />
                ) : (
                    <RadioButtonUnchecked color='primary' />
                );
            default:
                return null;
        }
    };

    if (selectedGroup == null || selectedField == null) {
        return null;
    }

    const isValid = () => {
        let hasTitle = false;
        for (let i = 0; i < languages.length; i++) {
            if (
                selectedField.title[languages[i].short] != null &&
                selectedField.title[languages[i].short].length > 0
            ) {
                hasTitle = true;
                break;
            }
        }

        if (!hasTitle) {
            return false;
        }

        switch (selectedField.fieldType) {
            case 'radio':
            case 'dropdown':
            case 'checkboxes':
                return (
                    selectedField.choices != null &&
                    selectedField.choices.length > 0
                );
            default:
                return true;
        }
    };

    return (
        <Card>
            <CardContent>
                <h3>
                    <Link to={`/app/${organisationId}/form`}>
                        {localization[language].formEditor.reportForm}
                    </Link>{' '}
                    /{' '}
                    <Link to={`/app/${organisationId}/form/${groupId}`}>
                        {selectedGroup.title[language] ||
                            selectedGroup.title.is ||
                            selectedGroup.title.en}
                    </Link>{' '}
                    /{' '}
                    {!isCreatingField
                        ? selectedField.title[language] ||
                          selectedField.title.is ||
                          selectedField.title.en
                        : localization[language].formEditor.createNewField}
                </h3>
                <Card variant='outlined' className={style.item}>
                    <CardContent className={style.formFieldEditor}>
                        <div className={style.item}>
                            <h3>{localization[language].content.title}</h3>
                            <Paper
                                variant='outlined'
                                className={style.titlePaper}
                            >
                                {languages.map((lang) => (
                                    <React.Fragment key={lang.short}>
                                        <div className={style.item}>
                                            <label
                                                htmlFor={`title_${lang.short}`}
                                                className={style.labelSmall}
                                            >
                                                <span>{lang.title}</span>
                                            </label>
                                            <input
                                                type='text'
                                                maxLength={300}
                                                id={`title_${lang.short}`}
                                                name={`title_${lang.short}`}
                                                value={
                                                    selectedField.title[
                                                        lang.short
                                                    ]
                                                }
                                                onChange={(e) =>
                                                    onTitleChange(
                                                        e.target.value,
                                                        lang.short
                                                    )
                                                }
                                            />
                                        </div>
                                    </React.Fragment>
                                ))}
                            </Paper>
                        </div>
                        <div className={style.item}>
                            <h3>{localization[language].formEditor.tooltip}</h3>
                            <Paper
                                variant='outlined'
                                className={style.titlePaper}
                            >
                                {languages.map((lang) => (
                                    <React.Fragment key={lang.short}>
                                        <div className={style.item}>
                                            <label
                                                htmlFor={`tooltip_${lang.short}`}
                                                className={style.labelSmall}
                                            >
                                                <span>{lang.title}</span>
                                            </label>
                                            <input
                                                type='text'
                                                maxLength={300}
                                                id={`tooltip_${lang.short}`}
                                                name={`tooltip_${lang.short}`}
                                                value={
                                                    selectedField.tooltip[
                                                        lang.short
                                                    ]
                                                }
                                                onChange={(e) =>
                                                    onTooltipChange(
                                                        e.target.value,
                                                        lang.short
                                                    )
                                                }
                                            />
                                        </div>
                                    </React.Fragment>
                                ))}
                            </Paper>
                        </div>
                        <div className={style.item}>
                            <h3>
                                {
                                    localization[language].app
                                        .fieldIsVisibleForThoseReportAreas
                                }
                            </h3>
                            <Paper
                                variant='outlined'
                                className={style.titlePaper}
                            >
                                <Grid container spacing={2}>
                                    {organisation != null &&
                                        organisation.reportChannels.map(
                                            (reportChannel: any) => (
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={12}
                                                    md={6}
                                                    lg={4}
                                                    key={reportChannel.id}
                                                >
                                                    <FormControlLabel
                                                        key={reportChannel.id}
                                                        control={
                                                            <Checkbox
                                                                size='small'
                                                                checked={isFieldVisibleForReportArea(
                                                                    reportChannel.id
                                                                )}
                                                                onChange={
                                                                    toggleFieldVisibilityForReportArea
                                                                }
                                                                name={
                                                                    reportChannel.id
                                                                }
                                                                color='primary'
                                                                disabled={
                                                                    isSavingContent
                                                                }
                                                            />
                                                        }
                                                        label={
                                                            <>
                                                                {
                                                                    reportChannel.subdomain
                                                                }
                                                                <i>
                                                                    .tilkynna.is
                                                                </i>
                                                            </>
                                                        }
                                                    />
                                                </Grid>
                                            )
                                        )}
                                </Grid>
                            </Paper>
                        </div>

                        <Menu
                            id='menu-appbar'
                            anchorEl={anchorElement}
                            open={Boolean(anchorElement)}
                            onClose={handleClose}
                        >
                            <MenuItem
                                onClick={() => onFieldTypeChanged('text')}
                            >
                                <ListItemIcon style={{ minWidth: 36 }}>
                                    <ShortText fontSize='small' />
                                </ListItemIcon>
                                {localization[language].formEditor.text}
                            </MenuItem>
                            <MenuItem
                                onClick={() => onFieldTypeChanged('textarea')}
                            >
                                <ListItemIcon style={{ minWidth: 36 }}>
                                    <Subject fontSize='small' />
                                </ListItemIcon>
                                {localization[language].formEditor.textarea}
                            </MenuItem>
                            <MenuItem
                                onClick={() => onFieldTypeChanged('checkboxes')}
                            >
                                <ListItemIcon style={{ minWidth: 36 }}>
                                    <CheckBox fontSize='small' />
                                </ListItemIcon>
                                {localization[language].formEditor.checkboxes}
                            </MenuItem>
                            <MenuItem
                                onClick={() => onFieldTypeChanged('radio')}
                            >
                                <ListItemIcon style={{ minWidth: 36 }}>
                                    <RadioButtonChecked fontSize='small' />
                                </ListItemIcon>
                                {localization[language].formEditor.radio}
                            </MenuItem>
                            <MenuItem
                                onClick={() => onFieldTypeChanged('dropdown')}
                            >
                                <ListItemIcon style={{ minWidth: 36 }}>
                                    <ArrowDropDownCircle fontSize='small' />
                                </ListItemIcon>
                                {localization[language].formEditor.dropdown}
                            </MenuItem>
                            <MenuItem
                                onClick={() => onFieldTypeChanged('file')}
                            >
                                <ListItemIcon style={{ minWidth: 36 }}>
                                    <CloudUpload fontSize='small' />
                                </ListItemIcon>
                                {localization[language].formEditor.file}
                            </MenuItem>
                        </Menu>
                        <div className={style.item}>
                            <Button
                                variant='outlined'
                                startIcon={renderSelectedFieldIcon()}
                                endIcon={<ArrowDropDown />}
                                onClick={handleFieldTypeMenu}
                            >
                                {
                                    localization[language].formEditor[
                                        selectedField.fieldType
                                    ]
                                }
                            </Button>
                        </div>
                        <div className={style.item}>
                            {(selectedField.fieldType === 'radio' ||
                                selectedField.fieldType === 'dropdown' ||
                                selectedField.fieldType === 'checkboxes') && (
                                <div className={style.item}>
                                    <h3>
                                        {
                                            localization[language].formEditor
                                                .choices
                                        }
                                    </h3>
                                    <DragDropContext
                                        onDragEnd={onDragEndHandler}
                                    >
                                        <Droppable droppableId='droppable'>
                                            {(provided, snapshot) => (
                                                <Paper
                                                    variant='outlined'
                                                    className={
                                                        style.choicesPaper
                                                    }
                                                    {...provided.droppableProps}
                                                    ref={provided.innerRef}
                                                >
                                                    <FormHelperText
                                                        style={{
                                                            marginBottom: 10,
                                                        }}
                                                    >
                                                        {
                                                            localization[
                                                                language
                                                            ].formEditor
                                                                .formDragAndDropHint
                                                        }
                                                    </FormHelperText>
                                                    {selectedField.choices.map(
                                                        (choice, index) => (
                                                            <Draggable
                                                                key={choice.id}
                                                                draggableId={
                                                                    choice.id
                                                                }
                                                                index={index}
                                                                isDragDisabled={
                                                                    isSavingContent
                                                                }
                                                            >
                                                                {(
                                                                    provided,
                                                                    snapshot
                                                                ) => (
                                                                    <Card
                                                                        variant='outlined'
                                                                        className={classNames(
                                                                            style.choicePaper,
                                                                            snapshot.isDragging &&
                                                                                style.isDragging
                                                                        )}
                                                                        ref={
                                                                            provided.innerRef
                                                                        }
                                                                        {...provided.draggableProps}
                                                                        {...provided.dragHandleProps}
                                                                        style={{
                                                                            ...provided
                                                                                .draggableProps
                                                                                .style,
                                                                        }}
                                                                    >
                                                                        <CardHeader
                                                                            className={classNames(
                                                                                style.cardHeaderSmall,
                                                                                selectedField.fieldType ===
                                                                                    'dropdown' &&
                                                                                    choice.isSelected &&
                                                                                    style.isSelected
                                                                            )}
                                                                            avatar={renderChoiceIcon(
                                                                                choice,
                                                                                index +
                                                                                    1
                                                                            )}
                                                                            title={
                                                                                choice
                                                                                    .title[
                                                                                    language
                                                                                ]
                                                                            }
                                                                            action={
                                                                                <IconButton
                                                                                    aria-label={
                                                                                        localization[
                                                                                            language
                                                                                        ]
                                                                                            .app
                                                                                            .labelEdit
                                                                                    }
                                                                                    onClick={() =>
                                                                                        onEditChoice(
                                                                                            choice
                                                                                        )
                                                                                    }
                                                                                    disabled={
                                                                                        accessToken ==
                                                                                        null
                                                                                    }
                                                                                >
                                                                                    <Edit />
                                                                                </IconButton>
                                                                            }
                                                                        />
                                                                    </Card>
                                                                )}
                                                            </Draggable>
                                                        )
                                                    )}
                                                    {provided.placeholder}
                                                    <Button
                                                        variant='outlined'
                                                        onClick={onAddNewChoice}
                                                        size='small'
                                                        disabled={
                                                            accessToken == null
                                                        }
                                                        syle={{
                                                            marginLeft: 'auto',
                                                        }}
                                                    >
                                                        {
                                                            localization[
                                                                language
                                                            ].formEditor
                                                                .addNewChoice
                                                        }
                                                    </Button>
                                                </Paper>
                                            )}
                                        </Droppable>
                                    </DragDropContext>
                                </div>
                            )}
                        </div>
                        <div className={style.item}>
                            <div>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            // size="small"
                                            checked={selectedField.required}
                                            onChange={(e) =>
                                                setSelectedField({
                                                    ...selectedField,
                                                    required:
                                                        !selectedField.required,
                                                })
                                            }
                                            name='required'
                                            color='primary'
                                            disabled={isSavingContent}
                                        />
                                    }
                                    label={
                                        localization[language].formEditor
                                            .required
                                    }
                                />
                            </div>
                        </div>
                    </CardContent>

                    <Dialog
                        open={showDeleteDialog}
                        onClose={() => setShowDeleteDialog(false)}
                        aria-labelledby='alert-dialog-title'
                        aria-describedby='alert-dialog-description'
                    >
                        <DialogTitle id='alert-dialog-title'>
                            {
                                localization[language].formEditor
                                    .titleConfirmDeleteField
                            }
                        </DialogTitle>
                        <DialogContent>
                            <CircularProgress
                                style={{ opacity: isSavingContent ? 1 : 0 }}
                                size={30}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={() => setShowDeleteDialog(false)}
                                disabled={isSavingContent}
                            >
                                {localization[language].app.labelCancel}
                            </Button>
                            <Button
                                onClick={onDeleteAsync}
                                color='primary'
                                autoFocus
                                disabled={isSavingContent}
                            >
                                {localization[language].app.labelContinue}
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog
                        className={style.dialog}
                        open={showChoiceDialog}
                        onClose={onCloseChoiceDialog}
                        aria-labelledby='alert-dialog-title'
                        aria-describedby='alert-dialog-description'
                    >
                        <DialogTitle id='alert-dialog-title'>
                            {selectedChoice != null && selectedChoice.isNew
                                ? localization[language].formEditor.addNewChoice
                                : localization[language].formEditor.editChoice}
                        </DialogTitle>
                        {selectedChoice != null && (
                            <DialogContent>
                                <h3>{localization[language].content.title}</h3>
                                <Paper
                                    variant='outlined'
                                    className={style.choiceEditorPaper}
                                >
                                    {languages.map((lang) => (
                                        <React.Fragment key={lang.short}>
                                            <div className={style.item}>
                                                <label
                                                    htmlFor={`title_${lang.short}`}
                                                    className={style.label}
                                                >
                                                    {lang.title}
                                                </label>
                                                <input
                                                    autoFocus={
                                                        lang.short === 'is'
                                                    }
                                                    type='text'
                                                    maxLength={300}
                                                    id={`title_${lang.short}`}
                                                    name={`title_${lang.short}`}
                                                    value={
                                                        selectedChoice.title[
                                                            lang.short
                                                        ]
                                                    }
                                                    onChange={(e) =>
                                                        onChoiceTitleChange(
                                                            e.target.value,
                                                            lang.short
                                                        )
                                                    }
                                                />
                                            </div>
                                        </React.Fragment>
                                    ))}
                                </Paper>
                                <div className={style.item}>
                                    <div>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={
                                                        selectedChoice !=
                                                            null &&
                                                        selectedChoice.isSelected
                                                    }
                                                    onChange={
                                                        toggleChoiceAsSelected
                                                    }
                                                    name='selected'
                                                    color='primary'
                                                    disabled={isSavingContent}
                                                />
                                            }
                                            label={
                                                localization[language]
                                                    .formEditor.selected
                                            }
                                        />
                                    </div>
                                </div>
                                {!selectedChoice.isNew && (
                                    <div style={{ textAlign: 'right' }}>
                                        <IconButton
                                            size='small'
                                            color='secondary'
                                            aria-label={
                                                localization[language].app
                                                    .labelDelete
                                            }
                                            onClick={onDeleteChoice}
                                            disabled={isSavingContent}
                                        >
                                            <DeleteForever />
                                        </IconButton>
                                    </div>
                                )}
                            </DialogContent>
                        )}

                        <DialogActions>
                            <Button
                                onClick={onCloseChoiceDialog}
                                disabled={isSavingContent}
                            >
                                {localization[language].app.labelCancel}
                            </Button>
                            <Button
                                onClick={onSaveChoice}
                                color='primary'
                                disabled={isSavingContent}
                            >
                                {localization[language].app.labelSave}
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Card>
            </CardContent>
            <CardActions>
                <Button
                    onClick={onSaveAsync}
                    size='large'
                    disabled={isSavingContent || !isValid()}
                    variant='contained'
                >
                    {localization[language].app.labelSave}
                </Button>
                <Button
                    onClick={() =>
                        navigate(`/app/${organisationId}/form/${groupId}`)
                    }
                    size='large'
                    disabled={isSavingContent}
                >
                    {localization[language].app.labelCancel}
                </Button>

                {!isCreatingField && (
                    <IconButton
                        // size="small"
                        color='secondary'
                        style={{ marginLeft: 'auto' }}
                        aria-label={localization[language].app.labelDelete}
                        onClick={() => setShowDeleteDialog(true)}
                        disabled={isSavingContent}
                    >
                        <DeleteForever />
                    </IconButton>
                    // <Button
                    //     onClick={() => setShowDeleteDialog(true)}
                    //     // variant="outlined"
                    //     disabled={isSavingContent}
                    //     color="secondary"
                    //     style={{ marginLeft: 'auto' }}
                    // >
                    //     {localization[language].app.labelDelete}
                    // </Button>
                )}
            </CardActions>
        </Card>
    );
};

export default React.memo(FormFieldEditor);
